<template>
  <div>
    <div class="containerButton">
      <w-error class="errorMessage" :message="errormsg"/>
    </div>
    <div class="containerButton" v-if="displayGoogleButton">
      <w-button color="outlined" :expanded="true" class="buttonSign" icon="google" :enabled="this.gAuthLoaded!==null"
                data-test="signInSignUpGoogleButton" :loading="googleLoading" @click="doGoogleOneTap">
        {{ $t('externalSignin.googleSignIn') }}
      </w-button>
    </div>
    <div class="containerButton" v-if="displayMsButton">
      <w-button color="outlined" :expanded="true" class="buttonSign" icon="microsoft" @click="loginMicrosoft"
                data-test="signInSignUpMicrosoftButton" :loading="msLoading">
        {{ $t('externalSignin.microsoftSignIn') }}
      </w-button>
    </div>
    <div class="containerButton" v-if="displayAppleButton">
      <w-button color="outlined" :expanded="true" class="buttonSign" icon="apple" @click="loginApple"
                data-test="signInSignUpAppleButton" :loading="appleLoading">
        {{ $t('externalSignin.appleSignIn') }}
      </w-button>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import store from '@/store';
import {
  APPLE_AUTH_REQUEST,
  AppleAuthParams,
  GAUTH_REQUEST,
  GAuthParams,
  MSAUTH_REQUEST,
  MSAuthParams, SHOW_POPUP_AUTH,
} from '@/store/auth/authAction';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {log} from '@/utils/log';
import WError from '@/components/wrapper/w-error.vue';
import {EXTERNAL_TOOL_AUTH_FAILURE, EXTERNAL_TOOL_INIT} from '@/store/integration/integrationAction';
import {notifyIframeExternalSigninNotSupported} from '@/utils/integrationUtil';
import {
  appleSigninOptions,
  googleProvider,
  msalConfig,
  msalScopeRequest,
} from '@/utils/externalSignin';
import * as Msal from '@azure/msal-browser';
import {InteractionRequiredAuthError, PublicClientApplication} from '@azure/msal-browser';
import {AuthenticationResult} from '@azure/msal-common';
import {redirectAfterAccountCreation, redirectIfNeed} from '@/utils/loginUtil';
import {googleLogout} from 'google-oauth-gsi';
import {alertMessageWithConfirmation} from '@/utils/dialog';

declare var AppleID: any;
@Component({
  components: {WButton, WError},
})
export default class ExternalSignIn extends Vue {
  private errormsg = '';
  private googleLoading: boolean = false;
  private msLoading: boolean = false;
  private appleLoading: boolean = false;
  private gAuthLoaded: any = null;
  private overlay: any = null;

  @Prop({default: false})
  private popup!: boolean;

  @Prop({default: true})
  private displayGoogleButton!: boolean;
  @Prop({default: true})
  private displayMsButton!: boolean;
  @Prop({default: true})
  private displayAppleButton!: boolean;

  private async mounted() {
    store.dispatch(EXTERNAL_TOOL_INIT);
    if (Vue.$msalObj && !this.popup) {
      this.setMicrosoftLoginRedirectCallback();
    }
    this.gAuthLoaded = googleProvider;
    AppleID.auth.init(appleSigninOptions());
  }

  public activateGoogleLoading() {
    this.googleLoading = true;
  }

  public desactivateGoogleLoading() {
    this.googleLoading = false;
  }

  @Watch('$route', {immediate: true, deep: true})
  private onRouteChange(route: any) {
    if (route.name === 'AuthenticationGoogle') {
      // We are comming from a google signin redirection
      // this.googleSignIn();
    }
  }

  private googleSignIn=googleProvider.useGoogleLogin({
    flow: 'implicit',
    onSuccess: (res) =>{
      console.log(res);
      if (res.access_token) {
              this.activateGoogleLoading();
              this.sendGoogleCredentialToBackend(res.access_token);
            }
    },
    onError: (err) => console.error('Failed to login with google', err),
  })

  private doGoogleOneTap() {
    this.googleSignIn();
  }


  /**
   * Send the credential retrieved from Google One Tap to backend.
   * Backend will decode the jwttoken and save the user in db.
   */
  private sendGoogleCredentialToBackend(credential: string) {
    const gAuthParams = new GAuthParams();
    gAuthParams.token = credential;
    store.dispatch(GAUTH_REQUEST, gAuthParams).then(async (accountCreation: boolean) => {
      if (accountCreation) {
        setActionEvent(ActionEvent.account_creation_google);
        await redirectAfterAccountCreation();
        this.desactivateGoogleLoading();
      } else {
        redirectIfNeed();
      }

      setActionEvent(ActionEvent.auth_success_google);
      this.close();
    }).catch((error: any) => {
      this.handleGoogleLoginFailure(error);
    });
  }

  /**
   * Send the id_token of the apple user retrieved to backend.
   * Backend will decode the jwttoken and save the user in db.
   */
  private sendAppleUserToBackend(appleAuthorization: any) {
    const appleAuthParams = new AppleAuthParams();
    appleAuthParams.token = appleAuthorization.authorization.id_token;
    appleAuthParams.firstName = appleAuthorization.user !== undefined ? appleAuthorization.user.name.firstName : null;
    appleAuthParams.lastName = appleAuthorization.user !== undefined ? appleAuthorization.user.name.lastName : null;
    store.dispatch(APPLE_AUTH_REQUEST, appleAuthParams).then(async (accountCreation: boolean) => {
      if (accountCreation) {
        setActionEvent(ActionEvent.account_creation_apple);
        await redirectAfterAccountCreation();
      } else {
        redirectIfNeed();
      }
      this.appleLoading = false;
      setActionEvent(ActionEvent.auth_success_apple);
      this.close();
    }).catch((error: any) => {
      this.handleAppleLoginError(error);
    });
  }

  /**
   * Start the microsoft login process after user click on the button
   */
  private loginMicrosoft() {
    const lastAskedUrl = store.getters.urlAsked;
    notifyIframeExternalSigninNotSupported(lastAskedUrl);
    setActionEvent(ActionEvent.auth_start_microsoft);
    this.msLoading = true;
    this.errormsg = '';
    if (this.popup) {
      this.getMsalObj().loginPopup(msalScopeRequest).then((token) => {
        this.acquireTokenPopup(token);
      });
    } else {
      this.getMsalObj().loginRedirect(msalScopeRequest);
    }
  }


  /**
   * Acquire accessToken to be used on backend to validate microsoft user
   * @param signInToken, the signin token (authorization code) retrieved from msal.loginPopup()
   */
  private acquireTokenPopup(signInToken: AuthenticationResult) {
    const microsoftAuthParams = new MSAuthParams();
    microsoftAuthParams.token = signInToken.idToken;
    msalScopeRequest.account = this.getMsalObj().getAllAccounts()?.[0];
    this.getMsalObj().acquireTokenSilent(msalScopeRequest).then((tokenResponse) => {
      microsoftAuthParams.accessToken = tokenResponse.accessToken;
      this.callBackendToCompleteMsAuth(microsoftAuthParams);
    }).catch((error) => {
      log.error('Silent token acquisition failed: ' + error);
      if (error instanceof InteractionRequiredAuthError) {
        log.debug('Trying to acquire token without silent');
        this.getMsalObj().acquireTokenRedirect(msalScopeRequest);
      } else {
        this.handleMsLoginError(error);
      }
    });
  }

  /**
   *  Acquire accessToken to be used on backend to validate microsoft user
   * @param signInToken, the signin token retrieved from msal.loginRedirect() call
   */
  private acquireTokenRedirect(signInToken: AuthenticationResult | null) {
    const microsoftAuthParams = new MSAuthParams();
    if (signInToken) {
      this.msLoading = true;
      msalScopeRequest.account = this.getMsalObj().getAllAccounts()?.[0];
      this.getMsalObj().acquireTokenSilent(msalScopeRequest).then((accessTokenResponse: AuthenticationResult) => {
        // Acquire token silent success
        // Call API with token
        microsoftAuthParams.token = accessTokenResponse.idToken;
        microsoftAuthParams.accessToken = accessTokenResponse.accessToken;
        this.callBackendToCompleteMsAuth(microsoftAuthParams);
      }).catch((error) => {
        // Acquire token silent failure, and send an interactive request
        log.error('Silent token acquisition failed: ' + error);
        if (error instanceof InteractionRequiredAuthError) {
          log.debug('Trying to acquire token without silent');
          this.getMsalObj().acquireTokenRedirect(msalScopeRequest);
        } else {
          this.handleMsLoginError(error);
        }
      });
    }
  }

  /**
   * Call backend api to validate user and complete authentication
   * @param microsoftAuthParams, the access token to use on backend to validate the Microsoft user identity
   */
  private callBackendToCompleteMsAuth(microsoftAuthParams: MSAuthParams) {
    this.errormsg = '';
    store.dispatch(MSAUTH_REQUEST, microsoftAuthParams).then(async (accountCreation: boolean) => {
      if (accountCreation) {
        setActionEvent(ActionEvent.account_creation_microsoft);
        await redirectAfterAccountCreation();
      } else {
        redirectIfNeed();
      }
      this.msLoading = false;
      setActionEvent(ActionEvent.auth_success_microsoft);
      this.close();
    });
  }

  /**
   * Start the apple login process after user click on the button
   */
  private async loginApple() {
    const lastAskedUrl = store.getters.urlAsked;
    notifyIframeExternalSigninNotSupported(lastAskedUrl);
    setActionEvent(ActionEvent.auth_start_apple);
    this.appleLoading = true;
    this.errormsg = '';
    try {
      const data = await AppleID.auth.signIn();
      setActionEvent(ActionEvent.auth_start_apple);
      this.sendAppleUserToBackend(data);
    } catch (error) {
      this.handleAppleLoginError(error);
    }
  }

  /**
   * Init the {@link Vue.$msalObj} if it is not yet initialized in main.ts
   * Note: If the Vue.$msalObj is null, it probably means that we came from google auth redirect which failed, and then we try to login with MS.
   * @return the initialized Vue.$msalObj
   */
  private getMsalObj(): PublicClientApplication {
    if (!Vue.$msalObj) {
      // Init the object only one time
      Vue.$msalObj = new Msal.PublicClientApplication(msalConfig);
      if (!this.popup) {
        this.setMicrosoftLoginRedirectCallback();
      }
    }
    return Vue.$msalObj;
  }


  private setMicrosoftLoginRedirectCallback() {
    // handle if it's a microsoft login redirect return
    Vue.$msalObj.handleRedirectPromise().then((tokenSuccess: AuthenticationResult) => {
      this.acquireTokenRedirect(tokenSuccess);
    }).catch((error: any) => {
      // try to log with popup if error
      log.error('Error when validating silent MsAuth: ' + error);
      this.handleMsLoginError(error);
    });
  }

  private close() {
    this.$emit('close');
  }

  private handleMsLoginError(error) {
    this.msLoading = false;
    setActionEvent(ActionEvent.auth_error_microsoft);
    store.dispatch(EXTERNAL_TOOL_AUTH_FAILURE, error);
    this.errormsg = this.$t('externalSignin.error', {error: error.message}).toString();
  }

  private handleAppleLoginError(error) {
    this.appleLoading = false;
    if (error.error !== 'popup_closed_by_user') {
      log.error(error.error);
      setActionEvent(ActionEvent.auth_error_apple);
      store.dispatch(EXTERNAL_TOOL_AUTH_FAILURE, error);
      if (error.error) {
        this.errormsg = this.$t('externalSignin.error', {error: error.error}).toString();
      } else {
        this.errormsg = this.$t('externalSignin.error', {error: this.$t('externalSignin.errorSetting').toString()}).toString();
      }
    }
  }


  private handleGoogleLoginFailure(e: any) {
    log.error(e);
    setActionEvent(ActionEvent.auth_error_google);
    store.dispatch(EXTERNAL_TOOL_AUTH_FAILURE, e);
    if (e.error !== 'popup_closed_by_user') {
      if (e.error) {
        this.errormsg = this.$t('externalSignin.error', {error: e.error}).toString();
      } else {
        this.errormsg = this.$t('externalSignin.error', {error: this.$t('externalSignin.errorSetting').toString()}).toString();
      }
    }
    this.desactivateGoogleLoading();
    return;
  }
}
</script>

<style scoped lang="scss">
.containerButton {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}

.googleSigninWrapper {
  width: 100%;
  margin: 0;
  border: 0;
  padding: 0;
  border-radius: 50%;
}

.modalGoogleAuth {
  background: #FFF;
  max-width: 600px;
  min-height: 320px;
  padding: 16px 32px 32px;
  border-radius: 8px;
  width: 100%;
  margin: auto;
  position: relative;
}
</style>
