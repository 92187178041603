import {v4 as uuidv4} from 'uuid';
import {log} from '@/utils/log';
import {cropAndScaleBitmap, cropAndScaleVideo} from '@/utils/video/canvasUtils';

export class CopyVideoTransformer implements ICanvasTransform {
    public initialised: boolean = false;
    private streamSource!: MediaStream;
    private video: HTMLVideoElement | null = null;

    private id: string = uuidv4();

    private cropWidth: number | undefined;
    private cropHeight: number | undefined;

    constructor(cropWidth: number | undefined, cropHeight: number | undefined) {
        this.cropWidth = cropWidth;
        this.cropHeight = cropHeight;
    }

    public async init(stream: MediaStream) {
        this.streamSource = stream;
        this.initVideo();
        log.debug('Initialise CopyVideoTransform');
    }

    public async start() {
        log.debug('start CopyVideoTransform');
        return;
    }

    public async transform(sleepMode: boolean, input: ImageBitmap | null = null): Promise<ImageBitmap | null> {
        try {
            // we grab the frame in sleep mode if note the track will auto desactivate
            const dateStart = new Date().getTime();
            let imageGrabbed = input;
            if (!imageGrabbed && this.video) {
                if (this.cropWidth && this.cropHeight
                    && (this.cropWidth !== this.video.videoWidth
                        || this.cropHeight !== this.video.videoHeight)) {
                    imageGrabbed = await cropAndScaleVideo(this.video, this.cropWidth, this.cropHeight);
                } else {
                    imageGrabbed = await createImageBitmap(this.video);
                }
                //  console.log('Grabbed in ' + (new Date().getTime() - dateStart) + 'ms');
            }

            if (imageGrabbed && imageGrabbed.width > 0) {
                this.initialised = true;
                return imageGrabbed;
            } else {
                return null;
            }

        } catch (e: any) {
            if (e) {
                throw new Error('RENDER_ERROR: ' + e.toString());
            } else {
                return null;
            }
        }
    }

    public async flush() {
        log.debug('Flush CopyVideoTransform');
        if (this.video) {
            this.video.remove();
        }
    }

    private initVideo() {
        // we have to put the stream in a video to keep it alive
        this.video = document.createElement('video');
        this.video.id = this.id.toString();
        this.video.autoplay = true;
        this.video.style.zIndex = '-1';
        this.video.style.position = 'absolute';
        this.video.style.top = '0';
        this.video.style.left = '0';
        document.body.append(this.video);
        this.video.srcObject = this.streamSource;
        this.video.muted = true;
    }

}
