import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {User} from '@/store/user/userModel';
import {FilterVideoType} from '@/enum/FilterVideoEnum';


export class Media {
    public mediaID: string = '';
    public recordID: string = '';
    public status: string = '';
    public jobID: string = '';
    public blobURL: string = '';
    public imageBlobURL: string = '';
    public subtitlePath: string = '';
    public type: SourceVideoType = SourceVideoType.NONE;
    public creator?: User;
    public cdnMediaUrl: string = '';
    public progress: number = 100;

    // is the media is saved on the server
    public saved: boolean = false;

    // if the media is validate by the user
    public validate: boolean = false;
}

export class ChunkMedia {
    public mediaID: string = '';
    public blob: Blob = new Blob();
    public type: SourceVideoType = SourceVideoType.NONE;
    public lastPart = false;
    public partNumber = 0;
    public mediaCreatorMode = false;
    public weetID: string = '';
    public noiseCancelation: number = 0;
    public transcoding: boolean = false;
    public maxTime: number = -1;

}

export class PlayerMedia {
    public url: string = '';
    public media: Media;
    public mirroring: boolean = false;
    public filter: FilterVideoType = FilterVideoType.NONE;
    public type: SourceVideoType = SourceVideoType.NONE;

    constructor(media: Media) {
        this.media = media;
    }
}
