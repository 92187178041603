<template>
  <div class="containerStepRecorder">

    <div class="layoutSelector" :class="isRecording?'hide':''">
      <ScreenLayoutSelector ref="screenSelector" @input="uploadFile" @close="onClose"/>
    </div>
    <div class="chooseScreenLayout" >
      <ChooseScreen @screenLoaded="onScreenLoaded"
                    @killSource="stopRecordIfNeed"
                    @onAIGenerated="onAIGenerated"/>

      <div class="stickerLayout">
        <StickerLayoutSource @openFilter="onOpenFilter"/>
      </div>

      <div class="timerBlock" v-if="!avatarAIStep || avatarAIStep<=1 ">
        <w-icon icon="clock-time-two-outline"
                color="text" size="small"/>
        {{ humanTimerRecord }} / {{ humanTimeMax }}
      </div>
    </div>

    <!-- Record the events in the timeline -->
    <TimeLineRecorder/>


    <div class="bottomButtonBar">
      <!-- BUTTON BAR -->
      <RecorderStartPauseButton stepTour="recordStartButton"
                                class="buttonRecord" :buttonMode="true"
                                @click="toggleRecord" v-if="!isAvatarAI" />
      <GenerateAvatarButton v-else />
      <!-- Component to count the record-->
      <TimerRecorder v-show="false"/>
      <div class="sectionTimer" v-show="false">
        <SectionTimer @end="stopRecordIfNeed"/>
      </div>


      <ValidateWeetButton data-test="recorderNextButtonStep1"
                          stepTour="buttonFinish" class="buttonAction" icon="arrow-right"
                          color="outlined"
                          @click="clickNext"/>
    </div>


    <div class="timeLineView">
      <TimeLine :advanced-aivailable="true"/>
    </div>

    <CreateWeetUploadStep :withNoiseCancelling="true" :file="file" @close="onFileUploaded"/>

    <OnboardingRecord class="onBoardingRecord" v-if="isRecordOnboarding && isShowOnboarding"/>
    <OnboardingSection class="onBoardingSection" v-if="isSectionOnboarding && isShowOnboarding"/>
    <!-- COUNT DOWN LAYOUT -->
    <transition name="fade">
      <div class="countDownContainer" v-show="countDown!==0" data-test="recorderCountDown">
        <div class="counter">
          {{ countDown }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import WeetPlayer from '@/components/play/player/WeetPlayer.vue';
import store from '@/store';
import {Howl} from 'howler';
import ChooseScreen from '@/components/recorder/videoLayout/chooseScreen/ChooseScreen.vue';
import TimeLineRecorder from '@/components/recorder/timeLine/TimeLineRecorder.vue';
import StickerLayoutSource from '@/components/recorder/sticker/StickerLayoutSource.vue';
import RecorderStartPauseButton from '@/components/recorder/button/RecorderStartPauseButton.vue';
import TimerRecorder from '@/components/recorder/timer/TimerRecorder.vue';
import SectionTimer from '@/components/recorder/timer/SectionTimer.vue';
import ValidateWeetButton from '@/components/actionButton/ValidateWeetButton.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import {setActionEvent, setPageEvent} from '@/utils/tracker';
import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import {PAUSE_RECORDING, START_RECORDING, STOP_RECORDING} from '@/store/recordingState/recordStateAction';
import ScreenLayoutSelector from '@/components/recorder/videoLayout/ScreenLayoutSelector.vue';
import {VALIDATE_MEDIA} from '@/store/media/mediaAction';
import TimeLine from '@/components/recorder/timeLine/TimeLine.vue';
import {Weet} from '@/store/weet/weetModel';
import {SEND_VISIBILITY_POLICY_WEET} from '@/store/myWeet/myWeetAction';
import {sendBadgeMessage, sendMessageToExtention} from '@/utils/extentionUtil';
import {TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import WIcon from '@/components/wrapper/w-icon.vue';
import prettyMilliseconds from 'pretty-ms';
import OnboardingRecord from '@/components/onboarding/OnboardingRecord.vue';
import OnboardingSection from '@/components/onboarding/OnboardingSection.vue';
import {numberOfSection} from '@/utils/timeLineUtils';
import {embededInExternalIframe} from '@/utils/integrationUtil';
import {VisibilityPolicy} from '@/store/weet/VisibilityPolicy';
import delay from 'delay';
import CreateWeetUploadStep from "@/components/recorder/createWeetSubComponent/CreateWeetUploadStep.vue";
import {UPLOAD_FILE_DESCRIPTION} from "@/store/upload/uploadAction";
import WorkspaceMovePanel from "@/components/workspace/WorkspaceMovePanel.vue";
import {initWeetEditing} from "@/utils/createWeetUtil";
import {SourceVideoType} from "@/enum/SourceVideoEnum";
import WButton from "@/components/wrapper/w-button.vue";
import GenerateAvatarButton from "@/components/recorder/button/GenerateAvatarButton.vue";


@Component({
  components: {
    GenerateAvatarButton,
    WButton,
    WorkspaceMovePanel,
    CreateWeetUploadStep,
    OnboardingSection,
    OnboardingRecord,
    WIcon,
    TimeLine,
    ScreenLayoutSelector,
    EndWeetButton,
    ValidateWeetButton,
    SectionTimer,
    TimerRecorder, RecorderStartPauseButton, StickerLayoutSource, TimeLineRecorder, ChooseScreen, WeetPlayer
  },
})
export default class CreateWeetRecorderStep extends Vue {

  private countDown: number = 0;
  private sound: Howl = new Howl({src: ['/sound/click2.mp3']});
  private filterOpen: boolean = false;

  private file: File | null = null;

  get weetID(): string {
    return store.getters.getEditingWeetID;
  }

  get isRecording(): boolean {
    return store.getters.isPauseAivailable || store.getters.isAvatarGenerateInProgress;
  }

  get isAvatarAI():boolean{
    return store.getters.getMainRecordSource===SourceVideoType.AI_AVATAR;
  }

  get humanTimerRecord(): string {
    return prettyMilliseconds(store.getters.getTimerRecord, {secondsDecimalDigits: 0, colonNotation: true});
  }

  get humanTimeMax(): string {
    return prettyMilliseconds(this.timeMax, {colonNotation: true, secondsDecimalDigits: 0});
  }

  get timeMax(): number {
    // convert second in millisecond
    return store.getters.getWeetMaxTime * 1000;
  }

  get isTimeLineFullAivailable(): boolean {
    return !this.isRecording && this.countDown === 0;
  }

  get myWeet(): Weet | undefined {
    return store.getters.getMyWeet(store.getters.getEditingWeetID);
  }

  get isRecordOnboarding(): boolean {
    if (this.myWeet) {
      return !store.getters.isOnboardingRecorder1 && store.getters.getTimeEvent.length === 0;
    } else {
      return !store.getters.isOnboardingRecorder1;
    }
  }

  get avatarAIStep(){
    return store.getters.getAiAvatarStep;
  }

  get isShowOnboarding(): boolean {
    return !this.isRecording && this.countDown === 0 && !this.filterOpen && !embededInExternalIframe();
  }

  get isSectionOnboarding(): boolean {
    if (this.myWeet) {
      return !store.getters.isOnboardingSection1 && numberOfSection(this.myWeet.timeLine) === 2;
    } else {
      return false;
    }
  }

  private uploadFile(file: File): void {
    this.file = file;
  }

  private onFileUploaded(): void {
    this.file = null;
    store.dispatch(UPLOAD_FILE_DESCRIPTION, null);
    this.reinitAIAvatar();
  }
  private onAIGenerated():void{
    this.reinitAIAvatar();
  }

  private onClose(): void {
    this.$emit('close');
  }

  public async toggleRecord() {
    // ignore if countdown is alredy
    // avoid spam extention click
    if (this.countDown > 0) {
      return;
    }

    if (this.isRecording) {
      setActionEvent(ActionEvent.record_button_pause, {record_time: store.getters.getTimerRecord});
      store.dispatch(PAUSE_RECORDING);
      this.validateSection();
    } else {

      this.countDown = 3;
      this.$emit('countDown', this.countDown);
      this.sound.play();

      // we create the weet if no do
      this.initWeetEditing();

      const decCountDown = () => {
        this.countDown--;
        this.$emit('countDown', this.countDown);
        sendBadgeMessage(this.countDown + '', '#9600ff');
        this.sound.play();
      };
      setTimeout(decCountDown, 1000);
      setTimeout(decCountDown, 2000);
      setTimeout(() => {
        this.sound.play();
        setTimeout(async () => {
          // Wait editingID arrive
          while (!store.getters.getEditingWeetID) {
            await delay(500);
          }
          this.countDown--;
          this.$emit('countDown', this.countDown);
          setActionEvent(ActionEvent.record_button_record, {
            filter: store.getters.getRecorderFilter,
            start_record_time: store.getters.getTimerRecord
          });

          store.dispatch(START_RECORDING);
        }, 300);
      }, 3000);
    }
  }

  private mounted() {
    sendMessageToExtention(TIMER_RECORD, 0);
    sendMessageToExtention(STOP_RECORDING);
    setPageEvent(PageEvent.page_record_step_record);
  }

  private clickNext() {
    this.$emit('next');
  }

  private stopRecordIfNeed() {
    if (this.isRecording) {
      this.toggleRecord();
    }
    setTimeout(() => {
      this.reinitAIAvatar();
    }, 100);
  }

  private reinitVirtualBackground() {
    // @ts-ignore
    this.$refs.screenSelector.chooseVirtualScreenCast();
  }

  public reinitAIAvatar(){
    // @ts-ignore
    this.$refs.screenSelector.chooseAIAvatar();
  }

  private validateSection() {
    // we validate the media to Save the Media :-)
    store.dispatch(VALIDATE_MEDIA, store.getters.getMainMediaId);
    store.dispatch(VALIDATE_MEDIA, store.getters.getStickersMediaId);
  }

  private async initWeetEditing() {
    // before record we create an ID
    // this id must be provide before the first chunk upload
    if (store.getters.getEditingWeetID === '') {
      const weet=await initWeetEditing();
      // if we are in an external tool (Teams / Slack / Speach /)
      // we open the weet to be more accessible
      if (store.getters.getExternalTool) {
        store.dispatch(SEND_VISIBILITY_POLICY_WEET,
            {weetID: weet.weetID, policy: VisibilityPolicy.SEMI_PRIVATE});
      }
    }
  }



  private onScreenLoaded() {
    if (store.getters.isStartRecordingAivailable) {
      // TODO mettre une conf
      //  this.startCountDown();
    }
  }

  private onOpenFilter(value) {
    this.filterOpen = value;
  }


}
</script>

<style scoped lang="scss">

.containerStepRecorder {
  height: 100%;
  width: 100%;
  // background: red;

  .layoutSelector {
    margin-top: 16px;
    margin-bottom: 16px;

    &.hide {
      visibility: hidden;
    }
  }

  .bottomButtonBar {
    margin-top: 16px;
    // position: absolute;
    width: 100%;
    min-height: 48px;
    left: 0px;
    justify-content: center;
    align-items: center;
    // background: red;
    bottom: 40px;
    display: flex;

    .buttonRecord {
      //margin-left: 130px;
      // margin-right: 16px;
    }

    .buttonAction {
      position: absolute;
      right: 72px;
    }

  }

  .stickerLayout {
    position: absolute;
    bottom: 0px;
    left: -40px
  }


  .chooseScreenLayout {
    margin: auto;
    position: relative;
    // width: calc(100% - 192px);
    width: 558px;
    min-height: 314px;
    height: 314px;
    max-height: 314px;


    .timerBlock {
      width: 100px;
      border-radius: 8px;
      position: absolute;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      background: var(--black);
      // background: rgba(255, 255, 255, 0.8);
      color: var(--white);
      opacity: 0.8;
      bottom: 16px;
      left: calc(50% - 50px);
    }
  }


  .timeLineView {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 0px);
  }

  .countDownContainer {
    position: absolute;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(27, 57, 84, 0.8);

    .counter {
      position: absolute;
      width: 100%;
      top: calc(50% - 150px);
      font-size: 190px;
      font-weight: 600;
      color: white;
      text-shadow: 2px 2px 10px #1B3954;
    }
  }

  .onBoardingRecord {
    position: absolute;
    left: -300px;
    bottom: 0px;
    animation-duration: 0.1s;
  }

  .onBoardingSection {
    position: absolute;
    left: -300px;
    bottom: 0px;
    animation-duration: 0.1s;
  }
}

</style>
