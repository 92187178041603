<template>
  <div class="weetAdvancedEditingCutContainer">
    <TimeLineSelector ref="timeSelector" :weet="weet"
                      @click="addSection"
                      @delete="deleteSection"
                      @cancel="cancelTimeLineModification"
                      :modeIntegration="modeIntegration">

      <div class="buttonBar">
        <Transition name="zoom">
          <w-button class="buttonSmart" v-if="!modeIntegration" v-show="!timeLineModified" :loading="magicLoading" size="small"
                    :enabled="isMagicAivailable"
                    icon="table-split-cell" :weet="weet" @move="back()"
                    color="outlined"
                    @click="magicTrick(true)" :tooltip="$t('createWeetComponent.advancedEditing.tooltip.magicTrim')">
            {{ $t('createWeetComponent.advancedEditing.button.magicTrim') }}
          </w-button>
        </Transition>
        <Transition name="zoom">
          <w-button class="buttonSmart"  v-if="!modeIntegration"  v-show="!timeLineModified" :loading="magicLoading" size="small"
                    :enabled="isMagicAivailable" :weet="weet" @move="back()"
                    icon="auto-fix"
                    color="outlined"
                    @click="magicTrick(false)" :tooltip="$t('createWeetComponent.advancedEditing.tooltip.magicCut')">
            {{ $t('createWeetComponent.advancedEditing.button.magicCut') }}
          </w-button>
        </Transition>
        <Transition name="zoom">
          <div class="buttonPreview" v-show="timeLineModified">
            {{ $t('createWeetComponent.advancedEditing.button.preview') }}
            <w-switch size="small" class="switchPreview" :value="isPreviewMode" @input="changePreviewMode"></w-switch>
          </div>
        </Transition>
      </div>

    </TimeLineSelector>

    <Transition name="fade">
      <w-button class="buttonCancel" :class="{modeIntregration:modeIntegration}" size="small" v-show="timeLineModified"
                icon="undo" :enabled="timeLineModified"
                color="text"
                @click="cancelTimeLineModification()">
        {{ $t('createWeetComponent.advancedEditing.button.cancelEditing') }}
      </w-button>
    </Transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WeetPlayer from '@/components/play/player/WeetPlayer.vue';
import store from '@/store';
import WInput from '@/components/wrapper/w-input.vue';
import WError from '@/components/wrapper/w-error.vue';
import {Weet} from '@/store/weet/weetModel';
import WButton from '@/components/wrapper/w-button.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import TimeLine from '@/components/recorder/timeLine/TimeLine.vue';
import TimeLineSelector from '@/components/recorder/timeLine/TimeLineSelector.vue';
import {REFRESH_WEET_FOR_EDITING} from '@/store/myWeet/myWeetAction';
import {PREVIEW_MODE, TIMELINE_ADVANCED_MODIFIED} from '@/store/advancedEditing/advancedEditingAction';
import {ADD_TIME_EVENT, DELETE_TIME_EVENT, SEEK_PLAYER, TIMER_PLAYER} from '@/store/timeLine/timeLineAction';
import ProgressProcessPlayer from '@/components/play/process/ProgressProcessPlayer.vue';
import {clone} from '@/utils/util';
import {
  getDurationOfTimeLine,
  getFreeTimeAfterEvent, getFreeTimeBeforeEvent, getTimeEventAfterTime,
  getTimeEventbeforeTime,
  isCutEventOnTimeLine,
  isSoundEventOnTimeLine
} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import delay from 'delay';
import {alertMessage, alertSuccess, confettiBee, inform} from '@/utils/dialog';
import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import {setActionEvent, setPageEvent} from '@/utils/tracker';
import WSwitch from '@/components/wrapper/w-switch.vue';
import {PaymentPlanEnum, Workspace} from '@/store/workspace/workspaceModel';
import UpgradePlanTag from '@/components/upgradePlan/UpgradePlanTag.vue';


@Component({
  components: {
    UpgradePlanTag,
    WSwitch,
    ProgressProcessPlayer, TimeLineSelector, TimeLine, EndWeetButton, WButton, WError, WInput, WeetPlayer
  },
})
export default class CreateWeetCutMode extends Vue {

  @Prop()
  private weet!: Weet;

  @Prop({default:false})
  private modeIntegration!: boolean;


  private magicLoading: boolean = false;

  get timeLineModified(): boolean {
    return store.getters.isAdvancedEditingModified;
  }

  get selectedWorkspace(): Workspace | undefined {
    return store.getters.getSelectedWorkspace;
  }

  get isPremiumWorkspace(): boolean {
    if (this.selectedWorkspace) {
      return this.selectedWorkspace.plan !== PaymentPlanEnum.FREE;
    } else {
      return false;
    }
  }

  get timerPlayer() {
    return store.getters.getTimerPlayer;
  }

  get isPreviewMode() {
    return store.getters.isPreviewMode;
  }

  get isMagicAivailable(): boolean {
    return isSoundEventOnTimeLine(this.weet.timeLine);
  }

  get durationOfTimeLine() {
    return getDurationOfTimeLine(this.weet.timeLine);
  }


  private mounted() {
    setPageEvent(PageEvent.page_record_step_advanced_cut);
  }

  private getSelector(): TimeLineSelector {
    return this.$refs.timeSelector as TimeLineSelector;
  }

  private updateSelector() {
    this.getSelector().updateSlider();
  }

  private back() {
    this.$emit('back');
  }

  private async addSection(time: number) {
    const timeEventStartSection = clone(getTimeEventbeforeTime(this.weet.timeLine, time, TimeEventType.MEDIA_PLAY));
    // check ecrasement
    timeEventStartSection.time = getFreeTimeAfterEvent(this.weet.timeLine, time);
    timeEventStartSection.type = TimeEventType.START_CUT;
    await store.dispatch(ADD_TIME_EVENT, timeEventStartSection);

    const timeEventEndSection = clone(getTimeEventbeforeTime(this.weet.timeLine, time, TimeEventType.MEDIA_PLAY));

    const delta = this.getSelector().zoom[1] - this.getSelector().zoom[0];
    let timeEndAsked = time + (delta * 0.05);
    // max timeAivailable (avoid merge)
    const maxTimeAivailable = getTimeEventAfterTime(this.weet.timeLine, time, TimeEventType.START_CUT)?.time;
    if (maxTimeAivailable && timeEndAsked > (maxTimeAivailable - 100)) {
      timeEndAsked = maxTimeAivailable - 100;
    }
    timeEventEndSection.time = getFreeTimeAfterEvent(this.weet.timeLine, timeEndAsked);
    timeEventEndSection.type = TimeEventType.END_CUT;
    await store.dispatch(ADD_TIME_EVENT, timeEventEndSection);
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, true);
    // get currentSection
    this.updateSelector();

    // amplitude
    setActionEvent(ActionEvent.cut_mode_add_section);
  }

  private async deleteSection(sectionNumber: number) {
    let numberOfSection = 0;
    let startEvent: TimeEvent | null = null;
    const timeToRewind = this.timerPlayer;
    // so now we remove the good section Number
    for (const te of this.weet.timeLine) {
      if (te.type === TimeEventType.START_CUT) {
        numberOfSection++;
        startEvent = te;
      } else if (te.type === TimeEventType.END_CUT) {
        if (numberOfSection === sectionNumber) {
          if (startEvent !== null) {
            await store.dispatch(DELETE_TIME_EVENT, startEvent);
          }
          await store.dispatch(DELETE_TIME_EVENT, te);
          break;
        }
      }
    }
    if (!isCutEventOnTimeLine(this.weet.timeLine)) {
      this.cancelTimeLineModification();
    } else {
      this.updateSelector();
    }
    setTimeout(() => {
      this.changePlayerTime(timeToRewind);
    }, 100);

    // amplitude
    setActionEvent(ActionEvent.cut_mode_remove_section);
  }

  private async magicTrick(trimOnly: boolean) {
    this.magicLoading = true;
    // maybe it's wrong
    await this.cancelTimeLineModification();
    let isModified = false;
    let sectionStarted = false;
    let numberOfSectionCompute = 0;
    for (const te of this.weet.timeLine) {
      const isTheLastSection = te.time === getTimeEventbeforeTime(this.weet.timeLine, this.durationOfTimeLine, TimeEventType.NO_SOUND_DETECT)?.time;
      const sectionNeed = (!trimOnly || numberOfSectionCompute === 0) || isTheLastSection;
      if (te.type === TimeEventType.NO_SOUND_DETECT
          && (sectionNeed)) {
        isModified = true;
        sectionStarted = true;
        const teClone = clone(te);
        teClone.type = TimeEventType.START_CUT;
        await store.dispatch(ADD_TIME_EVENT, teClone);
      }
      if (te.type === TimeEventType.SOUND_DETECT
          && sectionNeed && sectionStarted) {
        isModified = true;
        sectionStarted = false;
        const teClone = clone(te);
        teClone.type = TimeEventType.END_CUT;
        await store.dispatch(ADD_TIME_EVENT, teClone);
        numberOfSectionCompute++;
      }

      if ((te.type === TimeEventType.MEDIA_PAUSE
          || te.type === TimeEventType.MEDIA_PLAY) && sectionStarted && sectionNeed) {
        isModified = true;
        sectionStarted = false;
        const teClone = clone(te);
        // just before the mediaPause we adde the last cut event
        teClone.time = getFreeTimeBeforeEvent(this.weet.timeLine, (teClone.time - 1));
        teClone.type = TimeEventType.END_CUT;
        await store.dispatch(ADD_TIME_EVENT, teClone);
      }
    }

    await delay(50);
    // REMOVE SMALL CHUNK
    const MIN_CHUNK = 500;
    let startEvent: TimeEvent | undefined;
    for (const te of this.weet.timeLine) {
      if (te.type === TimeEventType.START_CUT) {
        startEvent = te;
      }
      if (te.type === TimeEventType.END_CUT) {
        if (startEvent && te.time - startEvent.time < MIN_CHUNK) {
          await store.dispatch(DELETE_TIME_EVENT, startEvent);
          await store.dispatch(DELETE_TIME_EVENT, te);
        }
      }
    }
    // MERGE SMALL SECTION
    let endEvent: TimeEvent | undefined;
    for (const te of this.weet.timeLine) {
      if (te.type === TimeEventType.END_CUT) {
        endEvent = te;
      }
      if (te.type === TimeEventType.START_CUT) {
        if (endEvent && te.time - endEvent.time < MIN_CHUNK) {
          await store.dispatch(DELETE_TIME_EVENT, endEvent);
          await store.dispatch(DELETE_TIME_EVENT, te);
        }
      }
    }
    if (isModified) {
      store.dispatch(TIMELINE_ADVANCED_MODIFIED, true);
    }
    this.updateSelector();
    setTimeout(async () => {
      this.magicLoading = false;
      // confettiBee(1000);
      await delay(1000);
      this.changePlayerTime(100);
      // await delay(500);
      inform(this.$t('createWeetComponent.advancedEditing.successCut').toString(), 'top');
    }, 100);

    // amplitude tags
    if (trimOnly) {
      setActionEvent(ActionEvent.cut_mode_smart_trim);
    } else {
      setActionEvent(ActionEvent.cut_mode_smart_cut);
    }
  }

  private async cancelTimeLineModification() {
    await store.dispatch(REFRESH_WEET_FOR_EDITING, this.weet.weetID);
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, false);
    this.getSelector().resetZoom();
    this.updateSelector();
    setTimeout(() => {
      this.changePlayerTime(100);
    });
  }

  private changePreviewMode() {
    store.dispatch(PREVIEW_MODE, !this.isPreviewMode);
  }

  private changePlayerTime(time) {
    store.dispatch(SEEK_PLAYER, time);
    store.dispatch(TIMER_PLAYER, time);
  }

  private beforeDestroy() {
    store.dispatch(TIMELINE_ADVANCED_MODIFIED, false);
    store.dispatch(PREVIEW_MODE, true);
  }

}
</script>

<style scoped lang="scss">

.weetAdvancedEditingCutContainer {
  margin: auto;

  .playerContainer {
    position: relative;
    height: 324px;
    max-height: 324px;
    margin: auto;
    background: var(--black);

    .progressWeet {
      display: flex;
      justify-items: center;
      align-content: center;
      height: 100%;
      width: 100%;
      padding: 64px;
    }
  }

  .tagButtonEleemnt {
    right: 0px;
    position: absolute;
    top: -12px;
  }


  .backButton {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .timeLineView {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 0px);
  }

  .buttonLine {
    position: absolute;
    display: flex;
    bottom: 32px;
    right: 32px;
    width: calc(100% - 128px);

    .buttonAction {
      margin-left: 16px;
    }

    .spacer {
      flex: 1;
    }
  }

  .titleToolMode {
    font-size: 24px;
    font-weight: 900;
    flex-grow: 1;
    color: var(--black);
    text-align: left;
    justify-content: center;
    line-height: 32px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .buttonBar {
    display: flex;
    align-items: center;
    position: relative;

    .buttonSmart {
      margin-right: 8px;
      animation-duration: 0.2s;
    }

    .buttonPreview {
      display: flex;
      font-size: 12px;
      animation-delay: 0.3s;
      animation-duration: 0.2s;

      .switchPreview {
        margin-left: 8px;
      }
    }
  }

  .buttonCancel {
    position: absolute;
    bottom: 24px;
    left: 16px;
    &.modeIntregration{
      left: 0px;
    }
  }

}
</style>
