import axios from 'axios';
import {SUBTITLES_MANAGEMENT_AUTHORISATION} from "@/store/subtitles/SubtitlesAction";
import {BACK_ENDPOINT} from "@/store/conf/api";
import jwt from "jsonwebtoken";

const subtitlesAPI = BACK_ENDPOINT + '/subtitles';
export default {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        async [SUBTITLES_MANAGEMENT_AUTHORISATION]({commit, dispatch}: { commit: any, dispatch: any }, {weetID,callback}:{weetID:string,callback:string}) {
            return await axios({
                url: subtitlesAPI + '/auth/' + weetID,
                params:{callback:callback},
                method: 'GET',
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                if (err.data) {
                    err = err.data;
                }
                throw err;
            });
        },

    },
};
