import {
    CHANGE_PLAYER_SPEED, CHANGE_PLAYER_VOLUME, EXPERIMENTAL_FEATURE,
    FIRST_WEET_CONGRAT, ONBOARDING_PLAYER_1,
    ONBOARDING_RECORDER_1, ONBOARDING_SECTION_1,
    RESET_PERSIST_STORE, SELECT_WORKSPACE,
    SET_LAST_NEWS_DATE,
    SET_LAST_VERSION_NEWS, STORE_LINK_REDIRECT_AFTER_PAYMENT,
    SUBTITLE_LANG, TIME_LINE_VISU,
} from '@/store/persistedInformation/persistAction';

export default {
    state: {
        subtitleLang: "",
        lastDateNewsCheck: new Date().getTime(),
        lastVersionNewsCheck: 2.20,
        first_weet_congrat: false,
        onboarding_recorder1: false,
        onboarding_player1: false,
        onboarding_section1: false,
        experimentalFeature: false,
        timelineVisu: false,
        selectedWorkspaceID: '',

        // player config
        volume: 100,
        speedMode: 1.0,
        linkToRedirectAfterPaymentSuccess:""

    },
    mutations: {
        [RESET_PERSIST_STORE]: (state: any, isoCode: string) => {
            // state.transcriptLang = isoCode;
            state.selectedWorkspaceID = '';
            state.linkToRedirectAfterPaymentSuccess='';
        },
        [SUBTITLE_LANG]: (state: any, value: boolean) => {
            state.subtitleLang = value;
        },
        [SET_LAST_NEWS_DATE]: (state: any, value: Date) => {
            state.lastDateNewsCheck = value.getTime();
        },
        [SET_LAST_VERSION_NEWS]: (state: any, value: number) => {
            state.lastVersionNewsCheck = value;
        },
        [FIRST_WEET_CONGRAT]: (state: any, value: boolean) => {
            state.first_weet_congrat = value;
        },
        [ONBOARDING_RECORDER_1]: (state: any, value: boolean) => {
            state.onboarding_recorder1 = value;
        },
        [ONBOARDING_PLAYER_1]: (state: any, value: boolean) => {
            state.onboarding_player1 = value;
        },
        [ONBOARDING_SECTION_1]: (state: any, value: boolean) => {
            state.onboarding_section1 = value;
        },
        [EXPERIMENTAL_FEATURE]: (state: any, value: boolean) => {
            state.experimentalFeature = value;
        },
        [TIME_LINE_VISU]: (state: any, value: boolean) => {
            state.timelineVisu = value;
        },
        [SELECT_WORKSPACE]: (state: any, workspaceID: string) => {
            state.selectedWorkspaceID = workspaceID;
        },
        [CHANGE_PLAYER_SPEED]: (state: any, value: number) => {
            state.speedMode = value;
        },
        [CHANGE_PLAYER_VOLUME]: (state: any, value: number) => {
            state.volume = value;
        },
        [STORE_LINK_REDIRECT_AFTER_PAYMENT]: (state: any, link: string) => {
            state.linkToRedirectAfterPaymentSuccess = link;
        },
    },
    getters: {
        getSubtitleLang: (state: any): boolean => {
            return state.subtitleLang;
        },
        isExperimentalFeature: (state: any): boolean => {
            return state.experimentalFeature;
        },
        getSelectedWorkspaceID: (state: any): string | null => {
            return state.selectedWorkspaceID;
        },
        getLastDateNewsCheck: (state: any): number => {
            return state.lastDateNewsCheck;
        },
        getLastVersionNewsCheck: (state: any): number => {
            return state.lastVersionNewsCheck;
        },
        speedMode: (state: any): number => {
            return state.speedMode;
        },
        isFirstWeetCreated: (state: any): boolean => {
            return state.first_weet_congrat;
        },
        isOnboardingRecorder1: (state: any): boolean => {
            return state.onboarding_recorder1;
        },
        isOnboardingSection1: (state: any): boolean => {
            return state.onboarding_section1;
        },
        isOnboardingPlayer1: (state: any): boolean => {
            return state.onboarding_player1;
        },
        isTimeLineVisu: (state: any): boolean => {
            return state.timelineVisu;
        },
        volume: (state: any): number => {
            return state.volume;
        },
        getLinkToRedirectAfterPaymentSuccess:(state:any):string=>{
            return state.linkToRedirectAfterPaymentSuccess;
        }
    },
    actions: {
        [STORE_LINK_REDIRECT_AFTER_PAYMENT]({commit, dispatch}: { commit: any, dispatch: any },
                                            link: string){
            commit(STORE_LINK_REDIRECT_AFTER_PAYMENT,link);
        },
        [RESET_PERSIST_STORE]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(RESET_PERSIST_STORE);
        },
        [SUBTITLE_LANG]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(SUBTITLE_LANG, value);
        },
        [EXPERIMENTAL_FEATURE]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(EXPERIMENTAL_FEATURE, value);
        },
        [SET_LAST_NEWS_DATE]({commit, dispatch}: { commit: any, dispatch: any }, value: Date) {
            commit(SET_LAST_NEWS_DATE, value);
        },
        [SET_LAST_VERSION_NEWS]({commit, dispatch}: { commit: any, dispatch: any }, value: number) {
            commit(SET_LAST_VERSION_NEWS, value);
        },
        [CHANGE_PLAYER_SPEED]({commit, dispatch}: { commit: any, dispatch: any }, value: number) {
            commit(CHANGE_PLAYER_SPEED, value);
        },
        [FIRST_WEET_CONGRAT]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(FIRST_WEET_CONGRAT, value);
        },
        [ONBOARDING_RECORDER_1]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(ONBOARDING_RECORDER_1, value);
        },
        [ONBOARDING_PLAYER_1]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(ONBOARDING_PLAYER_1, value);
        },
        [ONBOARDING_SECTION_1]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(ONBOARDING_SECTION_1, value);
        },
        [TIME_LINE_VISU]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(TIME_LINE_VISU, value);
        },
        [SELECT_WORKSPACE]({commit, dispatch}: { commit: any, dispatch: any }, workspaceID: string) {
            commit(SELECT_WORKSPACE, workspaceID);
        },
        [CHANGE_PLAYER_VOLUME]({commit, dispatch}: { commit: any, dispatch: any }, value: number) {
            commit(CHANGE_PLAYER_VOLUME, value);
        },
    },
};
