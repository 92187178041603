import html2canvas from "html2canvas";

export const exportNode2PngDataURL = async (node: HTMLElement, quality: number = 0.8): Promise<string> => {
    // create a canvas
    const canvas = await html2canvas(node, {
        allowTaint: false,
        backgroundColor: null,
        useCORS: true,
        logging: false,
        ignoreElements:(element)=>{
            return !!element.getAttribute('ignoreImageExport');
        },
        width: node.offsetWidth,
        height: node.offsetHeight,
        scale: 2,
    })

    // uncomment to debug canvas:
    // document.body.append(canvas);
    // canvas.style.position = 'absolute';
    // canvas.style.top = '0';
    // canvas.style.left = '0';
    // canvas.style.zIndex = '2000';

    // uncomment to show the image export from canvas
    const imageUrl = canvas.toDataURL('image/png');
    // const img = new Image();
    // img.src = imageUrl;
    // document.body.append(img);
    // img.style.position = 'absolute';
    // img.style.top = '0';
    // img.style.left = '0';
    // img.style.zIndex = '2000';
    // return new Promise((resolve, reject) => {
    //     canvas.toBlob((blob) => {
    //         if (blob) {
    //             resolve(blob)
    //         } else {
    //             reject('Impossible to generate the blob')
    //         }
    //     }, 'image/png', quality)
    // })

    return imageUrl;

}
