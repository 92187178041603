export const AUTH_REQUEST = 'AUTH_REQUEST';
export const CREATE_ACCOUNT_USER = 'CREATE_ACCOUNT_USER';
export const CREATE_ACCOUNT_USER_SILENT = 'CREATE_ACCOUNT_USER_SILENT';
export const UPDATE_USER_CONNECTED = 'UPDATE_USER_CONNECTED';
export const MSAUTH_REQUEST = 'MSAUTH_REQUEST';
export const GAUTH_REQUEST = 'GAUTH_REQUEST';
export const EXTERNAL_SYSTEM_AUTH_REQUEST = 'EXTERNAL_SYSTEM_AUTH_REQUEST';
export const APPLE_AUTH_REQUEST = 'APPLE_AUTH_REQUEST';
export const UPDATE_SETTING_USER_CONNECTED = 'UPDATE_SETTING_USER_CONNECTED';
export const ADD_PRIVATE_USER_OPTION = 'ADD_PRIVATE_USER_OPTION';
export const REMOVE_PRIVATE_USER_OPTION = 'REMOVE_PRIVATE_USER_OPTION';
export const SHOW_POPUP_AUTH = 'SHOW_POPUP_AUTH';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const RESET_AUTH_STORE = 'RESET_USER_STORE';
export const SEND_LINK_PASSWORD = 'SEND_LINK_PASSWORD';
export const REGENERATE_PASSWORD = 'REGENERATE_PASSWORD';
export const RETURN_TO_ASKED_URL = 'RETURN_TO_ASKED_URL';
export const RESET_ALL_CONNEXTION = 'RESET_ALL_CONNEXTION';
export const REFRESH_USER = 'REFRESH_USER';
export const BLOCK_REDIRECTS_TO_LOGIN_PAGE = 'BLOCK_REDIRECTS_TO_LOGIN_PAGE';
export const UNBLOCK_REDIRECTS_TO_LOGIN_PAGE = 'UNBLOCK_REDIRECTS_TO_LOGIN_PAGE';

export class AuthParams {
  public login: string;
  public password: string;

  constructor(login: string, password: string) {
    this.password = password;
    this.login = login;
  }
}

export class GAuthParams {
  public token: string = '';
}

export class MSAuthParams {
  public token: string = '';
  public accessToken: string = '';
}

export class AppleAuthParams {
  public token: string = '';
  public firstName: string | null = null;
  public lastName: string | null = null;
}
