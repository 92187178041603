<template>
  <div class="commentContainer">
    <div ref="eventContainer">
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {CommentEvent} from '@/store/comment/commentModel';
import CommentEmote from '@/components/play/comment/CommentEmote.vue';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {Weet} from '@/store/weet/weetModel';
import {Howl} from 'howler';
import {commentForSecond} from '@/utils/commentUtil';

@Component({
  components: {
    CommentEmote
  },
})
export default class CommentEmotePlayer extends Vue {

  @Prop({default: ''})
  private weet!: Weet;
  private pop = new Howl({
    src: ['/sound/pop.mp3'],
    volume: 0.50,
  });
  private pop2 = new Howl({
    src: ['/sound/pop2.mp3'],
    volume: 0.20,
  });

  @Prop({default: 0})
  private timePlayer!: number;


  private TIME_ANIMATION_EMOTE: number = 6000;
  private MAX_ELEMENT_BY_SECOND = 10;

  get comments(): CommentEvent[] {
    return store.getters.getComments;
  }

  get newCreatedComment(): CommentEvent {

    return store.getters.getLastCreatedEvent;
  }

  @Watch('newCreatedComment')
  public onNewComment() {
    if (this.newCreatedComment.type === CommentTypeEnum.EMOTE) {
      this.insertEmote(this.newCreatedComment);
    }
  }

  @Watch('timePlayer')
  public onTimechange() {

    let timeToProcess = this.timePlayer;
    if (this.timePlayer > 1000) {
      // we compute one second before, to take the right event
      timeToProcess = timeToProcess - 1000;
    }
    // one time by second
    // we dont use the debounce car it can be provoqued error with seek or speed change
    if (timeToProcess % 1000 <= 200) {

      let commentToThisTime = commentForSecond(timeToProcess);

      // we reorder to give priorité to all content between emote content
      commentToThisTime = commentToThisTime.filter((emote) => {
        return emote.type === CommentTypeEnum.EMOTE;
      });

      // now we add all annotation
      // view to add in different time
      for (const comment of commentToThisTime.slice(0, this.MAX_ELEMENT_BY_SECOND)) {
        setTimeout(() => {
          this.insertEmote(comment);
        }, comment.time - this.timePlayer);
      }
    }
  }


  // create and destroy an emote
  public insertEmote(comment: CommentEvent) {
    const ComponentClass = Vue.extend(CommentEmote);
    const instance = new ComponentClass(
        {
          propsData: {comment: comment},
        },
    );
    instance.$mount();
    // @ts-ignore
    this.$refs.eventContainer.appendChild(instance.$el);
    setTimeout(() => {
      instance.$destroy();
      (instance.$el).remove();
    }, this.TIME_ANIMATION_EMOTE);
  }

}
</script>

<style scoped lang="scss">

</style>
