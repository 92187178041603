export class AiavatarGenerationModel {
    public text:string;
    public voice:AvatarVoiceAvatar;
    public avatar:Avatar;
    public style:AvatarStyle;

    constructor(text:string,
                avatar:Avatar,
                voice:AvatarVoiceAvatar,
                style:AvatarStyle) {
        this.text=text;
        this.voice=voice;
        this.style=style;
        this.avatar=avatar;
    }
}


export enum AvatarVoiceAvatar{
    FR_FR_VIVIENNE="fr-FR-VivienneMultilingualNeural",
    US_AVIA='en-US-AvaMultilingualNeural',
    US_EMMA='en-US-EmmaMultilingualNeural',
    DE_SERPHINA= 'de-DE-SeraphinaMultilingualNeural',
    ZN_XIAO='zh-CN-XiaochenMultilingualNeural'
}
export enum Avatar{
    LISA="lisa",
}
export enum AvatarStyle{
    GRACEFUL_SITTING="graceful-sitting",
    CASUAL_SITTING="casual-sitting",
    GRACEFUL_STANDING="graceful-standing",
    TECHNICAL_SITTING="technical-sitting",
    TECHNICAL_STANDING="technical-standing"
}
