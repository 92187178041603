export enum AdvancedEditingMode {
    NONE = 'NONE',
    CUT = 'CUT',
    CUTTER = 'CUTTER',
    MERGE = 'MERGE',
    CHAPTER = 'CHAPTER',
    THUMBNAIL = 'THUMBNAIL',
    SUBTITLE = 'SUBTITLE',
    ANNOTATION = 'ANNOTATION',
    INTERACTION = 'INTERACTION'
}
