export const SETTINGS_LIST = 'SETTINGS_LIST';
export const USER_ACCOUNT_CREATION_AVAILABLE = 'ACCOUNT_CREATION_AVAILABLE';
export const USER_AUTHENTICATION_AVAILABLE = 'USER_AUTHENTICATION_AVAILABLE';
export const WEET_CREATION_AVAILABLE = 'WEET_CREATION_AVAILABLE';
export const WEET_MAX_LIMIT = 'WEET_MAX_LIMIT';
export const INFORMATION_MESSAGE = 'INFORMATION_MESSAGE';




