import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import {User} from '@/store/user/userModel';
import {RETURN_TO_ASKED_URL} from '@/store/auth/authAction';
import {ExternalToolEnum} from '@/enum/ExternalToolEnum';
import {log} from '@/utils/log';

Vue.use(VueRouter);

const ifNotAuthenticated = (to: any, from: any, next: any) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next('/');
};

// Sometimes Vuejs auto-decode the uri component, and then the decodeURIComponent fails
const safeDecodeUriComponent = (value) => {
    let decodedValue = value;
    try {
        decodedValue = decodeURIComponent(value);
    } catch (e) {
        log.warn('Url decoding fails for value: ' + value);
    }
    return decodedValue;
};

const ifAuthenticated = (to: any, from: any, next: any) => {
    if (store.getters.isAuthenticated) {
        const user: User = store.getters.userConnected;
        if (user.expiredPassword && !(to.query.access_token && to.query.tool && to.query.tool.toUpperCase() === ExternalToolEnum.OTHER)) {
            next({name: 'createPassword'});
            return;
        }
        next();
        return;
    } else {
        // we save the last path
        store.dispatch(RETURN_TO_ASKED_URL, to.fullPath);
        // if we have an access token, and we are from an external system, we can use to authenticate ourselves,
        // and we don't have to go to the regular authentication page, instead we redirect to this other page whose
        // responsibility is to verify the token and authenticate the user
        if (to.query.access_token && to.query.tool && to.query.tool.toUpperCase() === ExternalToolEnum.OTHER) {
            next({
                name: 'CheckTokenAuthentication',
                query: {
                    access_token: to.query.access_token,
                    tool: to.query.tool
                }
            });
        } else {
            next({name: 'Authentication'});
        }
    }
};

const authenticateIfToken = (to: any, from: any, next: any) => {
    if (store.getters.isAuthenticated || !(to.query.access_token && to.query.tool && to.query.tool.toUpperCase() === ExternalToolEnum.OTHER)) {
        next();
        return;
    } else {
        // we save the last path
        store.dispatch(RETURN_TO_ASKED_URL, to.fullPath);
        // if we have an access token, and we are from an external system, we can use to authenticate ourselves,
        // and we don't have to go to the regular authentication page, instead we redirect to this other page whose
        // responsibility is to verify the token and authenticate the user
        next({
            name: 'CheckTokenAuthentication',
            query: {
                access_token: to.query.access_token,
                tool: to.query.tool
            }
        });
    }
};


const routes = [
    {
        path: '/',
        redirect: '/my/weets',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/login',
        name: 'Authentication',
        component: () => import('@/views/login/Login.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/login/google',
        name: 'AuthenticationGoogle',
        component: () => import('@/views/login/Login.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/identity/checkToken',
        name: 'CheckTokenAuthentication',
        props: (to) => ({
            tool: to.query.tool,
            accessToken: to.query.access_token,
        }),
        component: () => import('@/views/identity/CheckToken.vue'),
    },
    {
        path: '/slackSuccess',
        name: 'SlackSuccess',
        props: (route) => ({
            code: route.query.code,
            stateID: route.query.stateID,
            tool: ExternalToolEnum.SLACK,
            state: route.query.state,
        }),
        component: () => import('@/views/integrationSuccess/IntegrationSuccess.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/teamsSuccess',
        name: 'TeamsSuccess',
        props: (route) => ({
            state: route.query.state,
            code: route.query.code,
            tool: ExternalToolEnum.TEAMS,
            errorMessage: route.query.error_description
        }),
        component: () => import('@/views/integrationSuccess/IntegrationSuccess.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/teamsAuthStart',
        name: 'IntegrationTeamsStartOAuth',
        props: (route) => {
            const externalToolReference = route.query.externalToolReference ? safeDecodeUriComponent(route.query.externalToolReference) : null;
            return ({
                oauthStartUrl: route.query.oauthStartUrl,
                responseType: route.query.response_type,
                responseMode: route.query.response_mode,
                redirectUri: route.query.redirect_uri,
                scope: route.query.scope,
                state: route.query.state,
                externalToolReference: externalToolReference,
                tool: ExternalToolEnum.TEAMS
            });
        },
        component: () => import('@/views/integrationSuccess/IntegrationTeamsStart.vue'),
    },
    {
        path: '/editEmbed',
        name: 'CreateWeetEmbed',
        component: () => import('@/views/weet/EditWeetWiew.vue'),
        props: (to) => {
            const sharedEmails = to.query.sharedEmails;
            const externalToolReference = to.query.externalToolReference ? safeDecodeUriComponent(to.query.externalToolReference) : null;
            return ({
                weetTitle: to.query.weetTitle,
                sharedEmails: sharedEmails ? JSON.parse(sharedEmails) : sharedEmails,
                tool: to.query.tool?.toUpperCase(),
                externalToolReference: externalToolReference,
                teamID: to.query.teamId || to.query.teamID,
                userID: to.query.userId || to.query.userID,
                accessToken: to.query.access_token
            });
        },
        beforeEnter: ifAuthenticated
    },
    {
        path: '/editEmbed/:weetID',
        name: 'EditWeetEmbed',
        component: () => import('@/views/weet/EditWeetWiew.vue'),
        props: (to) => {
            const externalToolReference = to.query.externalToolReference ? safeDecodeUriComponent(to.query.externalToolReference) : null;
            return ({
                tool: to.query.tool?.toUpperCase(),
                externalToolReference: externalToolReference,
                teamID: to.query.teamId || to.query.teamID,
                userID: to.query.userId || to.query.userID,
                accessToken: to.query.access_token,
                weetID: to.params.weetID
            });
        },
        beforeEnter: ifAuthenticated,
    },
    /* Specific route for weet creation from teams menu. Because teams deeplink in bot menu does not support query param*/
    {
        path: '/editEmbed/userId/:userId/teamId/:teamId/externalToolReference/:externalToolReference/tool/:tool/weetTitle/:weetTitle',
        name: 'CreateWeetEmbed',
        component: () => import('@/views/weet/EditWeetWiew.vue'),
        props: (to) => {
            const sharedEmails = to.params.sharedEmails;
            const externalToolReference = to.params.externalToolReference ? safeDecodeUriComponent(to.params.externalToolReference) : null;
            const weetTitle = to.params.weetTitle ? safeDecodeUriComponent(to.params.weetTitle) : null;
            const teamId = to.params.teamId || to.params.teamID;
            const userId = to.params.userId || to.params.userID;
            return ({
                weetTitle: weetTitle,
                sharedEmails: sharedEmails ? JSON.parse(sharedEmails) : sharedEmails,
                tool: to.params.tool?.toUpperCase(),
                externalToolReference: externalToolReference,
                teamID: teamId,
                userID: userId
            });
        },
        beforeEnter: ifAuthenticated
    },
    {
        path: '/authorization',
        name: 'Authorize from external tools',
        component: () => import('@/views/identity/Authorize.vue'),
        props: (to) => {
            const email = to.query.email ? safeDecodeUriComponent(to.query.email) : null;
            const firstName = to.query.first_name ? safeDecodeUriComponent(to.query.first_name) : null;
            const lastName = to.query.last_name ? safeDecodeUriComponent(to.query.last_name) : null;
            const skip = to.query.skip?.toLowerCase() === 'true';
            const authRedirection = to.query.authRedirection?.toLowerCase() === 'true';
            return ({
                clientId: to.query.client_id,
                responseType: to.query.response_type,
                email: email,
                redirectURI: to.query.redirect_uri,
                firstName: firstName,
                lastName: lastName,
                scope: to.query.scope,
                state: to.query.state,
                skip: skip,
                authRedirection: authRedirection
            });
        }
    },
    {
        path: '/createAccount',
        name: 'CreateAccount',
        component: () => import('@/views/login/CreateAccount.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: () => import('@/views/forgotPassword/ForgotPassword.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/regeneratePassword/:token',
        name: 'RegeneratePassword',
        props: true,
        component: () => import('@/views/forgotPassword/RegeneratePassword.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {

        path: '/my/weets',
        name: 'MyWeets',
        component: () => import('@/views/user/MyWeets.vue'),
        beforeEnter: ifAuthenticated,
    },
    {

        path: '/my/profile',
        name: 'MyProfile',
        component: () => import('@/views/user/MyProfile.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/my/notifications',
        name: 'MyNotification',
        component: () => import('@/views/user/MyNotification.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/workspace/manage',
        name: 'MyWorkspaceManageMembers',
        component: () => import('@/views/workspace/MyWorkspaceManageMembers.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/workspace/:workspaceID/manage',
        name: 'MyWorkspaceManageMembers',
        component: () => import('@/views/workspace/MyWorkspaceManageMembers.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/workspace/channels',
        name: 'MyWorkspaceManageChannels',
        component: () => import('@/views/workspace/MyWorkspaceManageChannels.vue'),
        props: true,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/workspace/:workspaceID/channels',
        name: 'MyWorkspaceManageChannels',
        component: () => import('@/views/workspace/MyWorkspaceManageChannels.vue'),
        props: true,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/workspace/weets',
        name: 'MyWorkspaceManageWeets',
        component: () => import('@/views/workspace/MyWorkspaceManageWeets.vue'),
        props: true,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/workspace/:workspaceID/weets',
        name: 'MyWorkspaceManageWeets',
        component: () => import('@/views/workspace/MyWorkspaceManageWeets.vue'),
        props: true,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/workspace/settings',
        name: 'MyWorkspaceSettings',
        component: () => import('@/views/workspace/MyWorkspaceSettings.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    },
    {
        path: '/workspace/:workspaceID/settings',
        name: 'MyWorkspaceSettings',
        component: () => import('@/views/workspace/MyWorkspaceSettings.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    }, {
        path: '/workspace/policy',
        name: 'MyWorkspaceVisibility',
        component: () => import('@/views/workspace/MyWorkspaceVisibility.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    },
    {
        path: '/workspace/:workspaceID/policy',
        name: 'MyWorkspaceVisibility',
        component: () => import('@/views/workspace/MyWorkspaceVisibility.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    },
    {
        path: '/workspace/edit',
        name: 'MyWorkspaceEdit',
        component: () => import('@/views/workspace/MyWorkspaceEdit.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    },
    {
        path: '/workspace/:workspaceID/edit',
        name: 'MyWorkspaceEdit',
        component: () => import('@/views/workspace/MyWorkspaceEdit.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    },
    {
        path: '/workspace/delete',
        name: 'MyWorkspaceDelete',
        component: () => import('@/views/workspace/MyWorkspaceDelete.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    },
    {
        path: '/workspace/:workspaceID/delete',
        name: 'MyWorkspaceDelete',
        component: () => import('@/views/workspace/MyWorkspaceDelete.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    },
    {
        path: '/workspace/payment',
        name: 'MyWorkspacePayment',
        component: () => import('@/views/workspace/MyWorkspacePayment.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/workspace/:workspaceID/payment',
        name: 'MyWorkspacePayment',
        component: () => import('@/views/workspace/MyWorkspacePayment.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/workspace/paymentSuccess',
        name: 'MyWorkspacePaymentSuccess',
        component: () => import('@/views/workspace/MyWorkspacePaymentSuccess.vue'),
        beforeEnter: ifAuthenticated,
        props: (to) => {
            return ({
                sessionID: to.query.session_id,
                workspaceID: to.query.workspace_id
            });
        },
    },
    // Warning, this route should be before all others '/workspace/xxx' routes
    {
        path: '/workspace/:workspaceID',
        name: 'MyWorkspaceInvite',
        component: () => import('@/views/workspace/MyWorkspaceInvite.vue'),
        beforeEnter: ifAuthenticated,
        props: true
    },
    {
        path: '/edit',
        name: 'EditWeet',
        component: () => import('@/views/weet/EditWeetWiew.vue'),
        props: (to) => {
            const sharedEmails = to.query.sharedEmails;
            const weetTitle = to.query.weetTitle ? safeDecodeUriComponent(to.query.weetTitle) : null;
            const externalToolReference = to.query.externalToolReference ? safeDecodeUriComponent(to.query.externalToolReference) : null;
            const teamId = to.query.teamId || to.query.teamID;
            const userID = to.query.userId || to.query.userID;
            const iframeRemover = to.query.iframeRemover
            return ({
                weetTitle: weetTitle,
                sharedEmails: sharedEmails ? JSON.parse(sharedEmails) : sharedEmails,
                externalToolReference: externalToolReference,
                tool: to.query.tool?.toUpperCase(),
                teamID: teamId,
                userID: userID,
                iframeRemover: iframeRemover,
            });
        },
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/edit/:weetID',
        name: 'EditWeet',
        props: true,
        component: () => import('@/views/weet/EditWeetWiew.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/my/setup',
        name: 'Setup',
        component: () => import('@/views/setup/Setup.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/play/:weetID/:title',
        name: 'PreviewWeet',
        component: () => import('@/views/weet/PlayWeetView.vue'),
        props: (to) => {
            return ({
                tool: to.query.tool?.toUpperCase(),
                accessToken: to.query.access_token,
                weetID: to.params.weetID,
                title: to.params.title,
                time: to.params.time,
                commentID: to.params.commentID,
                commentToReply: to.params.commentToReply
            });
        },
        beforeEnter: authenticateIfToken
    },
    {
        path: '/play/:weetID',
        name: 'PreviewWeet',
        component: () => import('@/views/weet/PlayWeetView.vue'),
        props: (to) => {
            return ({
                tool: to.query.tool?.toUpperCase(),
                accessToken: to.query.access_token,
                weetID: to.params.weetID,
                title: to.params.title,
                time: to.params.time,
                commentID: to.params.commentID,
                commentToReply: to.params.commentToReply
            });
        },
        beforeEnter: authenticateIfToken
    },
    {
        path: '/comment/:weetID',
        name: 'ViewComment',
        props: (to) => ({
            weetID: to.params.weetID,
            time: to.query.time,
            commentID: to.query.commentID,
            commentToReply: to.query.commentToReply,
        }),
        component: () => import('@/views/weet/PlayWeetView.vue'),
    },
    {
        path: '/embed/:weetID/:title',
        name: 'PreviewEmbeddedWeet',
        component: () => import('@/views/weet/EmbedWeetView.vue'),
        props: true,
        beforeEnter: authenticateIfToken
    },
    {
        path: '/tutorials/unsubscribe',
        name: 'Unsubscribe',
        component: () => import('@/views/unsubscribe/Unsubscribe.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/embed/:weetID',
        redirect: {name: 'PreviewEmbeddedWeet'}
    },
    {
        path: '/workspace/create',
        name: 'CreateWorkspace',
        component: () => import('@/views/workspace/MyWorkspaceCreate.vue'),
        props: true,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/sad',
        name: 'ByeBye',
        component: () => import('@/views/byebye/ByeBye.vue'),
    },
    {
        path: '/weetNotFound',
        name: 'NotFoundWeet',
        component: () => import('@/views/notFound/NotFoundWeet.vue'),
    },
    {
        path: '/accessDenied',
        name: 'AccessDenied',
        component: () => import('@/views/accessDenied/AccessDeniedWeet.vue'),
    },
    {
        path: '/externalToolUserMismatch',
        name: 'ExternalToolUserMismatch',
        props: true,
        component: () => import('@/views/externalToolUserMismatch/ExternalToolUserMismatch.vue'),
    },
    {
        path: '/helpVersion',
        name: 'HelpVersion',
        component: () => import('@/views/helpVersion/HelpVersion.vue'),
    },

    /** TOOLS **/
    {   // noise canceling
        path:'/remove-background-noise-from-video',
        name:'NewNoiseCancelling',
        props:true,
        component: ()=> import('@/views/tools/NoiseCancellingTool.vue')
    },
    {
        path:'/remove-background-noise-from-video/:weetID',
        name:'NoiseCancelling',
        props:true,
        component: ()=> import('@/views/tools/NoiseCancellingTool.vue')
    },
        // host a video
    {
        path:'/video-hosting',
        name:'NewHostYourVideo',
        component: ()=> import('@/views/tools/HostVideoTool.vue')
    },
    {
        path:'/video-hosting/:weetID',
        name:'HostYourVideo',
        props:true,
        component: ()=> import('@/views/tools/HostVideoTool.vue')
    },
        // share a video
    {
        path:'/share-video/:weetID',
        name:'ShareYourVideo',
        props:true,
        component: ()=> import('@/views/tools/ShareVideoTool.vue')
    },
    {
        path:'/share-video',
        name:'NewShareYourVideo',
        component: ()=> import('@/views/tools/ShareVideoTool.vue')
    },
        // Compress a video
    {
        path:'/video-compressor/:weetID',
        name:'CompressYourVideo',
        props:true,
        component: ()=> import('@/views/tools/CompressVideoTool.vue')
    },
    {
        path:'/video-compressor',
        name:'NewCompressYourVideo',
        component: ()=> import('@/views/tools/CompressVideoTool.vue')
    },
    // Trim a video
    {
        path:'/video-trimmer/:weetID',
        name:'CutYourVideo',
        props:true,
        component: ()=> import('@/views/tools/CutVideoTool.vue')
    },
    {
        path:'/video-trimmer',
        name:'NewCutYourVideo',
        component: ()=> import('@/views/tools/CutVideoTool.vue')
    },
    // Chapter a video
    {
        path:'/add-chapter-to-video/:weetID',
        name:'ChapterYourVideo',
        props:true,
        component: ()=> import('@/views/tools/ChapterVideoTool.vue')
    },
    {
        path:'/add-chapter-to-video',
        name:'NewChapterYourVideo',
        component: ()=> import('@/views/tools/ChapterVideoTool.vue')
    },
    // Image a video
    {
        path:'/add_image_to_your_video/:weetID',
        name:'AddImageVideo',
        props:true,
        component: ()=> import('@/views/tools/AddImageVideoTool.vue')
    },
    {
        path:'/add_image_to_your_video',
        name:'NewAddImageVideo',
        component: ()=> import('@/views/tools/AddImageVideoTool.vue')
    },
    // Transcript a video
    {
        path:'/transcribe-video/:weetID',
        name:'TranscriptYourVideo',
        props:true,
        component: ()=> import('@/views/tools/TranscriptVideoTool.vue')
    },
    {
        path:'/transcribe-video',
        name:'NewTranscriptYourVideo',
        component: ()=> import('@/views/tools/TranscriptVideoTool.vue')
    },
    // Translate a video
    {
        path:'/translate-video/:weetID',
        name:'TranslateYourVideo',
        props:true,
        component: ()=> import('@/views/tools/TranslateVideoTool.vue')
    },
    {
        path:'/translate-video',
        name:'NewTranslateYourVideo',
        component: ()=> import('@/views/tools/TranslateVideoTool.vue')
    },
    /** TESTER **/
    {
        path: '/webcamTester',
        name: 'WebcamTester',
        component: () => import('@/views/test/WebcamCanvasTester.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/thumbnailTester',
        name: 'thumnailTester',
        component: () => import('@/views/test/ThumbnailCanvasTester.vue'),
        beforeEnter: ifAuthenticated,
    },
    /** SUPERADMIN **/
    {
        path: '/debug087A63HQSFHJIUAUIJD',
        name: 'Debug',
        component: () => import('@/views/debug/DebugPage.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/_867A099384990U8UA0',
        name: 'SuperAdmin',
        component: () => import('@/views/superAdmin/_ListOfWeets.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/_867A099384990U8UA0_PLAYER',
        name: 'SuperAdminPLAYERS',
        component: () => import('@/views/superAdmin/_ListOfWeetsPlayer.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/_n098CHJHA098HhfhiA87/play/:weetID/',
        name: 'SuperAdminPlayWeet',
        component: () => import('@/views/superAdmin/_PlayWeetView.vue'),
        beforeEnter: ifAuthenticated,
        props: true,
    },
    {
        path: '/_n098CHJHA098HhfhiA87/embed/:weetID/',
        name: 'SuperAdminPlayEmbedWeet',
        component: () => import('@/views/superAdmin/_EmbedWeetView.vue'),
        beforeEnter: ifAuthenticated,
        props: true,
    },
    /** NOT FOUND **/
    {
        path: '/*',
        name: 'NotFound',
        component: () => import('@/views/notFound/NotFound.vue'),
    },

];


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export const isToolsRoute=(route:string)=>{
    const listOfToolsRoute=['NoiseCancelling','NewNoiseCancelling','HostYourVideo','NewHostYourVideo',
        'ShareYourVideo','NewShareYourVideo','CompressYourVideo','NewCompressYourVideo',
        'ChapterYourVideo','NewChapterYourVideo','CutYourVideo','NewCutYourVideo','AddImageVideo',
        'NewAddImageVideo', 'TranscriptYourVideo','NewTranscriptYourVideo',
        'TranslateYourVideo','NewTranslateYourVideo'];
    return listOfToolsRoute.indexOf(route)!=-1;
}

export default router;
