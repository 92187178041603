import {ActionEvent, PageEvent} from '@/enum/TrackerEnum';
import amplitude from 'amplitude-js';
import {User} from '@/store/user/userModel';
import store from '@/store';
import getParameter from 'get-parameter';
import {log} from '@/utils/log';

// @ts-ignore
const HOTJAR = window.hj;

export const getDeviceID = (): string | undefined => {
  const param = getParameter('device');
  if (param) {
    log.debug('DeviceID Found : ' + param);
    return param;
  } else {
    return undefined;
  }
};
export const setPageEvent = (event: PageEvent, properties: any = undefined) => {
  get().logEvent(event, enhancePropertiesEvent(properties));
  if (HOTJAR) {
    const user = store.getters.userConnected;
    if (user) {
      HOTJAR('identify', user.userID, {
        email: user.contactEmail,
        firstname: user.firstName,
        lastname: user.lastName,
        userID: user.userID
      });
    }
    HOTJAR('stateChange', event);
  }
};


export const setActionEvent = (event: ActionEvent, properties: any = undefined) => {
  get().logEvent(event, enhancePropertiesEvent(properties));
};

const get = () => {
  return amplitude.getInstance();
};


export const setIdentity = (user: User) => {
  const identify = new amplitude.Identify();
  identify.setOnce('ACCOUNT_CREATED', new Date());
  get().setUserId(user.userID);
  get().identify(identify);

};

export const setVersion = () => {
  get().setVersionName(process.env.VUE_APP_VERSION || '');
};

export const logout = () => {
  get().setUserId(null); // not string 'null'
};

const enhancePropertiesEvent = (properties: any = undefined): any => {
  if (!properties) {
    properties = {};
  }
  properties.isAuthenticated = store.getters.isAuthenticated;
  if (store.getters.getEditingWeetID) {
    properties.editingWeetID = store.getters.getEditingWeetID;
  }
  properties.workspaceID = store.getters.getSelectedWorkspaceID;
  return properties;
};
