import {
    PaymentPlanEnum,
    SettingWorkspace,
    SettingWorkspaceKey,
    Workspace,
    WorkspaceVisibilityPolicyEnum
} from '@/store/workspace/workspaceModel';
import store from '@/store';
import {
    GET_WORKSPACE_WEET_COUNT,
    REINIT_WORKSPACE_USERS_SEARCHED,
    SET_MY_LOGO_HEADER
} from '@/store/workspace/workspaceAction';
import {StaticTag} from '@/store/myWeet/myWeetAction';
import {i18n} from '@/utils/i18nUtils';
import {Weet} from '@/store/weet/weetModel';
import {SELECT_WORKSPACE} from '@/store/persistedInformation/persistAction';
import {
    GET_MY_CHANNEL_SUBSCRIPTION,
    GET_MY_WORKSPACE_CHANNELS,
    REINIT_CHANNEL_FOR_WORKSPACE,
    SELECT_WORKSPACE_CHANNEL
} from '@/store/channel/channelAction';
import {ChannelParams} from '@/store/channel/channelModel';
import {VisibilityChannelPolicy} from '@/store/channel/VisibilityChannelPolicy';
import {GET_WORKSPACE_RIGHTS} from '@/store/workspaceRight/workspaceRightAction';
import {WorkspaceRightManagmentEnum} from '@/store/workspaceRight/workspaceRightModel';
import {loadTheme, Theme} from "@/theme";
import {getImageURLForSize} from "@/utils/util";

export const MIN_WORKSPACE_NAME = 1;
export const MAX_WORKSPACE_NAME = 50;
export const WORKSPACE_EMAIL_FILTER = '@';
export const WORKSPACE_CHANNEL_FILTER = '#';

/**
 * When the workspace is selected, the list of Weets, rights and the list of recently shared are updated.
 * @param selectedWorkspaceID, the ID of workspace selected or null if selected workspace is the default one (Personnal Workspace)
 * @private
 */
export const selectWorkspace = (selectedWorkspaceID: string | null = null) => {
    // Select the workspace and update the last selected
    store.dispatch(SELECT_WORKSPACE, selectedWorkspaceID);
    store.dispatch(REINIT_WORKSPACE_USERS_SEARCHED);

    if (selectedWorkspaceID) {

        // Get the user authorizations for selected workspace
        store.dispatch(GET_WORKSPACE_RIGHTS).then(() => {
            store.dispatch(GET_WORKSPACE_WEET_COUNT);
        });
    }
    store.dispatch(REINIT_CHANNEL_FOR_WORKSPACE);
    store.dispatch(SELECT_WORKSPACE_CHANNEL, '');
    if (selectedWorkspaceID) {
        // Load channels
        store.dispatch(GET_MY_WORKSPACE_CHANNELS,
            new ChannelParams(0));
        // load subscription of channels
        store.dispatch(GET_MY_CHANNEL_SUBSCRIPTION, new ChannelParams(0));
    }
};

export const selectWorkspaceFromWeet = (weet: Weet) => {
    // get workspace selected
    const selectedWorkspaceID = store.getters.getSelectedWorkspaceID;
    const listOfMyWorkspace = store.getters.getMyWorkspaces;
    let wsIDOfWeet = '';

    if (weet.workspaces.length > 0) {
        // we take the first one because e support only one workspace for the moment
        wsIDOfWeet = weet.workspaces[0].workspaceID;
        if (wsIDOfWeet === selectedWorkspaceID) {
            // we are already on the good workspace
            return;
        }
        for (const myWS of listOfMyWorkspace) {
            if (myWS.workspaceID === wsIDOfWeet) {
                selectWorkspace(wsIDOfWeet);
                return;
            }
        }
    }
    if (selectedWorkspaceID) {
        // return to personnal workspace because the weet is not on a workspace of the user
        selectWorkspace();
    }
};

export const canAccessWorkspaceSetting = (): boolean => {
    return checkAnyRightWorkspace(rightsSettingsWorkspace());
};

export const canAccessMembers = (): boolean => {
    return checkRightWorkspace(WorkspaceRightManagmentEnum.ACCESS_USER);
};

export const canManageMembers = (): boolean => {
    return checkAnyRightWorkspace(rightsManageMembers());
};

export const canViewAllWorkspaceWeets = (): boolean => {
    return checkAnyRightWorkspace(rightsViewAllWorkspaceWeets());
};

export const canDeleteOthersWeets = (): boolean => {
    return checkRightWorkspace(WorkspaceRightManagmentEnum.DELETE_OTHER_WEET);
};

export const canAddChannels = (): boolean => {
    return checkRightWorkspace(WorkspaceRightManagmentEnum.CREATE_CHANNEL) &&
        checkRightWorkspace(WorkspaceRightManagmentEnum.ACCESS_CHANNEL);
};


export const canManageChannels = (): boolean => {
    return checkAnyRightWorkspace(rightsManageChannels());
};


export const canDeleteChannels = (): boolean => {
    return checkRightWorkspace(WorkspaceRightManagmentEnum.DELETE_CHANNEL) &&
        checkRightWorkspace(WorkspaceRightManagmentEnum.ACCESS_CHANNEL);
};

export const canEditWorkspace = (): boolean => {
    return checkAnyRightWorkspace(rightsEditWorkspace());
};
export const canManagePayments = (): boolean => {
    return checkAnyRightWorkspace(rightsManagePayment());
};

export const canDeleteWorkspace = (): boolean => {
    return checkAnyRightWorkspace(rightsDeleteWorkspace());
};

export const rightsEditWorkspace = (): WorkspaceRightManagmentEnum[] => {
    return [WorkspaceRightManagmentEnum.EDIT_WORKSPACE];
};
export const rightsDeleteWorkspace = (): WorkspaceRightManagmentEnum[] => {
    return [WorkspaceRightManagmentEnum.DELETE_WORKSPACE];
};
export const rightsManagePayment = (): WorkspaceRightManagmentEnum[] => {
    return [WorkspaceRightManagmentEnum.MANAGE_PAYMENTS];
};
export const rightsViewAllWorkspaceWeets = (): WorkspaceRightManagmentEnum[] => {
    return [WorkspaceRightManagmentEnum.VIEW_ALL_WORKSPACE_WEETS];
};
export const rightsManageMembers = (): WorkspaceRightManagmentEnum[] => {
    return [WorkspaceRightManagmentEnum.EDIT_USER, WorkspaceRightManagmentEnum.DELETE_USER];
};
export const rightsManageChannels = (): WorkspaceRightManagmentEnum[] => {
    return [WorkspaceRightManagmentEnum.CREATE_CHANNEL, WorkspaceRightManagmentEnum.DELETE_CHANNEL];
};
export const rightsSettingsWorkspace = (): WorkspaceRightManagmentEnum[] => {
    return Array.prototype.concat(rightsEditWorkspace(), rightsDeleteWorkspace(), rightsManagePayment(), rightsViewAllWorkspaceWeets(), rightsManageMembers());
};

export const isWorkspaceInFreePlan = (): boolean => {
    const selectedWorkspace = store.getters.getSelectedWorkspace;
    if (selectedWorkspace) {
        return selectedWorkspace.plan === PaymentPlanEnum.FREE;
    } else {
        return false;
    }
};


export const canSendInvite = (): boolean => {
    return checkRightWorkspace(WorkspaceRightManagmentEnum.CREATE_USER);
};

export const checkAnyRightWorkspace = (rights: WorkspaceRightManagmentEnum[]): boolean => {
    return rights.some((right) => checkRightWorkspace(right));
};

export const checkRightWorkspace = (right: WorkspaceRightManagmentEnum): boolean => {
    const selectedWorkspaceID = store.getters.getSelectedWorkspaceID;
    return store.getters.getWorkspaceRightByKey(selectedWorkspaceID, right);
};

export const canCreateWeet = (): boolean => {
    const selectedWorkspaceID = store.getters.getSelectedWorkspaceID;
    if (!selectedWorkspaceID) {
        return true;
    } else {
        return store.getters.getWorkspaceRightByKey(selectedWorkspaceID, WorkspaceRightManagmentEnum.CREATE_WEET);
    }
};

export const isFreemiumQuotaReached = (): boolean => {
    const selectedWorkspaceID = store.getters.getSelectedWorkspaceID;
    return (selectedWorkspaceID && isWorkspaceInFreePlan() && store.getters.isFreemiumQuotaReached(selectedWorkspaceID));
}

export const isFreemium = (): boolean => {
    return PaymentPlanEnum.FREE === store.getters.getSelectedWorkspace?.plan;
};
export const haveSeatsAvailable = (): boolean => {
    return numberOfSeatsAvailable() > 0;
};

export const numberOfSeatsAvailable = (): number => {
    return Math.max((store.getters.getMyWorkspacePayment?.seatCount != null ? store.getters.getMyWorkspacePayment.seatCount : 0) - store.getters.getTotalOfWorkspaceCreators, 0);
};


export const canMoveWeetFromCurrentWorkspace = (): boolean => {
    const selectedWorkspaceID = store.getters.getSelectedWorkspaceID;
    if (!selectedWorkspaceID) {
        return true;
    } else {
        return store.getters.getWorkspaceRightByKey(selectedWorkspaceID, WorkspaceRightManagmentEnum.MOVE_WEET);
    }
};

export const validateNameOfWorkspace = (name: string): boolean => {
    if (name.trim().length < MIN_WORKSPACE_NAME) {
        return false;
    } else if (name.trim().length > MAX_WORKSPACE_NAME) {
        return false;
    }
    return true;
};

export const workspaceVisibilityPolicyList = (): any[] => {
    return [
        {
            value: WorkspaceVisibilityPolicyEnum.PRIVATE,
            label: i18n.t('workspace.visibilityPolicy.privatePolicy')
        },
        {
            value: WorkspaceVisibilityPolicyEnum.PUBLIC,
            label: i18n.t('workspace.visibilityPolicy.publicPolicy')
        }];
};

export const getIconForChannel = (value: string | StaticTag, channelID: string = ''): string => {
    switch (value) {
        case StaticTag.ALL:
            return 'play-box-multiple-outline';
        case StaticTag.LAST_VIEWED:
            return 'clock-outline';
        case StaticTag.SHARED_WITH_ME:
            return 'at';
        case StaticTag.CREATED_BY_ME:
            return 'account-box-outline';
        case StaticTag.DRAFT:
            return 'file-document-edit-outline';
        default: {
            if (channelID) {
                const channelElement = store.getters.getChannel(channelID);
                if (channelElement.visibilityPolicy === VisibilityChannelPolicy.PRIVATE) {
                    return 'lock-outline';
                }
            }
            return 'pound';
        }
    }
};

export function isInvitationExpired(joined: number, workspace: Workspace): boolean {
    const today = new Date().getTime();
    const invitationExpirationSetting = workspace.settings?.find((setting: SettingWorkspace) => setting.key === SettingWorkspaceKey.WORKSPACE_USER_INVITE_TTL);
    if (!invitationExpirationSetting) {
        return false;
    }
    const expirationTTLMilliseconds = +invitationExpirationSetting.value * 24 * 3600 * 1000; // Convert nbdays to millis
    return joined <= (today - expirationTTLMilliseconds);
}

export function hasPaidWorkspace(): boolean {
    const listWorkspace = store.getters.getMyWorkspaces;
    for (const ws of listWorkspace) {
        if (ws.plan !== PaymentPlanEnum.FREE) {
            return true;
        }
    }
    return false;
}

export const manageThemeForWorkspace = (ws: Workspace | undefined) => {
    if (ws) {
        ws.theme ? loadTheme(ws.theme as Theme) : loadTheme();
        ws.imageHeaderURL ? store.dispatch(SET_MY_LOGO_HEADER, getImageURLForSize(ws.imageHeaderURL, 500)) : store.dispatch(SET_MY_LOGO_HEADER, '');
    } else {
        loadTheme(Theme.DEFAULT);
        store.dispatch(SET_MY_LOGO_HEADER, '');
    }
}
