<template>
  <div class="segmentBigItem" v-show="mustShow">
    <b-dropdown ref="dropdown" position="is-top-right" trap-focus
                animation="zoom" :mobile-modal="false"
                :close-on-click="false"
                class="dropDownTimeLineBigElement"
                @active-change="activeChange"
    >
      <div slot="trigger" class="dropDownTrigger">
        <w-tool-tip :enlarge="true" style="width: 100%;height: 100%;"
                    :label="getTooltipForSection(segment.start)">
          <ImageSection :segment="segment" :class="this.showPreview?'withBorder':''"
          />
        </w-tool-tip>
        <div class="buttonDeleteSection" @click.stop="deleteASection(segment.start.time)">
          <w-icon color="white" class="iconClose" icon="close-circle" size="small"></w-icon>
        </div>
        <div class="titleSection">
          {{ getTitleForSection(segment.start) }}
        </div>
        <div class="timeSection">
          {{ getHumanTime }}
        </div>
      </div>
      <b-dropdown-item
          :focusable="false"
          custom
          class="playerSectionContainer">
        <SectionTitle v-if="false && getWeet!==undefined" :time="segment.start.time" :weet="getWeet"/>
        <div class="sectionPlayer">
          <SectionPlayer v-if="showPreview " :segment="segment"
                         :medias="getMedia"/>
        </div>
        <w-button class="close"
                  :circle="true" color="light"
                  icon="close" size="small" @click="closeDropDown()"
        />
      </b-dropdown-item>

    </b-dropdown>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/store';
import {Segment, TimeEvent} from '@/store/timeLine/timeEvent';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import prettyMilliseconds from 'pretty-ms';
import SectionPlayer from '@/components/play/sectionplayer/SectionPlayer.vue';
import {Media} from '@/store/media/mediaModel';
import WModal from '@/components/wrapper/w-modal.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WButton from '@/components/wrapper/w-button.vue';
import ImageSection from '@/components/play/sectionplayer/ImageSection.vue';
import {createUnsavedEditingWeet, getMediasForWeet} from '@/utils/weetUtil';
import {Weet} from '@/store/weet/weetModel';
import {REMOVE_SECTION_EVENT} from '@/store/timeLine/timeLineAction';
import once from 'lodash/once';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import SectionTitle from '@/components/play/sectionplayer/SectionTitle.vue';
import {getTitleTimeEventEvent} from '@/utils/timeLineUtils';

@Component({
  components: {SectionTitle, ImageSection, WButton, WIcon, WModal, SectionPlayer, WToolTip},
})
export default class TimeLineElementBig extends Vue {

  @Prop()
  private segment!: Segment;

  @Prop({default: 0})
  private index!: number;
  private mustShow: boolean = false;
  private showPreview: boolean = false;

  private mounted() {
    this.mustShow = true;
  }

  private activeChange(active: boolean) {
    if (active) {
      this.showPreview = true;
    } else {
      // waiting animation
      setTimeout(() => {
        this.showPreview = false;
      }, 100);
    }
  }

  get getTimeLine(): [] {
    return store.getters.getTimeEvent;
  }

  private getTooltipForSection(timeEvent: TimeEvent): string {
    const sectionTitle = this.getTitleForSection(timeEvent);
    if (sectionTitle) {
      return this.$t('createWeetComponent.timeline.drag').toString() + ': ' + sectionTitle;
    } else {
      return this.$t('createWeetComponent.timeline.drag').toString();
    }
  }

  private getTitleForSection(timeEvent: TimeEvent): string {
    const te = getTitleTimeEventEvent(this.getTimeLine, timeEvent);
    if (te) {
      return te.value;
    } else {
      return '';
    }
  }

  get getWeet(): Weet {
    return createUnsavedEditingWeet(store.getters.getEditingWeetID);
  }

  get getMediaForSegment(): Media | undefined {
    if (this.segment.start.secondMedia.mediaID) {
      return getMediasForWeet(store.getters.getMyWeet(store.getters.getEditingWeetID), this.segment.start.secondMedia.mediaID);
    } else {
      return getMediasForWeet(store.getters.getMyWeet(store.getters.getEditingWeetID), this.segment.start.mainMedia.mediaID);
    }
  }

  get getMedia(): Media[] {
    const weet = store.getters.getMyWeet(store.getters.getEditingWeetID);
    if (weet) {
      return store.getters.getMedias.concat(weet.medias);
    } else {
      return store.getters.getMedias;
    }
  }

  get getTime(): number {
    return this.segment.end.time - this.segment.start.time;
  }

  get getHumanTime(): string {
    return prettyMilliseconds(this.getTime, {secondsDecimalDigits: 0, colonNotation: true});
  }

  private closeDropDown() {
    // @ts-ignore
    this.$refs.dropdown.toggle();
  }

  private deleteASection(time: number) {
    this.$buefy.dialog.confirm({
      title: this.$t('sectionDelete.dialog.title').toString(),
      message: this.$t('sectionDelete.dialog.confirm').toString(),
      confirmText: this.$t('sectionDelete.dialog.button').toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: once(() => {
        store.dispatch(REMOVE_SECTION_EVENT, time);
        setActionEvent(ActionEvent.record_remove_section);

      })
    });

  }
}
</script>
<style lang="scss">

// Find a better way to do that
.dropDownTimeLineBigElement {
  .dropdown-trigger {
    width: 100%;
    height: 100%;
  }

  .dropdown-menu {
    animation-duration: 0.2s;

    .dropdown-content {
      background: none;
      box-shadow: none;

      .dropdown-item {
        padding: 8px;
      }
    }

  }
}
</style>
<style scoped lang="scss">
@import "@/scss/shadows.scss";

.segmentBigItem {
  width: 100%;
  animation-duration: 0.2s;
  // padding for the hover catch
  cursor: pointer;
  animation-delay: 0.1s;
  height: 66px;
}

.buttonDeleteSection {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0px;
  right: 0px;
  // background: red;
  .iconClose {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.titleSection {
  position: absolute;
  text-align: left;
  margin-left: 4px;
  width: calc(100% - 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-size: 11px;
  height: 16px;
  top: 0px;
  left: 0px;

}

.close {
  border: 2px solid white;
  border-radius: 50px;
  position: absolute;
  right: -16px;
  top: -16px;
}

.playerSectionContainer {
  width: 468px;
  margin-left: -234px;
  top: -8px;
  border-radius: 12px;
  background: #FFFFFF;
  padding: 8px;
  position: relative;
  @extend .shadow1;

  .sectionPlayer {
    height: 254px;
    position: relative;
  }
}

.backgroundDropDown {
  // background: red;
}

.dropDownTrigger {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 4px;

  .withBorder {
    border: solid 2px var(--primary);
    border-radius: 4px;
  }

}

.dropDownTimeLineBigElement {
  width: 100%;
  height: 100%;
}

.timeSection {
  color: white;
  bottom: 0;
  position: absolute;
  right: 4px;
  font-size: 10px;
}

</style>
