<template>
  <div>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import {createUnsavedEditingWeet} from '@/utils/weetUtil';
import store from '@/store';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {ThumbnailGenerator} from '@/utils/video/videoEffect/ThumbnailGenerator';
import {getMediaForTimeEvent, getTimeEventbeforeTime} from '@/utils/timeLineUtils';
import {TimeEventType} from '@/enum/TimeEventType';
import {UPDATE_MY_WEET_IMAGE} from '@/store/myWeet/myWeetAction';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {Media} from '@/store/media/mediaModel';
import debounce from 'lodash/debounce';
import {isMediaNeedRefresh} from "@/utils/util";

@Component({
  components: {},
})
export default class ThumbnailAutomaticGenerator extends Vue {

  private DEFAULT_TIME = 1000;
  private tumbnailGenerator!: ThumbnailGenerator;
  private thumbnailInProgress: boolean = false;

  // get Editing Weet
  get weetID(): string {
    return store.getters.getEditingWeetID;
  }

  get myWeet(): Weet | undefined {
    return createUnsavedEditingWeet(this.weetID);
  }

  get isThumbnailGenerated(): boolean {
    if (this.myWeet) {
      return !!this.myWeet.thumbnail;
    } else {
      return false;
    }
  }

  get timeEventToGenerate(): TimeEvent | undefined {
    if (this.myWeet) {
      return getTimeEventbeforeTime(this.myWeet.timeLine, this.DEFAULT_TIME, TimeEventType.MEDIA_PLAY);
    } else {
      return undefined;
    }
  }

  get getMainMediaForWeet(): Media | undefined {
    if (this.myWeet && this.timeEventToGenerate) {
      return getMediaForTimeEvent(this.myWeet, this.timeEventToGenerate, true);
    } else {
      return undefined;
    }

  }

  get getSecondMediaForWeet(): Media | undefined {
    if (this.myWeet && this.timeEventToGenerate) {
      return getMediaForTimeEvent(this.myWeet, this.timeEventToGenerate, false);
    } else {
      return undefined;
    }
  }

  private generateThumbnailDebounce = debounce(() => {
    this.generateThumbnail();
  }, 250);

  @Watch('getMainMediaForWeet')
  public autoGenerationOnMainMediaUpdate() {
    if (!this.isThumbnailGenerated) {
      this.generateThumbnailDebounce();
    }
  }

  @Watch('getSecondMediaForWeet')
  public autoGenerationOnSecondMediaUpdate() {
    if (!this.isThumbnailGenerated) {
      this.generateThumbnailDebounce();
    }
  }

  @Watch('timeEventToGenerate')
  public autoGenerationDetection(now: TimeEvent, prev: TimeEvent) {
    if (!this.myWeet || this.myWeet.thumbnailManual) {
      return;
    }
    // if prev = undefined we currently in a init process.
    // 1 - No thumbnail, so i have to generated one
    // 2- Thubmnail I do nothing
    if (prev === undefined) {

      if (!this.isThumbnailGenerated) {
        this.generateThumbnailDebounce();
      }
    } else {
      // check if it's a new event
      const mainMedia = this.getMainMediaForWeet;
      // const urlMedia = mainMedia ? getVideoURLForStatus(mainMedia) : '';
      const secondMedia = this.getSecondMediaForWeet;

      if (!mainMedia) {
        return;
      }
      if (mainMedia?.type !== SourceVideoType.WEBCAM) {
        if (!secondMedia) {
          return;
        }
      }
      if (mainMedia && isMediaNeedRefresh(mainMedia)) {
        return;
      }
      // var urlSecondMedia=mainMedia?getVideoURLForStatus(mainMedia):'';
      if (!this.isThumbnailGenerated) {
        this.generateThumbnailDebounce();
      } else {
        if (now.time === prev.time
            && now.secondMedia?.mediaID === prev.secondMedia?.mediaID
            && now.mainMedia?.mediaID === prev.mainMedia?.mediaID) {
          // same event so do nothing
        } else {
          this.generateThumbnailDebounce();
        }
      }
    }
  }

  public async generateThumbnail() {

    if (this.myWeet && !this.thumbnailInProgress) {
      try {
        this.thumbnailInProgress = true;
        const blob = await this.tumbnailGenerator.generateThumbnailForWeet(this.myWeet, this.DEFAULT_TIME,
            {
              blur: false,
              avatarInSticker: false,
              removeSticker: false,
              showTitle: false,
              showTime: false,
              playIcon: true,
              background: null
            });
        this.thumbnailInProgress = false;
        store.dispatch(UPDATE_MY_WEET_IMAGE, {weetID: this.weetID, blob});
        // const url = URL.createObjectURL(blob);
        // open(url);
      } catch (e) {
        // do nothing, data is missed for generate a thumbnail
        this.thumbnailInProgress = false;
      }
    }
  }

  public async mounted() {
    this.tumbnailGenerator = new ThumbnailGenerator();
  }

  public beforeDestroy() {
    this.tumbnailGenerator.destroy();
  }
}
</script>

<style scoped lang="scss">
</style>
