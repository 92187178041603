import {Media} from '@/store/media/mediaModel';
import {User} from '@/store/user/userModel';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {AuthorisationEnum} from '@/enum/AuthorisationEnum';
import {CommentPolicy} from '@/store/weet/CommentPolicy';
import {VisibilityPolicy} from '@/store/weet/VisibilityPolicy';
import {Workspace} from '@/store/workspace/workspaceModel';
import {Channel} from '@/store/channel/channelModel';
import {EmotePolicy} from "@/store/weet/EmotePolicy";
import {Subtitle} from "@/store/subtitles/SubtitleModel";
import {Annotation} from "@/store/annotation/annotationModel";


export class Weet {
    public title: string = '';
    public weetID: string = '';
    public visibilityPolicy: VisibilityPolicy = VisibilityPolicy.SEMI_PRIVATE;
    public commentPolicy: CommentPolicy = CommentPolicy.COOWNER_CAN_COMMENT;
    public emotePolicy: EmotePolicy = EmotePolicy.USER_CAN_REACT;
    public medias: Media[] = [];
    public exportAivailable: boolean = false;
    public progressExport: number = 0;
    public owners: WeetOwer[] = [];
    public timeLine: TimeEvent[] = [];
    public created: Date = new Date();
    public views: number = 0;
    public viewers: number = 0;
    public hidden: boolean = false;
    public workspaces: Workspace[] = [];
    public channels: Channel[] = [];
    public thumbnail: string | undefined;
    public thumbnailManual: boolean = false;
    public subtitles: Subtitle[] = [];
    public annotations: Annotation[] = [];
    public archive:boolean=false;
}

export class WeetOwer {
    public user: User;
    public owner: boolean = false;
    public added: Date = new Date();
    public allow: AuthorisationEnum = AuthorisationEnum.EDIT;

    public constructor(user: User, owner: boolean, added: Date, allow: AuthorisationEnum) {
        this.user = user;
        this.owner = owner;
        this.added = added;
        this.allow = allow;
    }
}

export class WeetParams {
    public page: number = 0;
    public workspaceID: string = '';
    public maxByPage: number = 20;
    public nameFilter: string = '';

    constructor(page: number = 0, maxByPage: number = 20, nameFilter: string = '', workspaceID: string = '') {
        this.page = page;
        this.maxByPage = maxByPage;
        this.nameFilter = nameFilter;
        this.workspaceID = workspaceID;
    }
}

export interface WeetCountRepresentation {
    count: number;
}
