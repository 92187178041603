import axios from 'axios';

import {BACK_ENDPOINT} from '@/store/conf/api';
import {
  SETTINGS_LIST, WEET_CREATION_AVAILABLE,
  USER_ACCOUNT_CREATION_AVAILABLE,
  USER_AUTHENTICATION_AVAILABLE, INFORMATION_MESSAGE, WEET_MAX_LIMIT,
} from '@/store/settings/settingsAction';
import {Setting} from '@/store/settings/settingsModel';
import {SettingKey} from '@/store/settings/settingsKey';

const settingsAPI = BACK_ENDPOINT + '/settings';

export default {
  state: {
    user_account_creation_available: false,
    user_authentication_available: false,
    weet_creation_available: false,
    information_message: '',
    weet_max_limit: 480,
  },
  mutations: {
    [USER_ACCOUNT_CREATION_AVAILABLE]: (state: any, active: string) => {
      state.user_account_creation_available = active === 'true';
    },
    [USER_AUTHENTICATION_AVAILABLE]: (state: any, active: string) => {
      state.user_authentication_available = active === 'true';
    },
    [WEET_CREATION_AVAILABLE]: (state: any, active: string) => {
      state.weet_creation_available = active === 'true';
    },
    [WEET_MAX_LIMIT]: (state: any, time: number) => {
      state.weet_max_limit = time;
    },
    [INFORMATION_MESSAGE]: (state: any, time: number) => {
      state.information_message = time;
    },
  },
  getters: {
    isUserAccountCreationAvailable: (state: any): boolean => state.user_account_creation_available,
    isUserAuthAvailable: (state: any): boolean => state.user_authentication_available,
    isWeetCreationAvailable: (state: any): boolean => state.weet_creation_available,
    getInformationMessage: (state: any): string => state.information_message,
    getWeetMaxTime: (state: any): number => state.weet_max_limit,
  },
  actions: {
    async [SETTINGS_LIST]({commit, dispatch}: { commit: any, dispatch: any }, active: boolean) {
      const response = await axios({
        url: settingsAPI,
        method: 'GET',
      }).then((resp) => {
        const list = resp.data;
        for (const index in list) {
          if (index !== undefined) {
            const setting: Setting = list[index];
            if (setting !== undefined) {
              switch (setting.key) {
                case SettingKey.USER_AUTH_ENABLED:
                  commit(USER_AUTHENTICATION_AVAILABLE, setting.value);
                  break;
                case SettingKey.USER_CREATION_ENABLED:
                  commit(USER_ACCOUNT_CREATION_AVAILABLE, setting.value);
                  break;
                case SettingKey.WEET_CREATION_ENABLED:
                  commit(WEET_CREATION_AVAILABLE, setting.value);
                  break;
                case SettingKey.WEET_MAX_LIMIT:
                  commit(WEET_MAX_LIMIT, setting.value);
                  break;
                case SettingKey.INFORMATION_MESSAGE:
                  commit(INFORMATION_MESSAGE, setting.value);
                  break;
                default:
                // code block
              }
            }
          }
        }
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
  },
};
