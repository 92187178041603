import store from '@/store';
import {RETURN_TO_ASKED_URL, SHOW_POPUP_AUTH} from '@/store/auth/authAction';
import router from '@/router';
import {Watch} from 'vue-property-decorator';
import delay from 'delay';
import {log} from '@/utils/log';
import {WorkspaceCount} from '@/store/workspace/workspaceModel';
import {GET_MY_WORKSPACES_COUNT} from '@/store/workspace/workspaceAction';
import {selectWorkspace} from "@/utils/workspaceUtil";


export const toLoginPage = () => {
    store.dispatch(RETURN_TO_ASKED_URL, document.location.pathname);
    router.push({name: 'Authentication'});
};


export const quickAuthentication = async () => {
    return new Promise((resolve, reject) => {
        if (!store.getters.isAuthenticated) {
            store.dispatch(SHOW_POPUP_AUTH, true).then(async () => {
                await delay(1000);
                if (store.getters.isAuthenticated) {
                    resolve('Auth finish');
                } else {
                    if (store.getters.isShowPopUpAuth) {
                        await quickAuthentication();
                        resolve('auth finish');
                    } else {
                        // popup close without auth
                        reject('auth failed, or cancel');
                    }
                }
            });
        } else {
            resolve('Already Logged');
        }
    });
};

export const redirectIfNeed = () => {
    if (!store.getters.isShowPopUpAuth) {
        if (store.getters.urlAsked !== undefined) {
            router.push(store.getters.urlAsked);
        }
    }
    store.dispatch(RETURN_TO_ASKED_URL, '/');
};

export const redirectAfterAccountCreation = async () => {
    try {
        selectWorkspace(null);
        redirectIfNeed();
        // for the moment we don't want to redirect on the workspace creation
        // But soon :-)
        // const allUserWorkspaceCount: WorkspaceCount = await store.dispatch(GET_MY_WORKSPACES_COUNT, true);
        // if (allUserWorkspaceCount.count === 0 && !store.getters.isShowPopUpAuth) {
        //   // Redirect to create workspace
        //   router.push({name: 'CreateWorkspace', params: {fromSignUp: 'true'}});
        // } else {
        //   redirectIfNeed();
        // }
    } catch (e) {
        redirectIfNeed();
    }
};
