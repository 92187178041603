import {TimeEvent} from '@/store/timeLine/timeEvent';
import {TimeEventType} from '@/enum/TimeEventType';
import {Media} from '@/store/media/mediaModel';
import {clone} from '@/utils/util';
import {Weet} from '@/store/weet/weetModel';


/**
 * Give the final time if you move the section with moveASection
 * @param timeLines
 * @param startTime
 * @param moveBeforeTime
 */
export const getFinalPositionOfSection = (timeLines: TimeEvent[], startTime: number, moveBeforeTime: number): number => {
  const timeLineCopy = clone(timeLines);
  const orignalSection = getTimeEventOfSection(timeLineCopy, startTime);
  const durationOfSection = getDurationOfSection(timeLineCopy, startTime);
  insertASection(timeLineCopy, orignalSection, moveBeforeTime, durationOfSection);
  return orignalSection[0].time - durationOfSection;
};
/**
 *
 * @param timeLines
 * @param startTime
 * @param moveBeforeTime
 */
export const moveASection = (timeLines: TimeEvent[], startTime: number, moveBeforeTime: number) => {
  const orignalSection = getTimeEventOfSection(timeLines, startTime);
  const sectionsToInsert = clone(orignalSection);
  const durationOfSection = getDurationOfSection(timeLines, startTime);
  let newTimeLine = insertASection(timeLines, sectionsToInsert, moveBeforeTime, durationOfSection);

  // the time of the original section was updated by the insertSection
  newTimeLine = deleteASection(newTimeLine, orignalSection[0].time);
  return newTimeLine;
};

export const insertASection = (timeLines: TimeEvent[], timeEventsToInsert: TimeEvent[], insertBeforeTime: number, duration: number): TimeEvent[] => {
  const newTimeLine: TimeEvent[] = [];
  let sectionInserted: boolean = false;


  // control if the time to insert is a section
  const durationOfSection = getDurationOfSection(timeLines, insertBeforeTime);
  if (durationOfSection === 0 && getDurationOfTimeLine(timeLines) !== insertBeforeTime) {
    throw new Error('Impossible to insert Here');
  }
  if (timeEventsToInsert.length === 0) {
    throw new Error('Impossible to insert  (Nothing to insert)');
  }

  for (const timeEvent of timeLines) {
    // if we are before the inserted section we just add event
    if (timeEvent.time < insertBeforeTime) {
      newTimeLine.push(timeEvent);
    } else {
      // so we are in the timeline manipulation
      if (!sectionInserted) {
        // so we insert new timeevent
        let offsetTime = -1;
        for (const timeEventToInsert of timeEventsToInsert) {
          if (offsetTime === -1) { // first element to insert
            offsetTime = timeEventToInsert.time - insertBeforeTime;
            timeEventToInsert.time = insertBeforeTime;
          } else {
            timeEventToInsert.time = timeEventToInsert.time - offsetTime;
          }
          // insert event in new timeline
          newTimeLine.push(timeEventToInsert);
        }
        timeEvent.time = timeEvent.time + duration;
        newTimeLine.push(timeEvent);
        sectionInserted = true;
      } else {
        // now we just offset the other event
        timeEvent.time = timeEvent.time + duration;
        newTimeLine.push(timeEvent);

      }
    }
  }
  return newTimeLine;
};

/**
 * Delete the last section of the timeLine
 * @param timeLines
 */
export const timeOfLastSection = (timeLines: TimeEvent[]): number => {
  const timeLinesReverse: TimeEvent[] = timeLines;
  let time: number = 0;
  for (const timeEvent of timeLinesReverse) {
    if (timeEvent.type === TimeEventType.MEDIA_PLAY) {
      time = timeEvent.time;
    }
  }
  return time;
};

/**
 * Delete
 * @param timeLines
 * @param startTime
 */
export const deleteASection = (timeLines: TimeEvent[], startTime: number
): TimeEvent[] => {

  let offsetTime = 0;
  const newTimeLine: TimeEvent[] = [];
  let ignoreEvent: boolean = false;

  for (const timeEvent of timeLines) {
    // if we are in ignoreEvent and found a new Section,
    // so we finish to delete the sectino
    if (isSectionEventType(timeEvent) && ignoreEvent) {
      ignoreEvent = false;
      // setup the offset Time
      offsetTime = timeEvent.time - startTime;
    }
    // if we found the right event, we stop add the other event
    // ignoreEvent=true
    if (isSectionEventType(timeEvent) &&
      timeEvent.time === startTime) {
      ignoreEvent = true;
    }

    // Add event to section if we are not ingnoring event
    if (!ignoreEvent) {
      let newEvent = timeEvent;
      newEvent.time = newEvent.time - offsetTime;
      if (newEvent.type === TimeEventType.MEDIA_PAUSE) {
        newEvent = eraseMediaForEvent(newEvent);
      }
      newTimeLine.push(newEvent);
    }
  }
  if (numberOfSection(newTimeLine) === 0) {
    return [];
  } else {
    return newTimeLine;
  }
};

export const getObsoleteMedia = (timeLine: TimeEvent[], medias: Media[]): Media[] => {
  const obsoleteMedia: Media[] = [];

  for (const media of medias) {
    let mediaFound = false;
    for (const timeEvent of timeLine) {
      if (media.mediaID === timeEvent.mainMedia?.mediaID) {
        mediaFound = true;
        break;
      }
      if (media.mediaID === timeEvent.secondMedia?.mediaID) {
        mediaFound = true;
        break;
      }
    }
    if (!mediaFound) {
      obsoleteMedia.push(media);
    }
  }

  return obsoleteMedia;
};


/**
 *
 * @param timeLines
 */
export const numberOfSection = (timeLines: TimeEvent[]): number => {
  let counter = 0;
  for (const timeEvent of timeLines) {
    if (timeEvent.type === TimeEventType.MEDIA_PLAY) {
      counter++;
    }
  }

  return counter;
};

export const getDurationOfSection = (timeLines: TimeEvent[], startTime: number): number => {
  let duration = 0;
  let sectionFound = false;
  for (const timeEvent of timeLines) {
    if (isSectionEventType(timeEvent) && sectionFound) {
      duration = timeEvent.time - startTime;
      break;
    }
    if (timeEvent.type === TimeEventType.MEDIA_PLAY
      && startTime === timeEvent.time) {
      sectionFound = true;
    }
  }
  return duration;
};


export const getTimeEventOfSection = (timeLines: TimeEvent[], startTime: number): TimeEvent[] => {
  const newTimeLine: TimeEvent[] = [];
  let sectionFound = false;
  for (const timeEvent of timeLines) {
    if (isSectionEventType(timeEvent) && sectionFound) {
      break;
    }
    if (timeEvent.type === TimeEventType.MEDIA_PLAY
      && startTime === timeEvent.time) {
      sectionFound = true;
    }
    if (sectionFound) {
      newTimeLine.push(timeEvent);
    }
  }
  return newTimeLine;
};

/**
 * Compute the total duration of timeline
 * @param timeLines
 */
export const getDurationOfTimeLine = (timeLines: TimeEvent[]): number => {
  let duration = 0;

  const mediaPause = getTimeEventAfterTime(timeLines, 0, TimeEventType.MEDIA_PAUSE);
  if (mediaPause) {
    duration = mediaPause.time;
  } else {
    // found
    if (timeLines.length > 0) {
      duration = timeLines[timeLines.length - 1].time;
    }
  }
  return duration;
};

const isSectionEventType = (timeEvent: TimeEvent): boolean => {
  return timeEvent.type === TimeEventType.MEDIA_PLAY || timeEvent.type === TimeEventType.MEDIA_PAUSE;
};

const eraseMediaForEvent = (timeEvent: TimeEvent) => {
  if (timeEvent.mainMedia?.mediaID) {
    timeEvent.mainMedia.mediaID = '';
  }
  if (timeEvent.secondMedia?.mediaID) {
    timeEvent.secondMedia.mediaID = '';
  }
  return timeEvent;
};

export const getOrdonedTimeLineFromState = (recordTimeEvents: object) => {
  let listOfTimeEvent: TimeEvent[] = [];
  for (const timeEArray of Object.values(recordTimeEvents)) {
    const tabOfTE = timeEArray as TimeEvent[];
    for (const te of tabOfTE) {
      const timeEvent = te as TimeEvent;
      listOfTimeEvent.push(timeEvent);
    }
  }

  // sort the timeline in time
  listOfTimeEvent = listOfTimeEvent.sort((a: TimeEvent, b: TimeEvent) => {
    if (a.time < b.time) {
      return -1;
    } else {
      return 1;
    }
  });
  return listOfTimeEvent;
};


export const getFirstMediaPlayEvent = (timeline: TimeEvent[]): TimeEvent | undefined => {
  for (const te of timeline) {
    if (te.type === TimeEventType.MEDIA_PLAY) {
      return te;
    }
  }
  return undefined;
};


/**
 * @param timeline
 * @param time
 * @param filter
 */
export const getTimeEventbeforeTime = (timeline: TimeEvent[], time: number, filter: TimeEventType | undefined): TimeEvent | undefined => {


  // const listKey = Object.keys(weet.timeLine);
  let timeToRetrun;
  // // we try to find the key just before the time
  for (const timeEvent of timeline) {
    if (timeEvent.time <= time) {
      if (!filter) {
        timeToRetrun = timeEvent;
      } else {
        if (timeEvent.type === filter) {
          timeToRetrun = timeEvent;
        }
      }
    } else {
      break;
    }
  }
  return timeToRetrun;
};

/**
 * @param weet
 * @param time
 * @param filter
 */
export const getTimeEventAfterTime = (timeline: TimeEvent[], time: number, filter: TimeEventType | undefined): TimeEvent | undefined => {


  // const listKey = Object.keys(weet.timeLine);
  let timeToRetrun;
  // // we try to find the key just before the time
  for (const timeEvent of timeline) {
    if (time < timeEvent.time) {
      if (!filter) {
        timeToRetrun = timeEvent;
        break;
      } else {
        if (timeEvent.type === filter) {
          timeToRetrun = timeEvent;
          break;
        }
      }
    }
  }
  return timeToRetrun;
};
/**
 * @param weet
 * @param timeBefore
 * @param timeEnd
 * @param filter
 */
export const getTimeEventBetweetTime = (timeline: TimeEvent[], timeBefore: number, timeEnd: number, filter: TimeEventType | undefined) => {
  const eventBeforeEnd = getTimeEventbeforeTime(timeline, timeEnd, filter);
  if (eventBeforeEnd && eventBeforeEnd.time >= timeBefore) {
    return eventBeforeEnd;
  } else {
    return undefined;
  }
};

export const getTimeEventExactTime = (timeline: TimeEvent[], time: number): TimeEvent | undefined => {
  for (const timeEvent of timeline) {
    if (time === timeEvent.time) {
      return timeEvent;
    }
  }
  return undefined;
};


/**
 * Send the event you need to jump
 * @param weet
 * @param time
 */
export const getJumpEventForTime = (timeline: TimeEvent[], time: number): TimeEvent | undefined => {
  // is there is no sound event before the time
  const noSoundEvent = getTimeEventbeforeTime(timeline, time, TimeEventType.START_CUT);
  // console.log('found no Sound Event', noSoundEvent);
  // no nosound event so no jump
  if (!noSoundEvent) {
    return undefined;
  } else {
    // now we need to check if there is no sound detect
    // between the no soundEvent and the time
    const soundDetect = getTimeEventbeforeTime(timeline, time, TimeEventType.END_CUT);

    // console.log('Found soundDetect', soundDetect);
    // so the no sound event is for a previous section, we have to ignore it
    if (soundDetect && soundDetect.time > noSoundEvent.time) {
      return undefined;
    } else {
      if (!soundDetect || soundDetect.time < noSoundEvent.time) {
        let eventToJump = getTimeEventAfterTime(timeline, time, TimeEventType.END_CUT);
        if (!eventToJump) {
          // if no sound Detect we jump to the end of the video
          eventToJump = getTimeEventAfterTime(timeline, time, TimeEventType.MEDIA_PAUSE);
        }
        return eventToJump;
      }
    }
  }
};
/**
 * @param weet
 */
export const isQuickPlayOnTimeLine = (timeline: TimeEvent[]): boolean => {
  return isCutEventOnTimeLine(timeline);
};

export const isCutEventOnTimeLine = (timeline: TimeEvent[]): boolean => {
  return getTimeEventAfterTime(timeline, -1, TimeEventType.START_CUT) !== undefined;
};

export const isChapterEventOnTimeLine = (timeline: TimeEvent[]): boolean => {
  return getTimeEventAfterTime(timeline, -1, TimeEventType.CHAPTER_TITLE) !== undefined;
};
export const isSoundEventOnTimeLine = (timeline: TimeEvent[]): boolean => {
  return getTimeEventAfterTime(timeline, -1, TimeEventType.NO_SOUND_DETECT) !== undefined;
};

/**
 * @param weet
 */
export const getTimeSaveInQuickPlayMode = (timeline): number => {
  let timeSaved = 0;
  let tmpTime = -1;
  for (const te of timeline as TimeEvent[]) {
    if (te.type === TimeEventType.START_CUT && tmpTime === -1) {
      tmpTime = te.time;
    } else if (
      (te.type === TimeEventType.END_CUT ||
        te.type === TimeEventType.MEDIA_PAUSE) && tmpTime > -1
    ) {
      timeSaved += (te.time - tmpTime);
      tmpTime = -1;
    }
  }
  return timeSaved;
};

/**
 * Get time of freePlace
 * @param weet
 * @param timeEvent
 */
export const getFreeTimeAfterEvent = (timeline: TimeEvent[], timeEvent: TimeEvent | number): number => {
  let time = 0;
  if (timeEvent instanceof TimeEvent) {
    time = timeEvent.time;
  } else {
    time = timeEvent as number;
  }
  while (isEventOnTimeinTimeline(timeline, time)) {
    time++;
  }
  return time;
};

export const getFreeTimeBeforeEvent = (timeline: TimeEvent[], timeEvent: TimeEvent | number): number => {
  let time = 0;
  if (timeEvent instanceof TimeEvent) {
    time = timeEvent.time;
  } else {
    time = timeEvent as number;
  }
  while (isEventOnTimeinTimeline(timeline, time) && time > 0) {
    time--;
  }
  return time;
};

/**
 * Is Time is event
 */
export const isEventOnTimeinTimeline = (timeline: TimeEvent[], time: number): boolean => {
  for (const te of timeline as TimeEvent[]) {
    if (te.time === time) {
      return true;
    }
  }

  return false;
};

/**
 * Get the title event next to the timeEvent (100ms max)
 * @param timeline
 * @param timeEvent
 */
export const getTitleTimeEventEvent = (timeline: TimeEvent[], timeEvent: TimeEvent) => {
  return getTimeEventBetweetTime(timeline, timeEvent.time, timeEvent.time + 100, TimeEventType.CHAPTER_TITLE);
};


export const printTimeLine = (timeline: TimeEvent[]) => {
  let timeLineString = '';
  for (const te of timeline) {
    timeLineString += te.time + ': ' + te.type + ' | ';
  }

  return timeLineString;
};
/**
 * Return the media for a timeEvent,
 */
export const getMediaForTimeEvent = (weet: Weet, timeEvent: TimeEvent, main: boolean = true): Media | undefined => {

  for (const media of weet.medias) {
    if (main && timeEvent.mainMedia && media.mediaID === timeEvent.mainMedia.mediaID) {
      return media;
    }
    if (!main && timeEvent.secondMedia && media.mediaID === timeEvent.secondMedia.mediaID) {
      return media;
    }
  }
  return undefined;
};

export const getCutEventByIndex = (timeline: TimeEvent[], index: number): TimeEvent | undefined => {
  let indexFound = -1;
  for (const te of timeline) {
    if (te.type === TimeEventType.END_CUT
      || te.type === TimeEventType.START_CUT) {
      indexFound++;
    }
    if (indexFound === index) {
      return te;
    }
  }
};

export const getChapterEventByIndex = (timeline: TimeEvent[], index: number): TimeEvent | undefined => {
  let indexFound = -1;
  for (const te of timeline) {
    if (te.type === TimeEventType.CHAPTER_TITLE) {
      indexFound++;
    }
    if (indexFound === index) {
      return te;
    }
  }
};
