<template>
  <div>
    {{ humanCountDown }}
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import prettyMilliseconds from 'pretty-ms';
import {Howl} from 'howler';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {sendBadgeMessage} from '@/utils/extentionUtil';
import {showNotification} from '@/utils/notification';


@Component<SectionTimer>({
  components: {},
  metaInfo() {
    return {
      title: this.informationRecord
    };
  }
})
export default class SectionTimer extends Vue {
  private shake = new Howl({
    src: ['/sound/shake.mp3'],
    volume: 0.10,
  });

  private isNotifiyPlay: boolean = false;


  private animationTick: number = 0;

  get currentSectionRecord(): number {
    return store.getters.getTimerRecord;
  }

  get countDown(): number {
    return this.timeMax - this.currentSectionRecord;
  }

  get humanCurrentTime(): string {
    if (this.currentSectionRecord < 1000) {
      return '0:00';
    } else {
      return prettyMilliseconds(this.currentSectionRecord, {secondsDecimalDigits: 0, colonNotation: true});
    }
  }

  get humanCountDown(): string {
    if (this.countDown < 1000) {
      return '0:00';
    } else {
      return prettyMilliseconds(this.countDown, {secondsDecimalDigits: 0, colonNotation: true});
    }
  }

  get timeMax(): number {
    // convert second in millisecond
    return store.getters.getWeetMaxTime * 1000;
  }


  get isRecording(): boolean {
    return store.getters.isPauseAivailable;
  }

  @Watch('currentSectionRecord')
  public onCurrentTimeChange() {
    if (this.countDown <= 0 && this.isRecording) {
      this.$emit('end');
      setActionEvent(ActionEvent.record_section_limit_timeOut, {
        record_time: store.getters.getTimerRecord
      });
      this.$buefy.dialog.alert({
        title: this.$t('sectionTime.stop.title').toString(),
        message: this.$t('sectionTime.stop.description').toString(),
        type: 'is-color3',
        hasIcon: true,
        icon: 'clock-outline',
        ariaRole: 'alertdialog',
        ariaModal: true
      });
    }
  }

  get informationRecord(): string {
    // Welcoming message
    // animate message
    const maxTime = prettyMilliseconds(this.timeMax, {colonNotation: true, secondsDecimalDigits: 0});
    if (this.countDown > 30000 && this.isRecording) {
      return this.humanCurrentTime + ' / ' + maxTime; // this.$t('sectionTime.information.first').toString();
    }
    if (!this.isRecording) {
      return this.$t('sectionTime.information.waiting').toString();
    }

    const timeLeft = prettyMilliseconds(this.countDown, {
      colonNotation: true,
      secondsDecimalDigits: 0
    });
    const secondLeft = Math.round(this.countDown / 1000);
    // animate message
    if (this.countDown < 30000
        && this.countDown > 25000) {
      if (!this.isNotifiyPlay && store.getters.isTimeSectionNotificationActivated) {
        showNotification(this.$t('sectionTime.information.sectionHelper.title').toString(), this.$t('sectionTime.information.30s').toString());
      }
      if (store.getters.isTimeSectionSoundActivated) {
        this.playShake(6000);
      }
      sendBadgeMessage('⏱️' + secondLeft, '#00CD94');
      return this.$t('sectionTime.information.30s').toString();
    } else if (this.countDown < 10000
        && this.countDown > 5000) {
      sendBadgeMessage('⏱️' + secondLeft, '#F8550F');
      if (!this.isNotifiyPlay && store.getters.isTimeSectionNotificationActivated) {
        showNotification(this.$t('sectionTime.information.sectionHelper.title').toString(), this.$t('sectionTime.information.10s').toString());
      }
      if (store.getters.isTimeSectionSoundActivated) {
        this.playShake(6000);
      }
      return this.$t('sectionTime.information.10s', {time: timeLeft}).toString();
    } else {
      return this.humanCurrentTime + ' / ' + maxTime;
    }
  }

  public computeTitleAnimation(): string {
    const ANIMATION_TIME = 5;
    let title = '';
    for (let i = 0; i < this.animationTick; i++) {
      title += '-';
    }
    title += '🐝';
    for (let i = 0; i < ANIMATION_TIME - this.animationTick; i++) {
      title += '-';
    }
    this.animationTick++;
    if (this.animationTick === ANIMATION_TIME) {
      this.animationTick = 0;
    }

    return title;
  }

  public playShake(time: number) {
    if (this.isNotifiyPlay) {
      return;
    } else {
      this.isNotifiyPlay = true;
      this.shake.play();
      setTimeout(() => {
        this.isNotifiyPlay = false;
      }, time);
    }
  }
}
</script>

<style scoped lang="scss">
</style>
