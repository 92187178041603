<template>
  <div>

    <transition :name="'custom'"
                :enter-active-class="transitionNameEnter"
                :leave-active-class="transitionNameLeave"
    >
      <!-- ANNOTATION TEXT -->
      <div class="annotation annotationText" v-if="isText" :id="'annotation-'+annotation.annotationID"
           v-show="show" :class="{left:isLeft,right:isRight,top:isTop,bottom:isBottom}" @click="selectAnnotation">
        <div class="icon" :class="{'editable':isEditable}" v-if="annotation.iconMD" @click="showSelectionIcon=true">
          <w-icon :icon="iconMD" size="default"/>
          <w-icon class="iconEdition" icon="pencil-outline" size="small" v-if="isEditable"/>
        </div>
        <div class="textContent" :contenteditable="isEditable"
             :placeholder="$t('createWeetComponent.advancedEditing.annotationMode.edit.placeholder')"
             v-html="annotationContent"
             @focusout="validateAnnotationContent" @keydown.enter="focusOutAnnotationContent"/>

        <w-modal v-if="isEditable" :active="showSelectionIcon" @close="showSelectionIcon=false">
          <CenterCenterPanel>
            <IconsSelector class="containerIcon" @select="changeIcon"/>
          </CenterCenterPanel>
        </w-modal>
      </div>

      <!-- ANNOTATION IMAGE -->
      <div class="annotation annotationImage" v-if="isImage" :id="'annotation-'+annotation.annotationID"
           v-show="show" :class="{left:isLeft,right:isRight,top:isTop,bottom:isBottom}" @click="selectAnnotation">
        <div class="containerUploadElement">
          <img class="imageContent" :src="urlSourceImage" />
<!--          <img class="imageContent" src="https://cloudfront-us-east-2.images.arcpublishing.com/reuters/J63FBX3CCJJQFKNN7UGBKUOCGA.jpg" />-->
<!--        <img class="imageContent" src="https://i.pinimg.com/564x/5b/b6/6d/5bb66dd78e4cd8009a6a947fe64e21c8.jpg" />-->
          <b-upload v-if="isEditable" class="uploadElement" :class="{'empty':!urlSourceImage}" drag-drop accept=".png,.jpeg,.jpg,.gif" @input="uploadNewImage">
            <div class="uploadPanel">
              <w-icon color="dark"
                      icon="upload"
                      size="large">
              </w-icon>
              <p class="textUpload">{{$t('createWeetComponent.advancedEditing.annotationMode.edit.placeholderUpload')}}</p>
            </div>
          </b-upload>
        </div>

      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Annotation, AnnotationPosition, AnnotationType} from "@/store/annotation/annotationModel";
import {Howl} from 'howler';
import UserAvatar from "@/components/user/UserAvatar.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import store from "@/store";
import {clone, getImageURLForSize, getUserAvatar, htmliFy} from "@/utils/util";
import {inform, informError} from "@/utils/dialog";
import {SELECT_ANNOTATION, UPDATE_ANNOTATION} from "@/store/annotation/annotationAction";
import {Weet} from "@/store/weet/weetModel";
import {exportNode2PngDataURL} from "@/utils/ImageUtil";
import CenterCenterPanel from "@/components/layout/CenterCenter.vue";
import IconsSelector from "@/components/icons/IconsSelector.vue";
import WModal from "@/components/wrapper/w-modal.vue";
import delay from "delay";
import {ANNOTATION_INTERACTION_SAVE_IN_PROGRESS} from "@/store/recordingState/recordStateAction";

@Component({
  components: {WModal, IconsSelector, CenterCenterPanel, WIcon, UserAvatar},
})
export default class AnnotationElementPlayerComponent extends Vue {

  private pop = new Howl({
    src: ['/sound/pop.mp3'],
    volume: 0.80,
  });

  @Prop()
  private annotation!: Annotation;
  @Prop()
  private weet!: Weet;
  @Prop({default: 0})
  private timePlayer!: number;
  private ready: boolean = false;
  private showSelectionIcon: boolean = false;

  private iconMD: string = '';
  private urlSourceImage:string='';

  get annotationContent() {
    return htmliFy(this.annotation.textContent);
  }

  get isText() {
    return this.annotation.type === AnnotationType.TEXT;
  }

  get isImage() {
    return this.annotation.type === AnnotationType.IMAGE;
  }

  get isLeft() {
    return this.annotation.positionType === AnnotationPosition.TOP_LEFT;
  }

  get isRight() {
    return this.annotation.positionType === AnnotationPosition.TOP_RIGHT
        || this.annotation.positionType === AnnotationPosition.BOTTOM_RIGHT;
  }

  get isTop() {
    return this.annotation.positionType === AnnotationPosition.TOP_RIGHT
        || this.annotation.positionType === AnnotationPosition.TOP_LEFT;
  }

  get userImage(): string {
    return getUserAvatar(this.annotation.user, 500);
  }

  get isBottom() {
    return this.annotation.positionType === AnnotationPosition.BOTTOM_RIGHT;
  }

  get show() {
    return this.ready && this.timePlayer >= this.annotation.time &&
        this.timePlayer <= this.annotation.time + this.annotation.duration;
  }

  get transitionNameEnter() {
    switch (this.annotation.positionType) {
      case AnnotationPosition.TOP_LEFT:
        return "fadeInLeft";
      case AnnotationPosition.TOP_RIGHT:
        return "fadeInRight";
      case AnnotationPosition.BOTTOM_RIGHT:
        return "fadeInRight";
    }
    return "zoomIn"
  }

  get transitionNameLeave() {
    switch (this.annotation.positionType) {
      case AnnotationPosition.TOP_LEFT:
        return "fadeOutLeft";
      case AnnotationPosition.TOP_RIGHT:
        return "fadeOutRight";
      case AnnotationPosition.BOTTOM_RIGHT:
        return "fadeOutRight";
    }
    return "zoomOut"
  }

  get isEditable() {
    if (store.getters.getAnnotationSelected) {
      return store.getters.getAnnotationSelected.annotationID === this.annotation.annotationID
          && store.getters.isAnnotationMode;
    }
    return false;
  }

  private mounted() {
    this.ready = true;
    if (this.annotation.sound) {
      this.pop.play();
    }
    this.iconMD = this.annotation.iconMD;
    if(this.annotation.urlContent) {
      this.urlSourceImage = getImageURLForSize(this.annotation.urlContent, 1500);
    }
  }

  private selectAnnotation() {
    if (store.getters.isAnnotationMode) {
      store.dispatch(SELECT_ANNOTATION, this.annotation);
    }
  }

  private async validateAnnotationContent(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      if (htmliFy(element.innerText.trim()) !== htmliFy(this.annotationContent.trim())) {
        // update the text content of the annotation
        var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
        annotationUpdated.textContent = htmliFy(element.innerText.trim());

        this.updateAnnotation(annotationUpdated, true);
      }
    }
  }

  private async uploadNewImage(file:File){
    return new Promise((resolve,reject)=>{
      const reader = new FileReader();
      reader.addEventListener("load",()=>{
       var dataUrl=reader.result;
        var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
        annotationUpdated.urlContent=dataUrl+"";
        this.urlSourceImage=dataUrl+"";
        this.updateAnnotation(annotationUpdated,true);
      })
      reader.readAsDataURL(file);
    })
  }
  private async updateAnnotation(annot: Annotation, withPicture = false) {
    // export img before update
    var dataUrlImage = '';
    store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,true);
    if (withPicture) {
      const annotationNode = document.getElementById('annotation-' + annot.annotationID);
      if (!annotationNode) {
        return;
      }
      // wait for annotation update
      await delay(50);
      dataUrlImage = await exportNode2PngDataURL(annotationNode);
      annot.exportStaticUrl = dataUrlImage;
    }

    await store.dispatch(UPDATE_ANNOTATION, {
      weetID: this.weet.weetID, annotation: annot
    }).then((annotUpdated) => {
      store.dispatch(SELECT_ANNOTATION, annotUpdated)
      inform(this.$t('createWeetComponent.advancedEditing.annotationMode.edit.success').toString());
      store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,false);
    }).catch(()=>{
      informError(this.$t('createWeetComponent.advancedEditing.annotationMode.edit.error').toString());
      store.dispatch(ANNOTATION_INTERACTION_SAVE_IN_PROGRESS,false);
    })
  }

  private async changeIcon(value: string) {
    var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
    annotationUpdated.iconMD = value;
    this.iconMD = value;
    this.showSelectionIcon = false;
    await delay(500);
    this.updateAnnotation(annotationUpdated, true);

  }

  private focusOutAnnotationContent(event: InputEvent) {
    if (event.target != null) {
      const element = event.target as HTMLElement;
      element.blur();
    }
  }

}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.annotation {
  box-shadow: -50px 0px 200px rgba(12, 0, 120, 0.6);
  background: rgba(255, 255, 255, 0.98);
  position: absolute;
  border: solid 1px var(--light1);
  border-left: solid 4px var(--primary);
  border-radius: 12px;
  animation-duration: 0.2s;


  &.left {
    left: 16px;
  }

  &.top {
    top: 16px;
  }

  &.right {
    right: 16px;
  }

  &.bottom {
    bottom: 96px;
  }
}

.annotationText {
  width: 350px;
  padding: 16px;
  padding-left: 12px; // remove 4 px (border)
  display: flex;
  align-items: flex-start;

  .profil {
    margin-right: 16px;
  }

  .textContent {
    font-size: 16px;
    color: var(--dark);
    text-align: left;
    flex: 1;
    word-break: break-word;

    &[placeholder]:empty::before {
      content: attr(placeholder);
      color: var(--light2);
    }

    &[placeholder]:empty:focus::before {
      content: "";
    }
  }

  .icon {
    color: var(--light2);
    margin-right: 8px;

    .iconEdition {
      display: none;
      pointer-events: none;
      position: absolute;
      margin-top: -8px;
      color: var(--dark);
    }

    &:hover {
      color: inherit;

      + .iconEdition {
        display: block;
      }
    }

    &.editable {
      cursor: pointer;

      &:hover {
        color: var(--light1);
      }
    }
  }

}

.annotationImage {
  border: 0px;
  font-size: 0px;
  min-width: 50px;
  width: 48%;
  max-width: 48%;
  height: 100%;
  max-height: calc(100% - 88px);
  text-align: right;
  background: transparent;
  box-shadow: none;
  .imageContent{
    border-left: solid 4px var(--primary);
    border-radius: 12px;
    margin: 0px;
    //height: 100%;
    max-height: 100%;
    box-shadow: -50px 0px 200px rgba(12, 0, 120, 0.6);
  }
  .containerUploadElement{
    position: relative;
  }
  .uploadElement{
    display: flex;
    position: absolute;
    opacity: 0;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    border-radius: 12px;
    padding: 12px;
    color: var(--light2);
    border: 1px solid var(--light2);
    cursor: pointer;
    background: rgba(255, 255, 255, 0.9);
    .uploadPanel {
      display: flex;
      text-align: center;
      border: 0px;
      height: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .textUpload {
        font-size: 16px;
        color: var(--light2);
      }
    }

    &.empty{
      opacity: 1;
      min-height: 200px;
    }
  }

  &:hover {
    .uploadElement {
      opacity: 1;
    }
  }
}


.containerIcon {
  background: white;
  width: 100%;
  display: flex;
  border-radius: 12px;
  padding: 24px;
  flex-wrap: wrap;
  justify-content: space-between;
}



</style>

<style lang="scss">
.uploadElement{
  .upload-draggable{
    border: 0px;
    height: 100%;
    border-radius: 12px;
  }
}
</style>
