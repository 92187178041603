<template>
  <div>
    <slot></slot>
    <div class="iconSelector" v-for="ico in listOfIcons" :key="'ico-'+ico" @click="onSelect(ico)" :class="size">

      <w-icon :size="size"  :icon="ico"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import WButton from "@/components/wrapper/w-button.vue";
import store from "@/store";
import prettyMilliseconds from "pretty-ms";
import {Annotation} from "@/store/annotation/annotationModel";
import WIcon from "@/components/wrapper/w-icon.vue";
import WToolTip from "@/components/wrapper/w-toolTip.vue";
import {DELETE_ANNOTATION, SELECT_ANNOTATION, UNSELECT_ANNOTATION} from "@/store/annotation/annotationAction";
import {alertMessageWithConfirmation, confirmation, confirmationDanger} from "@/utils/dialog";
import {SEEK_PLAYER, TIMER_PLAYER} from "@/store/timeLine/timeLineAction";
import {time} from "@tensorflow/tfjs";


@Component({
  components: {WIcon},
})
export default class IconsSelector extends Vue {

  @Prop({default:'large'})
  private size!:string;
  private listOfIcons: string[] = ['information-outline', 'bell-outline', 'alert-octagram-outline',
    'alert-outline', 'link-variant', 'account-circle-outline', 'arrow-down-bold-circle-outline',
    'pencil-outline', 'cellphone', 'cloud-outline','currency-usd','database-outline','clock-outline',
      'calendar-blank-outline','alarm-light-outline','alarm','calendar-blank-outline'
  ];

  private onSelect(value: string) {
    this.$emit('select', value);
  }

}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.iconSelector {
  &.large{
    min-width: 64px;
    min-height: 64px;
    border-radius: 12px;
  }
  &.small{
    min-width: 24px;
    min-height: 24px;
    border-radius: 8px;
  }
  color: var(--dark);

  background: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  cursor: pointer;

  &:hover {
    background: var(--light1);
  }


}
</style>
