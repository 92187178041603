<template>
  <div class="header">
    <!-- MENU BURGER -->
    <div class="iconMenu" @click="toggleMenu">
      <w-icon :icon="!isMenuOpen?'menu':'backburger'" v-if="isAutenthicated && isMenuRoute"/>
    </div>
    <!-- LOGO -->
    <div class="headerContainer">
      <div class="logo" data-test="headerLogo" @click="gotToMyWeet" v-if="!logoHeader">
        <div class="logoImage"/>
        <div class="logoLight"/>
      </div>
      <div v-else class="logo" @click="gotToMyWeet">
        <img :src="logoHeader" class="logoCustom" alt="logo du workspace"/>
      </div>
      <w-button color="text" icon="pencil-outline" size="small" class="buttonModify" v-if="isAdmin"
                @click="moveToEditWorkspace"/>
    </div>
    <!-- SEARCH -->
    <div class="menuSearchPanel" data-test="menuSearchPanel">
      <SearchWeetAutoComplete class="inputSearch" v-if="isAutenthicated"/>
    </div>
    <!-- FREEMIUM -->
    <div class="buttonFreemium" v-if="isFreemium">
      <upgrade-plan-button size="default" color="secondary" ></upgrade-plan-button>
    </div>
    <!-- CREATE SPEACH -->
    <div class="buttonCreateSpeach" v-if="isAutenthicated">
      <w-button v-if="!isCurrentlyRecord" :color="colorButtonCreateWeet" :enabled="isCanCreateAWeet" @click="createWeet"
                icon="plus"
                data-test="headerCreateWeetButton">
        <span v-if="!isMobile">{{ $t('header.button.create') }}</span>
      </w-button>
      <RecorderPauseButton data-test="headerPauseWeetButton" v-else/>
    </div>

    <!-- TOOLS -->
    <div class="buttonTools" v-if="!isMobile">
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger>
          <w-button color="light" icon="dots-grid">
          </w-button>
        </template>
        <b-dropdown-item class="toolLine" aria-role="listitem" @click="goToRoute('NewNoiseCancelling')">
          <w-icon class="icon" icon="waveform"/>
          {{ $t('header.tools.noise') }}
        </b-dropdown-item>
        <b-dropdown-item class="toolLine" aria-role="listitem" @click="goToRoute('NewTranscriptYourVideo')">
          <w-icon class="icon" icon="subtitles-outline"/>
          {{ $t('header.tools.transcribe') }}
        </b-dropdown-item>
        <b-dropdown-item class="toolLine" aria-role="listitem" @click="goToRoute('NewTranslateYourVideo')">
          <w-icon class="icon" icon="translate"/>
          {{ $t('header.tools.translate') }}
        </b-dropdown-item>
        <b-dropdown-item class="toolLine" aria-role="listitem" @click="goToRoute('NewHostYourVideo')">
          <w-icon class="icon"  icon="cloud-outline"/>
          {{ $t('header.tools.hosting') }}
        </b-dropdown-item>
        <b-dropdown-item class="toolLine" aria-role="listitem" @click="goToRoute('NewShareYourVideo')">
          <w-icon class="icon"  icon="share-outline"/>
          {{ $t('header.tools.share') }}
        </b-dropdown-item>
        <b-dropdown-item class="toolLine" aria-role="listitem" @click="goToRoute('NewCompressYourVideo')">
          <w-icon class="icon"  icon="zip-box-outline"/>
          {{ $t('header.tools.compress') }}
        </b-dropdown-item>
        <b-dropdown-item class="toolLine" aria-role="listitem" @click="goToRoute('NewCutYourVideo')">
          <w-icon class="icon"  icon="content-cut"/>
          {{ $t('header.tools.trim') }}
        </b-dropdown-item>
        <b-dropdown-item class="toolLine" aria-role="listitem" @click="goToRoute('NewChapterYourVideo')">
          <w-icon class="icon"  icon="format-list-bulleted"/>
          {{ $t('header.tools.chapter') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <!-- AUTH -->
    <div class="buttonSignin" v-if="!isAutenthicated">
      <w-button data-test="headerSigninCTAButton" color="outlined" @click="signin">
        {{ $t('generic.signin') }}
      </w-button>
    </div>
    <div class="buttonSignin" data-test="headerSigninButton" v-if="!isAutenthicated">
      <w-button color="primary" @click="signup" data-test="headerSigninButton">
        {{ $t('header.button.signin') }}
      </w-button>
    </div>
    <div class="buttonSigninMobile" v-if="!isAutenthicated">
      <w-button data-test="headerSigninCTAButton" size="small" color="outlined" @click="signin">
        {{ $t('generic.signin') }}
      </w-button>
    </div>
    <div class="buttonSigninMobile" data-test="headerSigninButton" v-if="!isAutenthicated">
      <w-button color="primary" size="small" @click="signup" data-test="headerSigninButton">
        {{ $t('header.button.signin') }}
      </w-button>
    </div>

    <!-- NOTIFICATION -->
    <div class="notificationUser" v-if="isAutenthicated">
      <div class="beelButton" slot="trigger">
        <w-button size="medium" color="light" icon="bell-outline" data-test="headerNotificationButton"
                  @click="goToNotification"></w-button>
        <Transition name="zoomUp">
          <div class="redSticker" v-show="notificationUnread>0">{{ numberNotificationUnreadLabel }}</div>
        </Transition>
      </div>


    </div>
    <!-- PROFIL -->
    <HeaderDropDownMenu v-if="isAutenthicated"/>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import SignUp from '@/components/authPanel/SignUp.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import mobileDetect from 'mobile-device-detect';
import store from '@/store';
import {User} from '@/store/user/userModel';
import WIcon from '@/components/wrapper/w-icon.vue';
import {launchWeetCreation} from '@/utils/weetUtil';
import router, {isToolsRoute} from '@/router';
import NotificationItem from '@/components/notifications/NotificationItem.vue';
import {toLoginPage} from '@/utils/loginUtil';
import RecorderPauseButton from '@/components/recorder/button/RecorderPauseButton.vue';
import {OPEN_MENU} from '@/store/setup/setupAction';
import {MY_LIST_WEET, MyWeetListParams, REINIT_MY_WEET, StaticTag} from '@/store/myWeet/myWeetAction';
import {isGrid1X} from '@/utils/gridBreakPoint';
import SearchWeetAutoComplete from '@/components/weet/input/SearchWeetAutoComplete.vue';
import {
  canCreateWeet,
  canEditWorkspace,
  checkRightWorkspace,
  isFreemium,
  rightsEditWorkspace
} from '@/utils/workspaceUtil';
import HeaderDropDownMenu from '@/components/header/HeaderDropDownMenu.vue';
import {Workspace} from '@/store/workspace/workspaceModel';
import {getImageURLForSize} from '@/utils/util';
import {SELECT_MODE} from "@/store/advancedEditing/advancedEditingAction";
import {AdvancedEditingMode} from "@/enum/AdvancedEditingMode";
import UpgradePlanButton from "@/components/upgradePlan/UpgradePlanButton.vue";
import UpgradePlanTag from "@/components/upgradePlan/UpgradePlanTag.vue";

@Component(
    {
      name: 'Header',
      components: {
        UpgradePlanTag,
        UpgradePlanButton,
        HeaderDropDownMenu,
        RecorderPauseButton,
        NotificationItem, WIcon, WModal, SignUp, WButton, SearchWeetAutoComplete
      },
    })
export default class Header extends Vue {
  private routeSelected: string = '';

  private isMobile: boolean = false;


  private mounted() {
    this.isMobile = mobileDetect.isMobile;
  }

  get colorButtonCreateWeet(): string {
    let color = 'primary';
    if (this.routeSelected !== 'MyWeets') {
      color = 'outlined';
    }
    return color;
  }

  get isMenuOpen(): boolean {
    return store.getters.isMenuOpen;
  }

  get isAutenthicated(): boolean {
    return store.getters.isAuthenticated;
  }

  get isMenuRoute(): boolean {
    return !isToolsRoute(this.routeSelected)
  }


  get isCurrentlyRecord(): boolean {
    return store.getters.isPauseAivailable;
  }

  get isFreemium():boolean{
    return isFreemium();
  }


  private toggleMenu() {
    store.dispatch(OPEN_MENU, !store.getters.isMenuOpen);

  }

  private goToRoute(route: string) {
    router.push({name: route});
  }

  private gotToMyWeet() {
    const params = new MyWeetListParams();
    params.tagFilter = store.getters.getTagsFilter || StaticTag.ALL;
    params.channelFilter = store.getters.getChannelFilter;
    if (this.routeSelected !== 'MyWeets') {
      router.push({name: 'MyWeets'});
    }
    store.dispatch(REINIT_MY_WEET, params);
    store.dispatch(MY_LIST_WEET, params);
    // close the menu
    if (isGrid1X()) {
      store.dispatch(OPEN_MENU, false);
    }
  }

  private moveToEditWorkspace() {
    router.push({name: "MyWorkspaceEdit"})
  }


  get isAdmin(): boolean {
    return canEditWorkspace()
  }

  get userConnected(): User {
    return store.getters.userConnected;
  }

  get notificationUnread(): number {
    return store.getters.getTotalOfUnreadNotification;
  }

  get numberNotificationUnreadLabel(): string {
    if (this.notificationUnread > 99) {
      return '•';
    } else {
      return this.notificationUnread + '';
    }
  }

  get isExtAivailable(): boolean {
    return store.getters.isExtAvalaible;
  }

  get isCanCreateAWeet(): boolean {
    return canCreateWeet();
  }

  get logoHeader(): string {
    return store.getters.getLogoHeader;
  }


  private createWeet() {
    store.dispatch(SELECT_MODE, AdvancedEditingMode.NONE);
    launchWeetCreation();
  }

  private signin() {
    toLoginPage();
  }

  private signup() {
    router.push({name: 'CreateAccount'});
  }

  private goToNotification() {
    if (this.routeSelected !== 'MyNotification') {
      router.push({name: 'MyNotification'});
    }
  }

  @Watch('$route', {immediate: true, deep: true})
  private onRouteChange(route: any) {
    this.routeSelected = route.name;
  }
}
</script>
<style scoped lang="scss">
@import "@/scss/gridBreakPoint.scss";

.header {
  width: 100%;
  display: flex;
  top: 0;
  height: 80px;
  position: absolute;
  background: white;
  align-items: center;
  border-bottom: 1px solid var(--light);

  .iconMenu {
    transition: transform 0.2s ease;
    cursor: pointer;
    color: var(--dark);
    width: 80px;
    min-width: 80px;

    &:hover {
      transform: scale(1.05);
    }

  }

  .headerContainer {
    display: flex;
    align-items: center;

    .logo {
      cursor: pointer;

      .logoCustom {
        max-height: 48px;
        max-width: 240px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @include GRID1X {
          display: none;
        }
      }

      .logoImage {
        background: url(@/assets/logo/weet_h_gradiant.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 112px;
        height: 32px;
        margin-left: 0px;
        cursor: pointer;
        @include GRID1X {
          display: none;
        }
      }

      .logoLight {
        background: url(@/assets/logo/bee_logo_gradiant.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 64px;
        height: 48px;
        margin-left: 16px;
        display: none;
        @include GRID1X {
          display: block;
        }
      }
    }

    .buttonModify {
      opacity: 0;
      margin-left: 8px;
    }

    &:hover {
      .buttonModify {
        opacity: 1;
      }
    }
  }

  .buttonFreemium{
    margin-right: 16px;
  }

  .buttonTools {
    margin-right: 16px;
    .toolLine {
      display: flex;
      font-size: 14px;
      align-items: center;

      .icon {
        margin-right: 16px;
      }
    }
  }

  .buttonCreateSpeach {
    margin-right: 16px;

  }

  .buttonSignin {
    margin-right: 16px;
    @include GRID1X {
      display: none;
    }
  }

  .buttonSigninMobile {
    display: none;
    font-size: 12px;
    margin-right: 16px;
    @include GRID1X {
      display: block;
    }
  }

  .beelButton {
    margin-right: 16px;
    width: 48px;
    height: 48px;
    position: relative;

    .redSticker {
      color: #FFFFFF;
      position: absolute;
      background: var(--red);
      border-radius: 20px;
      font-size: 14px;
      text-align: center;
      width: 20px;
      height: 20px;
      right: -4px;
      top: -4px;
    }
  }

  .buttonNotification {
    padding-top: 16px;
    padding-bottom: 4px;
  }

  .menuSearchPanel {
    margin: auto;
    flex-grow: 1;
    justify-content: center;
    margin-left: 48px;
    margin-right: 24px;
    @include GRID1X {
      margin-left: 0px;
      margin-right: 0px;
    }

    .inputSearch {
      @include GRID1X {
        display: none;
      }
    }

  }

  .linkItems {
    font-size: 13px;
    margin: auto;
    font-weight: 900;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: var(--dark);
    display: flex;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    justify-content: center;

    .myWeetLink {
      cursor: pointer;
      margin-right: 24px;
      margin-left: 24px;
      height: calc(100% + 1px);
      padding-top: 28px;
      padding-bottom: 28px;
      min-width: 50%;
      color: var(--dark);

      span {
        min-height: 22px;
        line-height: 22px;
      }

      &.selected {
        border-bottom: 2px solid var(--primary);
      }

      &.information {
        &.disabled {
          color: var(--light1);
          cursor: auto;
        }

        @include GRID1X {
          display: none;
        }
        @include GRID2X {
          display: none;
        }
      }
    }
  }

}
</style>
