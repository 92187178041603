import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {VirtualBackgroundEnum} from '@/enum/VirtualBackgroundEnum';
import {ScreenVirtualBackgroundEnum} from '@/enum/ScreenVirtualBackgroundEnum';
import store from '@/store';
import {getImageURLForSize} from '@/utils/util';


// TODO this list must be provide by the back
export const listOfFilter = () => {
    return [
        {
            value: FilterVideoType.HIGH_CONTRAST,
            label: 'High contrast',
            img: '',
            animated: false,
            exportAivailable: true, full: true
        },
        {
            value: FilterVideoType.SEPIA,
            label: 'Sepia',
            img: '',
            animated: false,
            exportAivailable: true,
            full: true
        },
        {
            value: FilterVideoType.GRAY_SCALE,
            label: 'Black and white',
            img: '',
            animated: false,
            exportAivailable: true,
            full: true
        },

        {
            value: FilterVideoType.BRIGHT,
            label: 'Brightness',
            img: '',
            animated: false,
            exportAivailable: true,
            full: true
        },
        {
            value: FilterVideoType.SATURATE,
            label: 'Saturation',
            img: '',
            animated: false,
            exportAivailable: true,
            full: true
        },
        // {
        //     value: FilterVideoType.CHRISTMAS_SNOW,
        //     label: 'Christmas snow',
        //     img: '',
        //     animated: true,
        //     exportAivailable: false,
        //     new: true
        // },
        // {
        //   value: FilterVideoType.HALLOWEEN,
        //   label: 'Halloween',
        //   img: '',
        //   animated: true,
        //   exportAivailable: false,
        //   full: true,
        //   new: true
        // },
        // {
        //     value: FilterVideoType.XRAY, label: 'X-RAY',
        //     animated: false,
        //     exportAivailable: false,
        //     full: true,
        //     new: true
        // },
        // {
        //     value: FilterVideoType.EASTER, label: 'Easter',
        //     animated: true,
        //     exportAivailable: false,
        //     full: false,
        //     new: true
        // },
        // {
        //     value: FilterVideoType.PSYCHEDELIC, label: 'Psychedelic',
        //     animated: true,
        //     exportAivailable: false,
        //     full: true,
        //     new: true
        // },
        {
            value: FilterVideoType.WEET,
            label: 'Weet',
            img: '',
            animated: false,
            exportAivailable: false,
            full: true,
            new: false
        },
        {
            value: FilterVideoType.PEACH,
            label: 'Peach',
            img: '',
            animated: false,
            exportAivailable: false,
            full: true,
            new: false
        },
        {
            value: FilterVideoType.GRINK,
            label: 'Green and Pink',
            img: '',
            animated: false,
            exportAivailable: false,
            full: true,
            new: false
        },
        {value: FilterVideoType.AUTUMN, label: 'Autumn', animated: true, exportAivailable: false, full: false},
        // {value: FilterVideoType.SHINING, label: 'Shine', img: '',animated:true,exportAivailable:false},
        // {value: FilterVideoType.AQUA1, label: 'Aquarium', img: '', animated: true, exportAivailable: false},
        {value: FilterVideoType.STARS, label: 'Stars', img: '', animated: true, exportAivailable: false, full: false},
        {
            value: FilterVideoType.SNOW_STAR,
            label: 'Snow',
            img: '',
            animated: true,
            exportAivailable: false,
            full: false
        },
        {value: FilterVideoType.SUNSET, label: 'Sunset', img: '', animated: false, exportAivailable: false, full: true},
        {value: FilterVideoType.NEON, label: 'Neon', img: '', animated: false, exportAivailable: false, full: true},
        {
            value: FilterVideoType.AUBURN,
            label: 'Auburn',
            img: '',
            animated: false,
            exportAivailable: false,
            full: true,
            new: false
        },
        {value: FilterVideoType.HEART, label: 'Heart', img: '', animated: false, exportAivailable: false, full: true},
        {
            value: FilterVideoType.ROSERT,
            label: 'Red',
            img: '',
            animated: false,
            exportAivailable: false,
            full: true
        },
        {value: FilterVideoType.VIOLET, label: 'Violet', img: '', animated: false, exportAivailable: false, full: true},
        {value: FilterVideoType.BLUE, label: 'Blue', img: '', animated: false, exportAivailable: false, full: true},
        {
            value: FilterVideoType.VINTAGE,
            label: 'Vintage',
            img: '',
            animated: false,
            exportAivailable: false,
            full: true
        }


        //   {value: FilterVideoType.BULLE, label: 'Bubble', img: '',animated:true,exportAivailable:false},
        //  {value: FilterVideoType.OVERLAY1, label: 'Green and red', img: '', animated: false, exportAivailable: false},

    ];
};


export const listOfVirtualBackground = () => {
    return [
        {
            value: VirtualBackgroundEnum.VB_CUSTOM_SLOT_1,
            type: 'custom',
            label: 'Custom 1',
            img: '',
            exportAivailable: true
        },
        {value: VirtualBackgroundEnum.BLUR, type: 'blur', label: 'Blur background', img: '', exportAivailable: true},
        {
            value: VirtualBackgroundEnum.HOUSE_1,
            label: 'House one',
            type: 'image',
            img: '/vbThumbnail/HOUSE_1.jpg',
            exportAivailable: true
        },
        {
            value: VirtualBackgroundEnum.HOUSE_2,
            label: 'House Two',
            type: 'image',
            img: '/vbThumbnail/HOUSE_2.jpg',
            exportAivailable: true
        },

        {
            value: VirtualBackgroundEnum.HOUSE_3,
            label: 'House Three',
            type: 'image',
            img: '/vbThumbnail/HOUSE_3.jpg',
            exportAivailable: true
        },
        {
            value: VirtualBackgroundEnum.OFFICE,
            type: 'video',
            label: 'Office',
            img: '/vbThumbnail/OFFICE.jpeg',
            exportAivailable: true
        },
        {
            value: VirtualBackgroundEnum.OFFICE2,
            type: 'video',
            label: 'Office 2',
            img: '/vbThumbnail/OFFICE2.jpeg',
            exportAivailable: true
        },
        {
            value: VirtualBackgroundEnum.CITY,
            type: 'video',
            label: 'City',
            img: '/vbThumbnail/CITY.jpeg',
            exportAivailable: true
        },
        // {
        //     value: VirtualBackgroundEnum.PALM_2,
        //     label: 'Palm 2',
        //     type: 'video',
        //     img: '/vbThumbnail/PALM_2.jpeg',
        //     exportAivailable: true
        // },
        // {
        //     value: VirtualBackgroundEnum.WATTERFALL_2,
        //     label: 'Waterfall 2',
        //     type: 'video',
        //     img: '/vbThumbnail/WATTERFALL_2.jpeg',
        //     exportAivailable: true
        // },

        // {
        //     value: VirtualBackgroundEnum.ATLANTIQUE,
        //     label: 'Atlantique',
        //     type: 'video',
        //     img: '/vbThumbnail/ATLANTIQUE.jpeg',
        //     exportAivailable: true
        // },
        // {
        //     value: VirtualBackgroundEnum.BEACH,
        //     label: 'Beach ',
        //     img: '/vbThumbnail/BEACH.jpeg',
        //     exportAivailable: true
        // },
        // {
        //     value: VirtualBackgroundEnum.AQUARIUM,
        //     label: 'Aquarium',
        //     type: 'video',
        //     img: '/vbThumbnail/AQUARIUM.jpeg',
        //     exportAivailable: true
        // },
        // {
        //     value: VirtualBackgroundEnum.RAIN_FOREST,
        //     label: 'Rain Forest',
        //     type: 'video',
        //     img: '/vbThumbnail/RAIN_FOREST.jpeg',
        //     exportAivailable: true
        // },
        {
            value: VirtualBackgroundEnum.SAN_FRANCISCO,
            label: 'San francisco',
            type: 'video',
            img: '/vbThumbnail/SAN_FRANCISCO.jpeg',
            exportAivailable: true
        },
        // {
        //     value: VirtualBackgroundEnum.CLOUD,
        //     label: 'Cloud',
        //     type: 'video',
        //     img: '/vbThumbnail/CLOUD.jpeg',
        //     exportAivailable: true
        // },
        // {
        //     value: VirtualBackgroundEnum.BLUE,
        //     label: 'Blue',
        //     type: 'video',
        //     img: '/vbThumbnail/BLUE.jpeg',
        //     exportAivailable: true
        // },

        {
            value: VirtualBackgroundEnum.LIQUID,
            label: 'Liquid',
            type: 'video',
            img: '/vbThumbnail/LIQUID.jpeg',
            exportAivailable: true
        },
        {
            value: VirtualBackgroundEnum.PINK_3,
            label: 'Pink 3',
            type: 'video',
            img: '/vbThumbnail/PINK_3.jpeg',
            exportAivailable: true
        },
        {
            value: VirtualBackgroundEnum.PURPLE,
            label: 'Weet',
            type: 'video',
            img: '/vbThumbnail/PURPLE.jpeg',
            exportAivailable: true,
        },
        {
            value: VirtualBackgroundEnum.DARK_LIQUID_3,
            label: 'Dark liquid 3',
            type: 'video',
            img: '/vbThumbnail/DARK_LIQUID_3.jpeg',
            exportAivailable: true
        },
        {
            value: VirtualBackgroundEnum.WHITE_GEO,
            label: 'White geo',
            type: 'video',
            img: '/vbThumbnail/WHITE_GEO.jpeg',
            exportAivailable: true
        },

        {
            value: VirtualBackgroundEnum.YELLOW_GEO,
            label: 'Yellow geo',
            type: 'video',
            img: '/vbThumbnail/YELLOW_GEO.jpeg',
            exportAivailable: true
        },


        {
            value: VirtualBackgroundEnum.SPACE_4,
            label: 'Space 4',
            type: 'video',
            img: '/vbThumbnail/SPACE_4.jpeg',
            exportAivailable: true
        },
        {
            value: VirtualBackgroundEnum.VORTEX,
            label: 'Vortex',
            type: 'video',
            img: '/vbThumbnail/VORTEX.jpeg',
            exportAivailable: true
        },
        // {
        //     value: VirtualBackgroundEnum.CHRISTMAS,
        //     label: 'Christmas',
        //     img: '/vbThumbnail/CHRISTMAS.jpg',
        //     exportAivailable: true
        // }
    ];
};


export const listOfScreenVirtualBackground = () => {
    return [
        {
            value: ScreenVirtualBackgroundEnum.PROCESS_REVIEW,
            label: 'Process review',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.PROCESS_REVIEW + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.STEP_1,
            label: 'Step 1',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.STEP_1 + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.STEP_2,
            label: 'Step 2',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.STEP_2 + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.STEP_3,
            label: 'Step 3',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.STEP_3 + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.STEP_4,
            label: 'Step 4',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.STEP_4 + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.STEP_5,
            label: 'Step 5',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.STEP_5 + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.FEEDBACK,
            label: 'Feedback',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.FEEDBACK + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.CONCLUSION,
            label: 'Conclusion',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.CONCLUSION + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_BEST_PRACTICE,
            label: 'Best practice',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_BEST_PRACTICE + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_DOCUMENT_REVIEW,
            label: 'Document review',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_DOCUMENT_REVIEW + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_HOWTO,
            label: 'How To',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_HOWTO + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_INTRODUCTION,
            label: 'Introduction',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_INTRODUCTION + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_MEETING_FOLLOW_UP,
            label: 'Meeting Follow Up',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_MEETING_FOLLOW_UP + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_NEW_FEATURE,
            label: 'New Feature',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_NEW_FEATURE + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_NEWS,
            label: 'News',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_NEWS + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_PROJECT_BRIEF,
            label: 'Project Brief',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_PROJECT_BRIEF + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_QUICK_NOTE,
            label: 'Quick Note',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_QUICK_NOTE + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_QUICK_UPDATE,
            label: 'Quick Update',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_QUICK_UPDATE + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },
        {
            value: ScreenVirtualBackgroundEnum.NEUTRAL_REPORT,
            label: 'Report',
            img: '',
            url: 'screenVB/' + ScreenVirtualBackgroundEnum.NEUTRAL_REPORT + '.mp4',
            exportAivailable: true,
            anonyme: true,
        },

    ];
};

export const listOfAnonymeScreenVirtualBackground = () => {
    return listOfScreenVirtualBackground().filter((sceenBackground) => sceenBackground.anonyme);
};


/***
 *
 * ARCHIVE VB
 *
 *
 */

// {
//   value: VirtualBackgroundEnum.BLUR_1,
//   label: 'BLur color',
//   img: '/vbThumbnail/BLUR_1.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.BLUR_2,
//   label: 'BLur color 2',
//   img: '/vbThumbnail/BLUR_2.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.BLUR_3,
//   label: 'BLur color 3',
//   img: '/vbThumbnail/BLUR_3.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.BLUR_4,
//   label: 'BLur color 4',
//   img: '/vbThumbnail/BLUR_4.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.BLUR_5,
//   label: 'BLur color 5',
//   img: '/vbThumbnail/BLUR_5.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.BLUR_6,
//   label: 'BLur color 6',
//   img: '/vbThumbnail/BLUR_6.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.BLUR_7,
//   label: 'BLur color 7',
//   img: '/vbThumbnail/BLUR_7.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.DARK_LIQUID,
//   label: 'Dark liquid',
//   img: '/vbThumbnail/DARK_LIQUID.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.DARK_LIQUID_2,
//   label: 'Dark liquid 2',
//   img: '/vbThumbnail/DARK_LIQUID_2.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.DARK_LIQUID_4,
//   label: 'Dark liquid 4',
//   img: '/vbThumbnail/DARK_LIQUID_4.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.DARK_LIQUID_5,
//   label: 'Dark liquid 5',
//   img: '/vbThumbnail/DARK_LIQUID_5.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.LIQUID_2,
//   label: 'Liquid 2',
//   img: '/vbThumbnail/LIQUID_2.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.PINK,
//   label: 'Pink',
//   img: '/vbThumbnail/PINK.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.PINK_2,
//   label: 'Pink 2',
//   img: '/vbThumbnail/PINK_2.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.SPACE_2,
//   label: 'Space 2',
//   img: '/vbThumbnail/SPACE_2.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.SPACE_3,
//   label: 'Space 3',
//   img: '/vbThumbnail/SPACE_3.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.WHITE_CURVE,
//   label: 'White curve',
//   img: '/vbThumbnail/WHITE_CURVE.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.WHITE_GEO_2,
//   label: 'White geo 2',
//   img: '/vbThumbnail/WHITE_GEO_2.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.YELLOW_VORTEX,
//   label: 'Yellow Vortex',
//   img: '/vbThumbnail/YELLOW_VORTEX.jpeg',
//   exportAivailable: true
// },
// {
//   value: VirtualBackgroundEnum.PALM,
//   label: 'Palm',
//   img: '/vbThumbnail/PALM.jpeg',
//   exportAivailable: true
// }
// {value: VirtualBackgroundEnum.SPACE, label: 'Space', img: '/vbThumbnail/SPACE.jpeg', exportAivailable: true},
// {
//   value: VirtualBackgroundEnum.BROWN_FOREST,
//     label: 'Brown Forest',
//   img: '/vbThumbnail/BROWN_FOREST.jpeg',
//   exportAivailable: true
// },

/***
 * SCREEN ARCHIVE
 *
 */

// {
//   value: ScreenVirtualBackgroundEnum.WHITE_GEO_2,
//   label: 'White geo 2',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.WHITE_GEO_2 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.YELLOW_VORTEX,
//   label: 'Yellow vortex',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.YELLOW_VORTEX + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.DARK_LIQUID_4,
//   label: 'Dark liquid 4',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.DARK_LIQUID_4 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.DARK_LIQUID_5,
//   label: 'Dark liquid 5',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.DARK_LIQUID_5 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.LIQUID_2,
//   label: 'Liquid 2',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.LIQUID_2 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.PALM,
//   label: 'Palm',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.PALM + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.SPACE_2,
//   label: 'Space 2',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.SPACE_2 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.SPACE_3,
//   label: 'Space 3',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.SPACE_3 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.WHITE_CURVE,
//   label: 'White curve ',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.WHITE_CURVE + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.PINK,
//   label: 'Pink',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.PINK + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.PINK_2,
//   label: 'Pink 2',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.PINK_2 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.BLUR_1,
//   label: 'Blur color 1',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUR_1 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.BLUR_2,
//   label: 'Blur color 2',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUR_2 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.BLUR_3,
//   label: 'Blur color 3',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUR_3 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.BLUR_4,
//   label: 'Blur color 4',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUR_4 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.BLUR_5,
//   label: 'Blur color 5',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUR_5 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.BLUR_6,
//   label: 'Blur color 6',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUR_6 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.BLUR_7,
//   label: 'Blur color 7',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUR_7 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.DARK_LIQUID,
//   label: 'Dark liquid',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.DARK_LIQUID + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.DARK_LIQUID_2,
//   label: 'Dark liquid 2',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.DARK_LIQUID_2 + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.CITY_NIGHT,
//   label: 'City night ',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.CITY_NIGHT + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },
// {
//   value: ScreenVirtualBackgroundEnum.EASTER,
//   label: 'Easter ',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.EASTER + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.VALENTINE,
//   label: 'Valentine',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.VALENTINE + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.CONGRATULATIONS2,
//   label: 'Congratulation 2 ',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.CONGRATULATIONS2 + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.SPACE,
//   label: 'Space ',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.SPACE + '.mp4',
//   exportAivailable: true,
//   anonyme: true,
// },

// {
//   value: ScreenVirtualBackgroundEnum.CHRISTMAS,
//   label: 'Christmas ',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.CHRISTMAS + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.CHRISTMAS2,
//   label: 'Christmas 2',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.CHRISTMAS2 + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.CHRISTMAS3,
//   label: 'Christmas 3',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.CHRISTMAS3 + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.CHRISTMAS4,
//   label: 'Christmas 4',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.CHRISTMAS4 + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.CHRISTMAS5,
//   label: 'Christmas 5',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.CHRISTMAS5 + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.HAPPY_NY1,
//   label: 'Happy New Year 1',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY1 + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.HAPPY_NY2,
//   label: 'Happy New Year 2',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY2 + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.HAPPY_NY3,
//   label: 'Happy New Year 3',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY3 + '.mp4',
//   exportAivailable: true
// },
// {
//   value: ScreenVirtualBackgroundEnum.HAPPY_NY4,
//   label: 'Happy New Year 3',
//   img: '',
//   url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY4 + '.mp4',
//   exportAivailable: true
// },
// {
//     value: ScreenVirtualBackgroundEnum.ABSTRACT1,
//     label: 'Abstract 1',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.ABSTRACT1 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.ABSTRACT2,
//     label: 'Abstract 2',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.ABSTRACT2 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.ABSTRACT3,
//     label: 'Abstract 3',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.ABSTRACT3 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.COTON,
//     label: 'Coton',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.COTON + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.STAR1,
//     label: 'Star 1',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.STAR1 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.BLUE_2,
//     label: 'Blue 2',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUE_2 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.BLUE_3,
//     label: 'Blue 3',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUE_3 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.PINK_4,
//     label: 'Pink 4',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.PINK_4 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.ORANGE_1,
//     label: 'Orange',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.ORANGE_1 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.RED_1,
//     label: 'Red',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.RED_1 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.PURPLE_2,
//     label: 'Purple 2',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.PURPLE_2 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.GREEN,
//     label: 'Green',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.GREEN + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.VALENTINE2,
//     label: 'Valentine',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.VALENTINE2 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.VALENTINE,
//     label: 'Valentine',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.VALENTINE + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.COFFEE,
//     label: 'Coffee time ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.COFFEE + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
//
// {
//     value: ScreenVirtualBackgroundEnum.THANKS,
//     label: 'Thank you ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.THANKS + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.CONGRATULATIONS,
//     label: 'Congratulation ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.CONGRATULATIONS + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.HELLO,
//     label: 'Hello ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.HELLO + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.WELCOME,
//     label: 'Welcome ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.WELCOME + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.AWESOME,
//     label: 'Awesome ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.AWESOME + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.HAPPY_BIRTHDAY,
//     label: 'Happy Birthday ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_BIRTHDAY + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.BNEWS,
//     label: 'Breaking news ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.BNEWS + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.HAPPY_NY1,
//     label: 'Happy New Year 1',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY1 + '.mp4',
//     exportAivailable: true
// },
// {
//     value: ScreenVirtualBackgroundEnum.HAPPY_NY3,
//     label: 'Happy New Year 3',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY3 + '.mp4',
//     exportAivailable: true
// },
// {
//     value: VirtualBackgroundEnum.CHRISTMAS,
//     label: 'Christmas',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.CHRISTMAS + '.mp4',
//     exportAivailable: true,
//     anonyme: false
// },
// {
//     value: ScreenVirtualBackgroundEnum.HAPPY_NY5,
//     label: 'Happy New Year 5',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY5 + '.mp4',
//     exportAivailable: true,
//     anonyme: false
// },
// {
//     value: ScreenVirtualBackgroundEnum.HAPPY_NY6,
//     label: 'Happy New Year 6',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY6 + '.mp4',
//     exportAivailable: true,
//     anonyme: false
// },
// {
//     value: ScreenVirtualBackgroundEnum.HAPPY_NY7,
//     label: 'Happy New Year 7',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.HAPPY_NY7 + '.mp4',
//     exportAivailable: true,
//     anonyme: false
// },
// {
//     value: ScreenVirtualBackgroundEnum.CITY_CALM,
//     label: 'City calm ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.CITY_CALM + '.mp4',
//     exportAivailable: false,
//     anonyme: false
// },
// {
//     value: ScreenVirtualBackgroundEnum.FIRE_PIT,
//     label: 'Fire pit',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.FIRE_PIT + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.PALM_2,
//     label: 'Palm',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.PALM_2 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.BEACH,
//     label: 'Beach ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.BEACH + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.WATERFALL,
//     label: 'Waterfall',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.WATERFALL + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.ATLANTIQUE,
//     label: 'Atlantique',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.ATLANTIQUE + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.WATERFALL_2,
//     label: 'Waterfall 2 ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.WATERFALL_2 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
//
// {
//     value: ScreenVirtualBackgroundEnum.CLOUD,
//     label: 'Cloud',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.CLOUD + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
//
//
// {
//     value: ScreenVirtualBackgroundEnum.BLUE,
//     label: 'Blue',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.BLUE + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
//
// {
//     value: ScreenVirtualBackgroundEnum.PINK_3,
//     label: 'Pink 3',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.PINK_3 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.PURPLE,
//     label: 'Weet',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.PURPLE + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
//
// {
//     value: ScreenVirtualBackgroundEnum.WHITE_GEO,
//     label: 'White geo ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.WHITE_GEO + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
//
// {
//     value: ScreenVirtualBackgroundEnum.YELLOW_GEO,
//     label: 'Yellow geo',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.YELLOW_GEO + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.PARTICULE_COLOR,
//     label: 'Particule color',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.PARTICULE_COLOR + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.PARTICULE_BRIGHT,
//     label: 'Particule bright',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.PARTICULE_BRIGHT + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.PARTICULE_ORANGE,
//     label: 'Particule orange',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.PARTICULE_ORANGE + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.DARK_LIQUID_3,
//     label: 'Dark liquid 3',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.DARK_LIQUID_3 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
//
//
// {
//     value: ScreenVirtualBackgroundEnum.LIQUID,
//     label: 'Liquid',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.LIQUID + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
//
// {
//     value: ScreenVirtualBackgroundEnum.SPACE_4,
//     label: 'Space 4',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.SPACE_4 + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },
// {
//     value: ScreenVirtualBackgroundEnum.VORTEX,
//     label: 'Vortex ',
//     img: '',
//     url: 'screenVB/' + ScreenVirtualBackgroundEnum.VORTEX + '.mp4',
//     exportAivailable: true,
//     anonyme: false,
// },

export const getImageForVBCustom = (key: VirtualBackgroundEnum) => {
    const value = store.getters.getSetting(key);
    if (!value) {
        return require('@/assets/avatar.jpg');
    } else {
        return getImageURLForSize(value, 1440);
    }
};
