<template>
  <transition name="fade">
    <div class="AiEditorContainer"
         v-show="streamLoading"
    >
      <b-carousel class="carrouselAvatars" :autoplay="false" :has-drag="false" :arrow="false" :indicator="false"
      v-model="step">
        <b-carousel-item class="aiAvatarSelection">
          <div class="avatarPreview" :class="avatarSelectedClass" ></div>
          <div class="avatarParameter">
            <div class="labelSelect"  v-if="false">{{$t('aiAvatar.avatarSelector')}}</div>
            <w-select v-if="false" class="paramsSelect" size="small" v-model="avatarSelected">
              <option v-for="avatar in listOfAvatar" :value="avatar.value" :key="'avatar_'+avatar.value">
                {{ avatar.label }}
              </option>
            </w-select>
            <div class="labelSelect">{{$t('aiAvatar.styleSelector')}}</div>
            <w-select class="paramsSelect" size="small"  v-model="styleSelected" >
              <option v-for="style in listOfStyle" :value="style.value" :key="'style_'+style.value">
                {{ style.label }}
              </option>
            </w-select>

              <div class="labelSelect" >{{$t('aiAvatar.voicesSelector')}}</div>
              <w-select class="paramsSelect" v-model="voiceSelected" size="small" >
                <option v-for="voice in listOfVoice" :value="voice.value" :key="'voice_'+voice.value">
                  {{ voice.label }}
                </option>
              </w-select>
          </div>
        </b-carousel-item>
        <b-carousel-item class="aiAvatarContentEdition">
          <div class="labelTypeYourText" >
            <w-button color="text" icon="arrow-left" size="small" class="buttonBack" @click="backToFirstStep"></w-button>
            {{$t('aiAvatar.content.label')}}
            <w-tool-tip :label="$t('aiAvatar.languageSupported')" :position="'top'" :top-offset="24">
              <w-icon size="small" color="text" icon="information-outline" class="iconInformation"/>
            </w-tool-tip>
          </div>
          <w-input type="textarea" v-model="textToGenerate"  @input="onTextInput" class="contentTextAreaAI" :placeholder="$t('aiAvatar.content.placeholder')"
          :maxlength="maxlengthOfCharacter">

          </w-input>
          <div v-if="isFreemium" class="upgradeMessage">{{$t('aiAvatar.content.upgrade')}}</div>
          <div class="tipItem" v-if="isFreemium">
            <upgrade-plan-button size="small" color="secondary" />
          </div>
        </b-carousel-item>
        <b-carousel-item class="generateStep">
          <CenterCenter>
            <div>
              <div class="progressGenerate">{{ $t('aiAvatar.generate.progress', { avatar: avatarSelected }) }}</div>
              <div class="pourcentageValue">{{ pourcentageGeneration.toFixed(2) }}%</div>
              <b-progress size="is-small" :value="pourcentageGeneration" type="is-primary" class="progressBar">
              </b-progress>
            </div>
          </CenterCenter>
        </b-carousel-item>
      </b-carousel>
    </div>
  </transition>

</template>

<script lang="ts">
import store from '@/store';
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import {SourceVideoType} from '@/enum/SourceVideoEnum';


import {
  AVATAR_AI_TEXT,
  CHANGE_STEP_AI_GENERATION,
  CLEAR_MAIN_MEDIAID, CLEAR_STICKER_MEDIAID,
  GENERATE_MAIN_MEDIAID,
  UPDATE_MAIN_RUNNING_STATE,
} from '@/store/recordingState/recordStateAction';
import VideoFilePlayer from '@/components/recorder/videoLayout/videoPlayer/VideoFilePlayer.vue';
import WSelect from "@/components/wrapper/w-select.vue";
import WButton from "@/components/wrapper/w-button.vue";
import WInput from "@/components/wrapper/w-input.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import UpgradePlanButton from "@/components/upgradePlan/UpgradePlanButton.vue";
import {isFreemium} from "@/utils/workspaceUtil";
import {
  AiavatarGenerationModel,
  Avatar,
  AvatarStyle,
  AvatarVoiceAvatar
} from "@/store/AIAvatar/aiavatarGenerationModel";
import {initWeetEditing} from "@/utils/createWeetUtil";
import {Media} from "@/store/media/mediaModel";
import {AIAVATAR_GENERATE, GENERATE_AVATAR_IN_PROGRESS, METADATA_MEDIA, REFRESH_MEDIA} from "@/store/media/mediaAction";
import delay from "delay";
import {isMediaNeedRefresh} from "@/utils/util";
import {SAVE_TIMELINE_IF_NEED, TIMELINE_UNSAVED} from "@/store/timeLine/timeLineAction";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import {alertError, alertErrorWithConfirmation} from "@/utils/dialog";
import WToolTip from "@/components/wrapper/w-toolTip.vue";


@Component({
  components: {WToolTip, CenterCenter, UpgradePlanButton, WIcon, WInput, WButton, WSelect, VideoFilePlayer},
  computed: {},
})
export default class AIAvatarSource extends Vue {

  private listOfVoice = [
    {label:'Vivienne', value: AvatarVoiceAvatar.FR_FR_VIVIENNE},
    {label:'Avia', value: AvatarVoiceAvatar.US_AVIA},
    {label:'Emma', value: AvatarVoiceAvatar.US_EMMA},
    {label:'Serphina', value: AvatarVoiceAvatar.DE_SERPHINA},
    {label:'Xiao', value: AvatarVoiceAvatar.ZN_XIAO},

  ]
  private listOfAvatar=[
    {label:'Lisa',value:'LISA'}
  ]
  private listOfStyle=[
    {label:'Formal',value:AvatarStyle.GRACEFUL_SITTING},
    {label:'Casual',value:AvatarStyle.CASUAL_SITTING},
    {label:'Formal Standing',value:AvatarStyle.GRACEFUL_STANDING},
    {label:'Modern',value:AvatarStyle.TECHNICAL_SITTING},
    {label:'Modern Standing',value:AvatarStyle.TECHNICAL_STANDING},
  ]

  private voiceSelected=AvatarVoiceAvatar.US_AVIA
  private avatarSelected=Avatar.LISA
  private styleSelected=AvatarStyle.GRACEFUL_SITTING
  private textToGenerate:string="";
  // value if the stream is setup
  private streamLoading: boolean = false;

  private pourcentageGeneration:number=0;

  private MAX_LENGTH_FREEMIUM=500;
  private MAX_LENGTH_PREMIUM=5000;



  get step(){
    return store.getters.getAiAvatarStep;
  }

  get avatarSelectedClass(){
    return this.avatarSelected+"_"+this.styleSelected;
  }

  get isFreemium():boolean{
    return isFreemium()
  }
  public async mounted() {
    this.streamLoading = true;
    this.textToGenerate=store.getters.getAiAvatarText
  }
  get maxlengthOfCharacter(){
    if(this.isFreemium){
      return this.MAX_LENGTH_FREEMIUM;
    }else{
      return this.MAX_LENGTH_PREMIUM;
    }
  }


  private backToFirstStep(){
    store.dispatch(CHANGE_STEP_AI_GENERATION,0)
  }

  private async initWeetEditing() {
    if (store.getters.getEditingWeetID === '') {
      await initWeetEditing()
    }
  }


  public generateMediaID() {
    if (store.getters.getMainRecordSource === SourceVideoType.NONE) {
      store.dispatch(CLEAR_MAIN_MEDIAID);
    }
    store.dispatch(GENERATE_MAIN_MEDIAID);
  }

  public updateRunningStatus(value: boolean) {
    store.dispatch(UPDATE_MAIN_RUNNING_STATE, value);
  }

  private onTextInput(){
    store.dispatch(AVATAR_AI_TEXT,this.textToGenerate);
  }

  @Watch("step")
  public async launchAvatarGeneration(){
    if(this.step!==2){ //if not step loading
      return;
    }
    if(this.textToGenerate){
      try {
        store.dispatch(CHANGE_STEP_AI_GENERATION, 2)
        //this.reinit();
        await this.initWeetEditing(); // obtain a weet ID
        await store.dispatch(CLEAR_STICKER_MEDIAID);
        var mediaID = await store.dispatch(GENERATE_MAIN_MEDIAID);

        // create a media
        let media = new Media();
        media.mediaID = store.getters.getMainMediaId;
        media.type = SourceVideoType.AI_AVATAR;


        var params = new AiavatarGenerationModel(this.textToGenerate,
            this.avatarSelected, this.voiceSelected, this.styleSelected);
        media = await store.dispatch(AIAVATAR_GENERATE, {media, aiavatarParams: params});
        console.log("RefreshedMedia: ", media)
        await store.dispatch(TIMELINE_UNSAVED);
        media = await store.dispatch(REFRESH_MEDIA, media);
        while (isMediaNeedRefresh(media)) {
          await store.dispatch(TIMELINE_UNSAVED);
          await delay(2000)
          media = store.getters.getMediasForMediaId(media.mediaID);
          this.pourcentageGeneration = media.progress;
        }
        store.dispatch(GENERATE_AVATAR_IN_PROGRESS, false)
        await store.dispatch(METADATA_MEDIA, media.mediaID);
        await store.dispatch(TIMELINE_UNSAVED);
        await store.dispatch(SAVE_TIMELINE_IF_NEED);
        store.dispatch(AVATAR_AI_TEXT, "");
        this.$emit('close');
      }catch (e){
        alertErrorWithConfirmation(this.$t('aiAvatar.content.error').toString()).then(()=>{
          this.$emit('close');
        })
      }


    }
  }
}
</script>

<style lang="scss">
// no scope to manage textarea size
.contentTextAreaAI{
  .textarea{
    height: 170px;
    resize: none;
  }
}


</style>
<style lang="scss" scoped>
@import "@/scss/shadows.scss";
.AiEditorContainer {
  position: absolute;
  display: flex;
  width: 100%;
  animation-duration: 1s;
  border-radius: 8px;

  .carrouselAvatars{
    width: 100%;
    .aiAvatarSelection{
      width: 100%;
      min-height: 314px;
      background: var(--light);
      border-radius: 8px;
      .avatarParameter{
        width: 216px;
        text-align: right;
        padding-top: 32px;
        float: right;
        padding-right: 16px;
        .paramsSelect {
          margin-bottom: 24px;
          width: 200px;
        }

        .buttonNextStep{
          position: absolute;
          right:16px;
          bottom:16px;
        }

        .labelSelect{
          position: relative;
          text-align: left;
          font-size: 14px;
          color: var(--dark);
          justify-content: space-between;
          align-items: flex-end;
          display: flex;
        }
      }
      .avatarPreview {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat ;
        background-size: 100% ;
        background-position: -150px center;
        &.lisa_graceful-standing {
          background-image: url(@/assets/AIAvatar/LISA_GRACEFUL_STANDING.png);
        }
        &.lisa_casual-sitting {
          background-image: url(@/assets/AIAvatar/LISA_CASUAL_SITTING.png);
        }
        &.lisa_graceful-sitting {
          background-image: url(@/assets/AIAvatar/LISA_GRACEFUL_SITTING.png);
        }
        &.lisa_technical-standing {
          background-image: url(@/assets/AIAvatar/LISA_TECHNICAL_STANDING.png);
        }
        &.lisa_technical-sitting {
          background-image: url(@/assets/AIAvatar/LISA_TECHNICAL_SITTING.png);
        }
      }
    }
    .aiAvatarContentEdition{
      min-height: 314px;
      padding: 16px;
      background: var(--light);
      border-radius: 8px;
      .labelTypeYourText{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        text-align: left;
        color: var(--dark);
        .buttonBack{
          margin-right: 8px;
        }
        .iconInformation{
          margin-left: 8px;
          display: flex;
        }
      }
      .upgradeMessage{
        font-size: 14px;
        color: var(--light2);
      }
      .tipItem{
        position: absolute;
        right: 16px;
        bottom: 16px;
      }
      .contentTextAreaAI{
        margin-bottom: -16px;
      }

      .buttonValidateStep{
        position: absolute;
        bottom: 16px;
        right: 16px;
      }
    }
      .generateStep {
        min-height: 314px;
        padding: 16px;

        .progressGenerate {
          margin-bottom: 24px;
          font-size: 16px;
          min-width: 502px;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
  }
}

</style>
