import Vue from 'vue';
import axios from 'axios';
import {ChunkMedia, Media} from '@/store/media/mediaModel';
import {
    ADD_MEDIA, ADD_TO_MEDIA_CHUNK_QUEUE, AIAVATAR_GENERATE,
    CLEAR_MEDIA,
    CREATE_CHUNK_MEDIA,
    CREATE_MEDIA, DELETE_MEDIA,
    DOWNLOAD_MEDIA, GENERATE_AVATAR_IN_PROGRESS,
    MEDIA_UPDATE_MEDIAID, METADATA_MEDIA, REFRESH_MEDIA, REMOVE_MEDIA_FROM_QUEUE,
    SEND_CHUNK_MEDIA,
    SEND_VIRTUAL_MEDIA, SET_BLOB_TO_MEDIA, SET_MEDIA_LIST, VALIDATE_MEDIA
} from '@/store/media/mediaAction';
import {
    ATTACHED_MEDIA_TO_WEET,
    ATTACHED_TIMELINE_TO_WEET,
    AttachedMediaParams,
    AttachedTimeLineParams,
} from '@/store/myWeet/myWeetAction';
import {
    MEDIA_CHUNK_UPLOAD_START, MediaMetadata,
    MediaUploadChunkParams,
} from '@/store/upload/uploadAction';
import {
    MediaIDUpdateParams,
    SAVE_TIMELINE_IF_NEED,
    TIMELINE_UNSAVED,
    TIMELINE_UPDATE_MEDIAID
} from '@/store/timeLine/timeLineAction';
import store from '@/store';
import {MEDIA_DOWNLOAD_START, MediaDownloadParams} from '@/store/download/downloadAction';
import {UPDATE_MEDIA_ID_RECORDING_STATE} from '@/store/recordingState/recordStateAction';
import {log} from '@/utils/log';
import queue from 'queue';
import Queue from 'queue';
import {BACK_ENDPOINT} from '@/store/conf/api';
import {ScreenVirtualBackgroundEnum} from '@/enum/ScreenVirtualBackgroundEnum';
import {TRANSCRIPT_SAVE, UPDATE_MEDIAID_TRANSCRIPT} from '@/store/transcript/transcriptAction';
import {isMediaNeedRefresh} from "@/utils/util";
import {uniqBy} from "lodash";
import {AiavatarGenerationModel} from "@/store/AIAvatar/aiavatarGenerationModel";

export const LIST_OF_QUEUE = {};
export const MEDIA_API = BACK_ENDPOINT + '/media';
export const SCREEN_VB_API = MEDIA_API + '/virtualMedia';

export const AIAVATAR_API = MEDIA_API + '/aiAvatar';
export default {
    state: {
        medias: {},
        processingFileMetadata: null,
        avatarGenerateInProgress: false,
    },
    mutations: {
        [CREATE_MEDIA]: (state: any, media: Media) => {
            Vue.set(state.medias, media.mediaID, media);
        },
        [SET_MEDIA_LIST]: (state: any, medias: Media[]) => {
            state.medias = [];
            for (const media of medias) {
                Vue.set(state.medias, media.mediaID, media);
            }
        },
        [SET_BLOB_TO_MEDIA]: (state: any, {
            mediaId,
            blobURL,
            imageURL
        }: { mediaId: string, blobURL: string, imageURL: string }) => {
            let media = state.medias[mediaId];
            if (!media) {
                // so we search the record, maybe it's ask the old ID
                for (const mediaSearch of Object.values(state.medias) as Media[]) {
                    if (mediaSearch.recordID === mediaId) {
                        media = mediaSearch;
                        break;
                    }
                }
            }
            if (media) {
                // this method if to allow reactivity on media change
                media.blobURL = blobURL;
                media.imageBlobURL = imageURL;
                Vue.set(state.medias, mediaId, media);
                // Vue.set(media, 'blobURL', blobURL);
                // Vue.set(media, 'imageBlobURL', imageURL);
            }
        },
        [GENERATE_AVATAR_IN_PROGRESS]: (state: any, value: boolean) => {
            state.avatarGenerateInProgress = value;
        },
        [ADD_TO_MEDIA_CHUNK_QUEUE]: (state: any, {chunk, sender}: { chunk: ChunkMedia, sender: any }) => {
            // now we verify the queue
            let queueMedia: Queue = LIST_OF_QUEUE[chunk.mediaID];
            if (queueMedia === undefined) {
                queueMedia = queue({
                    concurrency: 1, // A LA QUEUE LEU LEU
                    autostart: true,
                });
            }
            LIST_OF_QUEUE[chunk.mediaID] = queueMedia;
            // put the senderInTheQueue
            queueMedia.push(sender);
        },
        [REMOVE_MEDIA_FROM_QUEUE]: (state: any, mediaID: string) => {
            const queueMedia: Queue = LIST_OF_QUEUE[mediaID];
            if (queueMedia !== undefined) {
                queueMedia.stop();
                queueMedia.end();
            }
        },
        [MEDIA_UPDATE_MEDIAID]: (state: any, params: MediaIDUpdateParams) => {
            // upadte the mediaID withh the backend mediaID
            // const media = state.medias[params.oldID] as Media;
            // keep the blob URL if there is a blob
            const mediaOld = state.medias[params.oldID];
            let blobURL;
            let imageBlobURL;
            let validate = true;
            if (mediaOld) {
                blobURL = mediaOld.blobURL;
                imageBlobURL = mediaOld.imageBlobURL;
                validate = mediaOld.validate;
            }
            Vue.delete(state.medias, params.oldID);
            const media = params.media;
            media.blobURL = blobURL;
            media.imageBlobURL = imageBlobURL;
            media.recordID = params.oldID;
            media.saved = true;
            media.validate = validate;
            Vue.set(state.medias, media.mediaID, media);
        },

        [VALIDATE_MEDIA]: (state: any, mediaID: string) => {
            const media = state.medias[mediaID];
            if (media) {
                media.validate = true;
                Vue.set(state.medias, media.mediaID, media);
            } else {
                log.warn('media not found:' + mediaID);
            }
        },
        [DELETE_MEDIA]: (state: any, mediaID: string) => {
            Vue.delete(state.medias, mediaID);
        },
        [CLEAR_MEDIA]: (state: any) => {
            state.medias = {};
        },
        [METADATA_MEDIA]: (state: any, params: MediaMetadata) => {
            // remove table
            state.processingFileMetadata = params;
        },
    },
    getters: {
        getMedias: (state: any): Media[] => {
            // bug : https://speacher.speach-qa.com/play/196866b4/blocking-issue-on-recorder-can-t-save-close-the-weet
            // Array converston send multiple time media... it's normaly impossible :-(
            // so filter by uniq mediaID...
            let listOfMedia = Object.values(state.medias);
            listOfMedia = uniqBy(listOfMedia, 'mediaID')
            return listOfMedia as Media[];
        },
        isAvatarGenerateInProgress: (state: any): boolean => {
            return state.avatarGenerateInProgress;
        },
        getMetadataProcessingFile: (state: any): MediaMetadata => {
            return state.processingFileMetadata;
        },
        getMediasForMediaId: (state: any) => (mediaId: string): Media => {
            return state.medias[mediaId];
        },
        isAllMediaSavedAndValidate: (state: any): boolean => {
            let saved = true;
            for (const media of Object.values(state.medias)) {
                if (media !== undefined && (!(media as Media).saved || !(media as Media).validate)) {
                    saved = false;
                    break;
                }
            }
            return saved;
        },
    },
    actions: {
        async [ADD_MEDIA]({
                              commit,
                              dispatch,
                              state,
                              getters
                          }: { commit: any, dispatch: any, state: any, getters: any },
                          media: Media) {
            commit(CREATE_MEDIA, media);
        },
        async [SET_BLOB_TO_MEDIA]({
                                      commit,
                                      dispatch,
                                      state,
                                      getters
                                  }: { commit: any, dispatch: any, state: any, getters: any },
                                  {
                                      mediaId,
                                      blobURL,
                                      imageURL
                                  }: { mediaId: string, blobURL: string, imageURL: string }) {
            log.debug('📷 UPDATE BLOB for ' + mediaId);
            commit(SET_BLOB_TO_MEDIA, {mediaId, blobURL, imageURL});
        },
        async [CREATE_CHUNK_MEDIA]({
                                       commit,
                                       dispatch,
                                       state,
                                       getters
                                   }: { commit: any, dispatch: any, state: any, getters: any },
                                   chunk: ChunkMedia) {
            log.debug('👌 Create Chunk ' + chunk.mediaID + ' part ' + chunk.partNumber + ' last ' + chunk.lastPart);
            // before record we create an ID
            // this id must be provide before the first chunk upload
            const sender = (): Promise<void> => {
                return new Promise((resolve, reject) => {
                    dispatch(SEND_CHUNK_MEDIA, chunk).then(() => {
                        resolve();
                    });
                });
            };
            commit(ADD_TO_MEDIA_CHUNK_QUEUE, {chunk, sender});
        },


        // launche by the Queue

        // 2 - Create the media in front Part (Media ID is temporary)
        // 3 - Upload the Media
        // 4 - Update Media Id with the real media ID
        // 5 - Update the timeLine media ID
        // 6 - Attache the media to the weet
        // 7 - If there is no other synchronisation, save the timeLine
        async [SEND_CHUNK_MEDIA]({
                                     commit,
                                     dispatch,
                                     state,
                                     getters
                                 }: { commit: any, dispatch: any, state: any, getters: any },
                                 chunk: ChunkMedia) {

            const chunkUploadParams = new MediaUploadChunkParams(chunk);

            await dispatch(MEDIA_CHUNK_UPLOAD_START, chunkUploadParams).then(
                async (params: MediaIDUpdateParams) => {
                    // if we have an mediaID the mediaA is finish to upload
                    // so we can proceed to the update
                    if (getters.getEditingWeetID === '' && !chunk.mediaCreatorMode) {
                        // so there is a huge problem here
                        throw Error('Impossible to upload a chunk file if there is no weet ID');
                    }
                    if (chunk.lastPart) {
                        log.debug('End of media Upload ' + chunk.mediaID + " " + new Date().getTime());
                        await dispatch(UPDATE_MEDIAID_TRANSCRIPT, params);
                        await commit(MEDIA_UPDATE_MEDIAID, params);
                        // we update the recorderStateToo
                        await dispatch(UPDATE_MEDIA_ID_RECORDING_STATE, params);
                        // 5 -  we wait the update of the timeLine
                        await dispatch(TIMELINE_UPDATE_MEDIAID, params);


                        // TODO externalize that
                        // if we the media is not on media creator only,
                        // so we attached the media to the timeline of the weet
                        if (!chunk.mediaCreatorMode) {

                            // 6 - attach Media
                            const attachedParams = new AttachedMediaParams(getters.getEditingWeetID, params.media.mediaID);
                            await dispatch(ATTACHED_MEDIA_TO_WEET, attachedParams);

                            dispatch(TRANSCRIPT_SAVE, {
                                mediaID: params.media.mediaID,
                                weetID: getters.getEditingWeetID
                            });
                            // now we launch the update Media
                            await dispatch(REFRESH_MEDIA, params.media);
                            // we can attached the timeline
                            await dispatch(SAVE_TIMELINE_IF_NEED);
                            return params.media.mediaID;
                        } else {
                            dispatch(TRANSCRIPT_SAVE, {mediaID: params.media.mediaID, weetID: chunk.weetID});
                        }
                    }
                },
            );
        },
        async [AIAVATAR_GENERATE]({
                                      commit,
                                      dispatch,
                                      state,
                                      getters
                                  }: { commit: any, dispatch: any, state: any, getters: any },
                                  {media, aiavatarParams}: { media: Media, aiavatarParams: AiavatarGenerationModel }) {

            if (getters.getEditingWeetID === '') {
                // so there is a huge problem here
                throw Error('Impossible to create aiavatar if there is no weet ID');
            }
            commit(CREATE_MEDIA, media);
            commit(GENERATE_AVATAR_IN_PROGRESS, true)
            const response = await axios(
                {
                    url: AIAVATAR_API,
                    method: 'POST',
                    data: aiavatarParams
                }).then(async (resp) => {

                const refreshMedia = resp.data;
                refreshMedia.saved = true;
                // now we update the mediaID
                const params = new MediaIDUpdateParams(media.mediaID, refreshMedia);

                await commit(MEDIA_UPDATE_MEDIAID, params);
                await dispatch(UPDATE_MEDIA_ID_RECORDING_STATE, params);

                await dispatch(TIMELINE_UPDATE_MEDIAID, params);


                const attachedParams = new AttachedMediaParams(getters.getEditingWeetID, params.media.mediaID);
                await dispatch(ATTACHED_MEDIA_TO_WEET, attachedParams);

                log.debug('Is All Media Saved = ' + getters.isAllMediaSavedAndValidate + ' and Validate');
                if (getters.isAllMediaSavedAndValidate && getters.timelineNeedToBeSaved) {
                    // after the media is really update we start the synchronisation of the timeline
                    const attachedTimeLineParams = new AttachedTimeLineParams(
                        getters.getEditingWeetID,
                        getters.getTimeEvent,
                        getters.getOffsetTimeEventUpdate
                    );
                    store.dispatch(ATTACHED_TIMELINE_TO_WEET, attachedTimeLineParams);
                }
                return refreshMedia;
            }).catch((err) => {
                commit(GENERATE_AVATAR_IN_PROGRESS, false)
                throw err;
            });
            return response;
        },
        async [SEND_VIRTUAL_MEDIA]({
                                       commit,
                                       dispatch,
                                       state,
                                       getters
                                   }: { commit: any, dispatch: any, state: any, getters: any },
                                   {media, type}: { media: Media, type: ScreenVirtualBackgroundEnum }) {

            if (getters.getEditingWeetID === '') {
                // so there is a huge problem here
                throw Error('Impossible to create virtualMedia if there is no weet ID');
            }
            commit(CREATE_MEDIA, media);

            const response = await axios(
                {
                    url: SCREEN_VB_API,
                    method: 'POST',
                    data: {
                        screenVirtualBackground: type
                    }
                }).then(async (resp) => {
                const refreshMedia = resp.data;
                refreshMedia.saved = true;
                // now we update the mediaID
                const params = new MediaIDUpdateParams(media.mediaID, refreshMedia);

                await commit(MEDIA_UPDATE_MEDIAID, params);
                await dispatch(UPDATE_MEDIA_ID_RECORDING_STATE, params);

                await dispatch(TIMELINE_UPDATE_MEDIAID, params);


                const attachedParams = new AttachedMediaParams(getters.getEditingWeetID, params.media.mediaID);
                await dispatch(ATTACHED_MEDIA_TO_WEET, attachedParams);

                log.debug('Is All Media Saved = ' + getters.isAllMediaSavedAndValidate + ' and Validate');
                if (getters.isAllMediaSavedAndValidate && getters.timelineNeedToBeSaved) {
                    // after the media is really update we start the synchronisation of the timeline
                    const attachedTimeLineParams = new AttachedTimeLineParams(
                        getters.getEditingWeetID,
                        getters.getTimeEvent,
                        getters.getOffsetTimeEventUpdate
                    );
                    store.dispatch(ATTACHED_TIMELINE_TO_WEET, attachedTimeLineParams);
                }
                return refreshMedia;
            }).catch((err) => {
                throw err;
            });

            return response;
        },
        async [DOWNLOAD_MEDIA]({
                                   commit,
                                   dispatch,
                                   state,
                                   getters
                               }: { commit: any, dispatch: any, state: any, getters: any },
                               media: Media) {
            const downloadMedia = new MediaDownloadParams(media);
            dispatch(MEDIA_DOWNLOAD_START, downloadMedia).then((blobUrl) => {
                // now we set the media (with blob url)
                media.blobURL = blobUrl;
                commit(CREATE_MEDIA, media);
            });
        },
        async [REFRESH_MEDIA]({
                                  commit,
                                  dispatch,
                                  state,
                                  getters
                              }: { commit: any, dispatch: any, state: any, getters: any },
                              media: Media) {
            log.debug('START REFRESH MEDIA ' + media.mediaID);
            return await axios(
                {
                    url: MEDIA_API + '/' + media.mediaID,
                    method: 'GET',
                }).then((resp) => {
                const refreshMedia = resp.data;
                refreshMedia.saved = true;
                refreshMedia.blobURL = media.blobURL;
                refreshMedia.imageBlobURL = media.imageBlobURL;
                commit(CREATE_MEDIA, refreshMedia);
                dispatch(SAVE_TIMELINE_IF_NEED);
                log.debug('END REFRESH MEDIA ' + media.mediaID);
                if (isMediaNeedRefresh(refreshMedia)) {
                    setTimeout(() => {
                        dispatch(REFRESH_MEDIA, refreshMedia);
                    }, 2500);
                }
                return refreshMedia;
            }).catch((err) => {
                throw err;
            });
        },
        [CLEAR_MEDIA]({commit, dispatch, state}: { commit: any, dispatch: any, state: any }) {
            commit(CLEAR_MEDIA);
        },
        [GENERATE_AVATAR_IN_PROGRESS]({commit, dispatch, state}: { commit: any, dispatch: any, state: any }, value: boolean) {
            commit(GENERATE_AVATAR_IN_PROGRESS, value);
        },
        async [VALIDATE_MEDIA]({
                                   commit,
                                   dispatch,
                                   state,
                                   getters
                               }: { commit: any, dispatch: any, state: any, getters: any },
                               mediaID: string) {
            if (mediaID) {
                commit(VALIDATE_MEDIA, mediaID);
                if (getters.isAllMediaSavedAndValidate && getters.timelineNeedToBeSaved) {
                    // after the media is really update we start the synchronisation of the timeline
                    const attachedTimeLineParams = new AttachedTimeLineParams(
                        getters.getEditingWeetID,
                        getters.getTimeEvent,
                        getters.getOffsetTimeEventUpdate
                    );
                    log.debug('attached timeline after validation');
                    store.dispatch(ATTACHED_TIMELINE_TO_WEET, attachedTimeLineParams);
                }
            }
        },
        [DELETE_MEDIA]({commit, dispatch, state}: { commit: any, dispatch: any, state: any }, mediaID: string) {
            commit(DELETE_MEDIA, mediaID);
        },
        [REMOVE_MEDIA_FROM_QUEUE]({
                                      commit,
                                      dispatch,
                                      state
                                  }: { commit: any, dispatch: any, state: any }, mediaID: string) {
            commit(REMOVE_MEDIA_FROM_QUEUE, mediaID);
        },
        async [METADATA_MEDIA]({
                                   commit,
                                   dispatch,
                                   state,
                                   getters
                               }: { commit: any, dispatch: any, state: any, getters: any },
                               mediaID: string) {
            if (mediaID) {
                return await axios(
                    {
                        url: MEDIA_API + '/metadata/' + mediaID,
                        method: 'GET',
                    }).then((resp) => {
                    commit(METADATA_MEDIA, resp.data);
                    return resp.data;
                }).catch(async (err) => {
                    return err;
                });
            }
        }
    },
};
