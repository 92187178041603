<template>
  <div class="controlWeetPlayerContainer">
    <div class="bottomLinePlayer">
      <div class="playContainer" @click="onClickPlay">
        <w-icon data-test="weetPauseButton" icon="pause" class="logoPlay" v-show="play" color="white"/>
        <w-icon data-test="weetPlayButton" icon="play" class="logoPlay" v-show="!play && !isEnd" color="white"/>
        <w-icon data-test="weetRePlayButton" icon="replay" lass="logoPlay" v-show="!play && isEnd " color="white"/>
      </div>
      <div class="volumeContainer" @mouseover="changeVolumeHover=true" @mouseout="changeVolumeHover=false">
        <div @click="muteUnmuteVolume">
          <w-icon :icon="volumeIcon" class="volumeIcon"/>
        </div>
        <b-slider class="volumeSlider" :tooltip="false" v-show="changeVolumeHover" :type="'is-primary'"
                  :max="100" @input="changeVolume" :value="volume" bigger-slider-focus
                  rounded size="is-small"/>
      </div>
      <div class="timeContainer" data-test="weetPlayerTimer">
        {{ timer }} / {{ durationTimer }}
      </div>
      <div class="spacer"></div>
      <div class="reduceContainer" @click="toggleMinimizeStickers" v-if="!isMinimisePoisitionLayout">
        <w-icon :icon="isStickerMinimize?'account-outline':'account'"
                size="small"/>
      </div>
      <div class="subtitleContainer" v-if="isSubtitleAivailable">
        <b-dropdown aria-role="list" :scrollable="true" max-height="300" position="is-top-left">
          <template #trigger="{ active }">
            <w-icon class="iconTrigger" :icon="!subtitleLang?'closed-caption-outline':'closed-caption'"/>
          </template>

          <b-dropdown-item aria-role="listitem" @click="activateSubtitle(sub.lang)" v-for="sub in weet.subtitles"
                           :key="sub.subtitleID"
                           class="subtitleLine">
            <span class="subtitleLang">{{ sub.label }}
              </span>
            <w-icon class="check" icon="check" size="small" color="primary"
                    :class="{selected:sub.lang===subtitleLang}"/>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" class="subtitleLine" @click="activateSubtitle()">
            <span class="subtitleLang">{{ $t('player.subtitle.no') }}
            </span>
            <w-icon class="check" icon="check" color="primary" size="small" :class="{selected:!subtitleLang}"/>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="speedContainer" @click="switchSpeedMode">
        <div class="speed">{{ speedMode }}</div>
      </div>
      <div class="fullscreenContainer" @click="toggleFullscreen">
        <w-icon v-if="!isFullScreen" icon="fullscreen"/>
        <w-icon v-else icon="fullscreen-exit"/>
      </div>
    </div>
    <div class="topLine">
      <div class="sliderContainer" data-test="weetPlayerTimeline">
        <b-slider :custom-formatter="val => getHumanTime(val)"
                  :max="duration+100" type="is-primary"
                  rounded size="is-small" @change="changeCurrentTime" :value="currentTime">
          <template v-for="section in sectionOfWeet">
            <b-slider-tick :value="section.time" class="tickClass" :key="section.key">
              <!-- TODO Notification Update? -->
              <!--              <div v-if="section.type==='STEP'" class="avatarSection" data-test="weetPlayerSegment"-->
              <!--                   @click.stop="changeCurrentSection(section)">-->
              <!--                <w-tool-tip color="light" size="small" position="top" :label="section.tooltip">-->
              <!--                  <user-avatar class="avatarTick" :type="section.thumbType"-->
              <!--                               :user-avatar="section.image"-->
              <!--                               size="tiny"/>-->
              <!--                  <WeetControlPastille class="pastille" :section="section" :weet="weet"/>-->
              <!--                </w-tool-tip>-->
              <!--              </div>-->
              <div v-if="section.type==='CHAPTER_TITLE'" class="chapterSection" data-test="weetPlayerSegment"
                   @click.stop="changeCurrentSection(section)">
                <w-tool-tip color="light" size="small" position="top" :label="section.tooltip">
                  <div class="tickChapter"></div>
                </w-tool-tip>
              </div>
              <div v-if="section.type==='WEBCAM' ||
                section.type === 'SCREEN' ||
                section.type=== 'TEXT'" class="commentSection"
                   @click.stop="changeCurrentSection(section)">
                <w-tool-tip color="light" class="tooltipAvatar" size="small" position="top" :label="section.tooltip">
                  <user-avatar class="avatarTick" :type="section.thumbType"
                               :user-avatar="section.image"
                               size="mini"/>
                  <WeetControlPastille class="pastille" :small="true" :section="section" :weet="weet"/>
                </w-tool-tip>
              </div>
              <div v-if="section.type==='EMOTE'" class="emoteSection"
                   @click.stop="changeCurrentSection(section)">
                <w-tool-tip color="light" class="tooltipAvatar" size="small" position="top" :label="section.tooltip">
                  <user-avatar class="avatarTick" :type="section.thumbType"
                               :user-avatar="section.image"
                               size="mini"/>
                  <WeetControlPastille class="pastille" :small="true" :section="section" :weet="weet"/>
                </w-tool-tip>
              </div>
            </b-slider-tick>
          </template>
        </b-slider>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {
  MAIN_MEDIA_BUFFERING,
  PLAYER_PLAYING,
  SECOND_MEDIA_BUFFERING,
  SWITCH_MINIMISE_STIKERS,
  SWITCH_PLAYER_FULLSCREEN,
  TIMER_PLAYER,
} from '@/store/timeLine/timeLineAction';
import prettyMilliseconds from 'pretty-ms';
import WIcon from '@/components/wrapper/w-icon.vue';
import {getTabOfColor} from '@/utils/util';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {Weet} from '@/store/weet/weetModel';
import {canEditThisWeet, getWeetDuration, getWeetSection} from '@/utils/weetUtil';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {SectionEventDescriptor, TimeEvent} from '@/store/timeLine/timeEvent';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {READ_NOTIFICATIONS} from '@/store/myNotifications/myNotificationsAction';
import {COMMENT_TO_REPLY} from '@/store/comment/commentAction';
import sortBy from 'lodash/sortBy';
import {
  CHANGE_PLAYER_SPEED,
  CHANGE_PLAYER_VOLUME,
  SUBTITLE_LANG
} from '@/store/persistedInformation/persistAction';
import {AccurateTimer} from '@/utils/accurateTimer';
import WeetControlPastille from '@/components/play/player/weetPlayerSubComponent/WeetControlPastille.vue';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {getFirstMediaPlayEvent} from '@/utils/timeLineUtils';
import {SPEED_SELECTION} from '@/utils/playerUtil';

@Component({
  components: {WeetControlPastille, UserAvatar, WButton, WToolTip, WIcon},
})
export default class ControlPlayer extends Vue {

  @Prop({default: ''})
  private fullScreenElement!: string;

  @Prop()
  private weet!: Weet;

  @Prop()
  private timeStart!: number;

  @Prop()
  private waiting!: boolean;

  private currentTime: number = 0;

  private pauseOnEndTimeOut: number = 0;

  private accurateTimer: AccurateTimer = new AccurateTimer();
  private timeStep: number = 200;
  private startAccurateTimer: number = new Date().getTime();


  private changeVolumeHover: boolean = false;


  public mounted() {
    store.dispatch(PLAYER_PLAYING, false);
    store.dispatch(MAIN_MEDIA_BUFFERING, 0);
    store.dispatch(SECOND_MEDIA_BUFFERING, 0);
    store.dispatch(SWITCH_MINIMISE_STIKERS, false);
  }

  public seek(time) {
    if (this.weet.hidden) {
      return;
    }
    // we seek after the tick (release the grab of the timeline)
    setTimeout(() => {
      this.currentTime = time;
    }, 10);
  }

  get isEditable(): boolean {
    if (this.isAuthenticated) {
      return canEditThisWeet(this.weet);
    } else {
      // we keep the buton to redirect to login page
      return true;
    }
  }

  get isSubtitleAivailable(): boolean {
    return this.weet.subtitles.length > 0;
  }


  get isAuthenticated(): boolean {
    return store.getters.isAuthenticated;
  }

  get isMinimisePoisitionLayout(): boolean {
    const timeEvent = store.getters.getCurrentPlayerTimeEvent as TimeEvent;
    return timeEvent &&
        (timeEvent.positionLayout === StickerPosition.NONE
            || timeEvent.secondMedia.mediaID === '');
  }

  get sectionOfWeet(): SectionEventDescriptor[] {
    const sections: SectionEventDescriptor[] = getWeetSection(this.weet, true);

    // sort by comment Before
    return sortBy(sections, ['commentID']);
  }

  get isEnd() {
    if (this.currentTime + 300 >= this.duration) {
      return true;
    } else {
      return false;
    }
  }


  get play(): boolean {
    return store.getters.isPlaying;
  }

  get isFullScreen(): boolean {
    return store.getters.isFullscreenMode;
  }

  get isStickerMinimize(): boolean {
    return store.getters.isMinimizeSticker;
  }

  get speedMode(): number {
    return store.getters.speedMode;
  }

  get duration(): number {
    return getWeetDuration(this.weet);
  }

  get durationTimer(): string {
    return prettyMilliseconds(this.duration, {colonNotation: true, secondsDecimalDigits: 0});
  }

  get timer() {
    const ms = this.currentTime;
    if (ms < 1000) {
      return '0:00';
    } else {
      return prettyMilliseconds(ms, {colonNotation: true, secondsDecimalDigits: 0});
    }
  }

  get sliderColor(): string {
    return getTabOfColor()[0];
  }


  private switchSpeedMode() {
    const speed = this.speedMode;
    const speedTab: number[] = SPEED_SELECTION;
    let newSpeed = speedTab[0];
    for (const speedAvailable of speedTab) {
      if (speedAvailable > speed) {
        newSpeed = speedAvailable;
        break;
      }
    }

    store.dispatch(CHANGE_PLAYER_SPEED, newSpeed);
    setActionEvent(ActionEvent.player_control_speed, {
      player_speed: newSpeed,
      weetID: this.weet.weetID
    });

  }

  get subtitleLang(): string {
    return store.getters.getSubtitleLang;
  }

  private activateSubtitle(value: string = "") {
    store.dispatch(SUBTITLE_LANG, value);
  }

  private toggleFullscreen() {
    store.dispatch(SWITCH_PLAYER_FULLSCREEN, !this.isFullScreen);
    setActionEvent(ActionEvent.player_control_fullscreen, {
      player_fullscreen: this.isFullScreen,
      weetID: this.weet.weetID
    });
  }

  private getHumanTime(val: number): string {
    if (val < 1000) {
      return '0:00';
    } else {
      return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: 0});
    }
  }

  get volumeIcon(): string {
    if (this.volume > 75) {
      return 'volume-high';
    }
    if (this.volume > 40) {
      return 'volume-medium';
    }
    if (this.volume < 40 && this.volume > 0) {
      return 'volume-low';
    }
    return 'volume-off';
  }

  private muteUnmuteVolume() {
    if (this.volume <= 0) {
      store.dispatch(CHANGE_PLAYER_VOLUME, 100);
    } else {
      store.dispatch(CHANGE_PLAYER_VOLUME, 0);
    }
  }

  get volume(): number {
    return store.getters.volume;
  }

  private onClickPlay() {
    if (this.weet.hidden) {
      return;
    }
    if (this.isEnd) {
      this.$emit('replay', 0);
    }else{
      this.$emit('playPlayer');
    }

  }

  @Watch('currentTime')
  private onCurrentTimeChange() {
    if (!this.play) {
      return;
    }
    const notificationsToRead: Notification[] = [];
    for (const notification of store.getters.getWeetTypeUnreadTimelineNotification) {
      if (notification.weetID === this.weet.weetID && !notification.replyToCommentID && Math.round(notification.time / 1000) === Math.round(this.currentTime / 1000)) {
        notificationsToRead.push(notification);
      }
    }
    if (notificationsToRead.length > 0) {
      store.dispatch(READ_NOTIFICATIONS, notificationsToRead);
    }
  }

  @Watch('play')
  private togglePlay() {
    if (this.play) {
      // reset counter if at the end
      if (this.currentTime >= this.duration) {
        store.dispatch(TIMER_PLAYER, 1);
      }
      this.startAccurateTimer = new Date().getTime();
      this.accurateTimer.launch(this.incrementTimer, this.timeStep);
      this.$emit('play');
    } else {
      this.accurateTimer.stop();
    }
  }


  private changeCurrentTime(val) {
    // console.log('Control : changeCurrentTime : ' + val);
    this.$emit('seek', val);
    // stop the end of video programmed
    clearTimeout(this.pauseOnEndTimeOut);
    store.dispatch(TIMER_PLAYER, val);
    setActionEvent(ActionEvent.player_control_seek, {
      player_seek: val,
      weetID: this.weet.weetID
    });
  }

  private changeCurrentSection(val) {
    this.changeCurrentTime(val.time);
    store.dispatch(TIMER_PLAYER, val.time).then(() => {
      this.currentTime = val.time;
      if (val.commentID) {
        store.dispatch(COMMENT_TO_REPLY, val.commentID);
        store.dispatch(PLAYER_PLAYING, false);
      }
    });
  }

  private toggleMinimizeStickers() {
    store.dispatch(SWITCH_MINIMISE_STIKERS, !this.isStickerMinimize);
    setActionEvent(ActionEvent.player_control_hide_cam, {
      player_webcam_hide: this.isStickerMinimize,
      weetID: this.weet.weetID
    });
  }

  private changeVolume(val) {
    store.dispatch(CHANGE_PLAYER_VOLUME, val);
    setActionEvent(ActionEvent.player_control_volume, {
      player_volume: val,
      weetID: this.weet.weetID
    });
  }

  private incrementTimer() {
    if (this.duration > 100 && this.currentTime + 300 >= this.duration) {
      this.pauseOnEndWeet(this.currentTime);
    }

    if (this.waiting) {
      return;
    }
    let step = this.accurateTimer.preciseStep;
    if (step < 10) {
      step = this.timeStep;
    }
    let currentTime = this.currentTime;
    currentTime += Math.floor(step * this.speedMode);
    this.currentTime = currentTime;
    this.$emit('timerPlayer', currentTime);


    store.dispatch(TIMER_PLAYER, currentTime);

  }

  private pauseOnEndWeet(currentTime: number) {

    clearTimeout(this.pauseOnEndTimeOut);
    // stop 100ms before
    // @ts-ignore
    this.pauseOnEndTimeOut = setTimeout(() => {
      store.dispatch(PLAYER_PLAYING, false);
      this.$emit('ended');
    }, ((this.duration - currentTime) * this.speedMode) - 100);

  }

  private beforeDestroy() {
    this.accurateTimer.stop();
    store.dispatch(PLAYER_PLAYING, false);
    store.dispatch(TIMER_PLAYER, 0);

  }


}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";

$bottom: 0px;

.controlWeetPlayerContainer {
  background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6));
  width: 100%;
  height: 100%;
  border-radius: 8px;

  .bottomLinePlayer {
    position: absolute;
    width: 100%;
    display: flex;
    bottom: 8px;
    padding-left: 8px;
    padding-bottom: 4px;
    max-height: 24px;
    // ICON play
    .playContainer {
      cursor: pointer;
      display: flex;
      text-align: center;
      align-items: center;
      margin-right: 16px;


      .logoPlay {
        transition: transform ease-in-out 0.1s;

        &:hover {
          transform: scale(1.2);;
        }
      }
    }

    // Volume
    .volumeContainer {
      display: flex;
      cursor: pointer;
      color: white;
      font-size: 12px;
      align-items: center;
      margin-right: 8px;
      margin-top: 3px;

      .volumeIcon {
        margin-right: 8px;
      }

      .volumeSlider {
        width: 64px;
        margin-right: 8px;
      }
    }

    // time
    .timeContainer {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: #FFF;
    }

    .spacer {
      flex: 1;
    }

    .speedContainer {
      display: flex;
      align-items: center;
      margin-right: 12px; // put 12 instead of 16 because the fullscreen icon has a lot of emty space
      cursor: pointer;
      color: var(--dark);
      font-size: 10px;
      font-weight: 900;
      transition: transform ease-in-out 0.1s;
      padding-top: 2px;

      &:hover {
        transform: scale(1.2);;
      }

      .speed {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        width: 20px;
        line-height: 10px;
        height: 16px;
        margin-top: -2px;
        background: var(--white);
      }
    }


    .reduceContainer {
      display: flex;

      align-items: center;
      margin-right: 16px;
      cursor: pointer;
      font-weight: 900;
      transition: all ease-in-out 0.1s;
      color: rgba(255, 255, 255, 0.5);
      color: white;

      &:hover {
        transform: scale(1.2);
      }
    }

    .subtitleContainer {
      display: flex;

      align-items: center;
      margin-right: 16px;
      cursor: pointer;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.5);
      color: white;
      margin-top: 3px;

      .iconTrigger {
        transition: all ease-in-out 0.1s;

        &:hover {
          transform: scale(1.2);
        }
      }

      .subtitleLine {
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-content: center;

        .subtitleLang {
          flex: 1;
        }

        .check {
          display: none;
          align-items: center;
          justify-content: center;

          &.selected {
            display: flex;
          }
        }
      }
    }

    .fullscreenContainer {
      display: flex;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;
      color: #FFFFFF;
      transition: all ease-in-out 0.1s;

      &:hover {
        transform: scale(1.2);;
      }
    }
  }

  .topLine {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 40px;
    align-items: center;

    .sliderContainer {
      margin-left: 16px;
      margin-right: 16px;
      width: 100%;

      .tickClass {
        animation-duration: 0.2s;
        background: var(--light2);

        .avatarSection {
          position: absolute;
          margin-top: -36px;
          margin-left: 0px;
          z-index: -1;

          .avatarTick {
            transition: transform 0.2s ease;
          }

          &:hover {
            .avatarTick {
              transform: scale(1.2);
            }
          }
        }

        .chapterSection {
          margin-top: -6px;

          .tickChapter {
            width: 8px;
            height: 8px;
            background: var(--primary);
            border: solid 1px white;
            border-radius: 100%;

          }
        }

        .commentSection {
          margin-top: 4px;
          transition: all 0.2s ease;
          width: 16px;

          .avatarTick {
            transition: transform 0.2s ease;
          }

          &:hover {
            .avatarTick {

              transform: scale(1.5);
            }

            .pastille {
              transform: scale(1.5);
            }
          }

        }

        .emoteSection {
          margin-top: 4px;
          transition: all 0.2s ease;
          width: 16px;

          .avatarTick {
            transition: transform 0.2s ease;
          }

          &:hover {
            .avatarTick {

              transform: scale(1.5);
            }

            .pastille {
              transform: scale(1.5);
            }
          }
        }

      }
    }

  }


  .plusSection {
    position: absolute;
    margin-top: -40px;
    margin-left: -16px;

    &:hover {
      transform: scale(1.2);
    }
  }

}
</style>
