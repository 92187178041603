<template>
  <div class="weetPlayerCommentPreview" :class="isFullscreen?'fullScreen':''">
    <Transition name="slideLeft">
      <div class="commentToPreview" :class="isFullscreen?'fullScreen':''" v-show="commentToPreview!==null">
        <div class="contentBox" v-if="commentToPreview!==null">
          <div class="userCommentBox">
            <UserAvatar class="userAvatar" :user-avatar="avatarUrl" type="sticker" size="tiny"/>
            <Transition name="zoom">
              <div class="pastille" v-show="isNotify"></div>
            </Transition>
            <div class="nameBox">
              {{ commentToPreview.user.firstName }}
            </div>
            <div class="dotseparator">{{ $t('generic.dot2') }}</div>
            <w-tool-tip class="dateBox" size="small" position="top" :label="toolTipDateCreation"> {{ dateOfCreation }}
            </w-tool-tip>
          </div>
          <div class="textComment" v-if="isText" v-html="textofComment" data-hj-suppress>
          </div>
          <div class="videoContent" v-if="isScreen">
            <CommentScreenPlayer v-if="commentToPreview.media.status!=='WAITING'"
                                 :comment="commentToPreview"/>
            <div v-else>{{ $t('commentPanelView.commentView.waiting') }}</div>
          </div>
          <div class="videoContent" v-if="isWebcam">
            <CommentStickerPlayer v-if="commentToPreview.media.status!=='WAITING'"
                                  :comment="commentToPreview"/>
            <div v-else>{{ $t('commentPanelView.commentView.waiting') }}</div>
          </div>
          <!-- BOTTOM LINE -->
          <div class="buttonsLine">
            <a class="linkComment" href="#" @click="viewTopic"
               v-if="commentToPreview.numberOfReplies>0">{{
                $tc('player.comment.button.show', commentToPreview.numberOfReplies, {
                  number:
                  commentToPreview.numberOfReplies
                })
              }}</a>
            <a class="linkComment" href="#" @click="replyComment">{{ $t('player.comment.button.reply') }}</a>
          </div>
        </div>

        <!-- BUTTON CLOSE -->
        <div class="closeButtonBox">
          <w-button icon="close" size="small" color="text" @click.stop="closePreview"></w-button>
        </div>

      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {getDateAgo, getLocalTime, getUserAvatar, htmliFy} from '@/utils/util';
import UserAvatar from '@/components/user/UserAvatar.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {COMMENT_TO_REPLY, OPEN_PANEL_REPLY} from '@/store/comment/commentAction';
import {commentForSecond} from '@/utils/commentUtil';
import {Howl} from 'howler';
import {CommentTypeEnum} from '@/store/comment/commentTypeEnum';
import {CommentEvent} from '@/store/comment/commentModel';
import CommentViewComponent from '@/components/comment/CommentView.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import {PLAYER_PLAYING} from '@/store/timeLine/timeLineAction';
import {READ_NOTIFICATIONS} from '@/store/myNotifications/myNotificationsAction';
import CommentScreenPlayer from '@/components/play/comment/CommentScreenPlayer.vue';
import CommentStickerPlayer from '@/components/play/comment/CommentStickerPlayer.vue';

@Component({
  components: {CommentStickerPlayer, CommentScreenPlayer, CommentViewComponent, WToolTip, WButton, UserAvatar},
})
export default class WeetPlayerCommentPreview extends Vue {
  private pop = new Howl({
    src: ['/sound/pop.mp3'],
    volume: 0.50,
  });

  private commentToPreview: CommentEvent | null = null;
  private durationComment: number = 5000;

  @Prop({default: 0})
  private timePlayer!: number;

  get isNotify(): boolean {
    return this.notifications.length > 0;
  }

  get notifications(): Notification[] {
    if (this.commentToPreview == null) {
      return [];
    }
    const notifications: Notification[] = [];
    for (const notification of store.getters.getUnreadNotification) {
      if (notification.commentID === this.commentToPreview.commentID) {
        notifications.push(notification);
      }
    }
    return notifications;
  }

  get isFullscreen(): boolean {
    return store.getters.isFullscreenMode;
  }

  get isPlay(): boolean {
    return store.getters.isPlaying;
  }

  private readNotification() {
    store.dispatch(READ_NOTIFICATIONS, this.notifications);
  }

  get newCreatedComment(): CommentEvent {

    return store.getters.getLastCreatedEvent;
  }

  @Watch('newCreatedComment')
  private onNewComment() {
    if (this.newCreatedComment.type !== CommentTypeEnum.EMOTE) {
      this.showComment(this.newCreatedComment);
    }
  }

  private showComment(comment: CommentEvent) {
    if (this.isPlay) {
      if (this.commentToPreview && this.commentToPreview.commentID
          === comment.commentID) {
        return;
      }
      this.commentToPreview = comment;
      this.pop.play();
      if (this.isNotify) {
        setTimeout(this.readNotification, 1500);
      }
    }
  }


  get isText(): boolean {
    return this.commentToPreview?.type === CommentTypeEnum.TEXT;
  }

  get isScreen(): boolean {
    return this.commentToPreview?.type === CommentTypeEnum.SCREEN;
  }

  get isWebcam(): boolean {
    return this.commentToPreview?.type === CommentTypeEnum.WEBCAM;
  }

  get toolTipDateCreation(): string {
    if (this.commentToPreview === null) {
      return '';
    }
    return getLocalTime(this.commentToPreview.created);
  }

  get dateOfCreation(): string {
    if (this.commentToPreview === null) {
      return '';
    }
    return ' ' + getDateAgo(this.commentToPreview.created);
  }


  get textofComment(): string {
    if (this.commentToPreview !== null && this.commentToPreview.type === CommentTypeEnum.TEXT) {
      return htmliFy(this.commentToPreview.text as string);
    } else {
      return '';
    }
  }

  get avatarUrl(): string {
    return getUserAvatar(this.commentToPreview?.user, 128);
  }


  private closePreview() {
    this.commentToPreview = null;
  }


  private viewTopic() {
    if (this.commentToPreview === null) {
      return;
    }
    setActionEvent(ActionEvent.comment_reply_button);
    store.dispatch(PLAYER_PLAYING, false);
    store.dispatch(COMMENT_TO_REPLY, this.commentToPreview.commentID);
    store.dispatch(OPEN_PANEL_REPLY, '');
  }

  private replyComment() {
    if (this.commentToPreview === null) {
      return;
    }
    setActionEvent(ActionEvent.comment_reply_button);
    store.dispatch(PLAYER_PLAYING, false);
    store.dispatch(COMMENT_TO_REPLY, this.commentToPreview.commentID);
    store.dispatch(OPEN_PANEL_REPLY, this.commentToPreview.commentID);
    this.closePreview();
  }


  @Watch('timePlayer')
  private onTimechange() {

    // clean commentToPreview if need;
    if (this.commentToPreview
        && (this.commentToPreview.time + this.durationComment < this.timePlayer
            || this.timePlayer < this.commentToPreview.time)) {
      this.commentToPreview = null;
    }
    let timeToProcess = this.timePlayer;
    if (this.timePlayer > 1000) {
      // we compute one second before, to take the right event
      timeToProcess = timeToProcess - 1000;
    }
    // one time by second
    // we dont use the debounce car it can be provoqued error with seek or speed change
    if (timeToProcess % 1000 <= 250) {
      const commentToThisTime = commentForSecond(timeToProcess);
      // set the commentToPreview if need
      if (commentToThisTime.length > 0) {
        const commentToProcess = commentToThisTime[0];
        if (commentToProcess.type !== CommentTypeEnum.EMOTE) {
          setTimeout(() => {
            this.showComment(commentToProcess);
          }, commentToProcess.time - this.timePlayer);
        }
      }

    }
  }

}
</script>

<style scoped lang="scss">
@import "@/scss/shadows.scss";
@import "@/scss/animation.scss";

.weetPlayerCommentPreview {
  text-align: right;
  position: absolute;
  top: 0;
  left: 16px;
  min-width: 260px;
  z-index: 12;

  &.fullScreen {
    transform: scale(1.2);
  }

  .commentToPreview {
    position: relative;
    display: inline-block;
    background: #FFF;
    box-sizing: border-box;
    margin-top: 16px;
    opacity: 1;
    min-height: 100%;
    min-width: 260px;
    max-width: 340px;
    padding: 8px;
    text-align: left;
    border-radius: 8px;
    animation-duration: 0.2s;
    word-break: break-word;
    transition: transform 0.5s ease-in-out;
    @extend .shadow1;

    .pastille {
      top: 4px;
      left: 24px;
      animation-duration: 1s;
      animation-delay: 1s;
      position: absolute;
      width: 12px;
      height: 12px;
      border: solid 2px #FFF;
      border-radius: 12px;
      background: var(--primary);
      @extend .shadow1;
    }

    .videoContent {
      cursor: pointer;
      position: relative;
      max-height: 160px;
      width: 100%;
      height: 160px;
    }

    .contentBox {
      flex: 1;
      font-size: 14px;

      .userCommentBox {
        display: flex;
        margin-bottom: 8px;
        align-items: center;

        .userAvatar {
          margin-right: 8px;
        }

        .nameBox {
          color: var(--primary);
        }

        .dotseparator {
          margin-right: 4px;
          margin-left: 4px;
        }

        .dateBox {
          color: var(--light2);
          font-size: 12px;
        }
      }

      .buttonsLine {
        text-align: right;
        padding-right: 16px;
        margin-top: 8px;

        .linkComment {
          margin-left: 16px;
        }
      }
    }

    .closeButtonBox {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    &.fullScreen {
      top: 32px;
      left: 64px;
    }
  }
}

</style>
