// SHOW POPUP
export const SHOW_POPUP_CREATION = 'SHOW_POPUP_CREATION';

// STATE RECORD
export const START_RECORDING = 'START_RECORDING';
export const PAUSE_RECORDING = 'PAUSE_RECORDING';
export const STOP_RECORDING = 'STOP_RECORDING';

export const RECORD_WEBCAM_AIVAILABLE = 'RECORD_WEBCAM_AIVAILABLE';

// STATE LAYOUT
export const RECORD_STICKER_FULLSCREEN = 'RECORD_STICKER_FULLSCREEN';
export const RECORD_STICKER_POSITION = 'RECORD_STICKER_POSITION';
export const RECORD_STICKER_FILTER = 'RECORD_STICKER_FILTER';
export const RECORD_STICKER_FACE_TRACK = 'RECORD_STICKER_FACE_TRACK';
export const RECORD_STICKER_VIRTUAL_BACKGROUND = 'RECORD_STICKER_VIRTUAL_BACKGROUND';
export const RECORD_STICKER_INTEGRATION = 'RECORD_STICKER_INTEGRATION';
export const RECORD_MAIN_VIRTUAL_BACKGROUND = 'RECORD_MAIN_VIRTUAL_BACKGROUND';
export const RECORD_STICKER_MUTE = 'RECORD_STICKER_MUTE';
export const RECORD_STICKER_VIDEO_MUTE = 'RECORD_STICKER_VIDEO_MUTE';

//
export const GENERATE_STICKERS_MEDIAID = 'GENERATE_STICKERS_MEDIAID';
export const GENERATE_MAIN_MEDIAID = 'GENERATE_MAIN_MEDIAID';
export const CLEAR_MAIN_MEDIAID = 'CLEAR_MAIN_MEDIAID';
export const CLEAR_STICKER_MEDIAID = 'CLEAR_STICKER_MEDIAID';
export const SET_STICKERS_TIME_RECORDING = 'SET_STICKERS_TIME_RECORDING';
export const SET_MAIN_TIME_RECORDING = 'SET_MAIN_TIME_RECORDING';
export const UPDATE_MEDIA_ID_RECORDING_STATE = 'UPDATE_MEDIA_ID_RECORDING_STATE';
export const UPDATE_MEDIA_CONTROL_CHANGE = 'UPDATE_MEDIA_CONTROL_CHANGE';
export const UPDATE_MAIN_RUNNING_STATE = 'UPDATE_MAIN_RUNNING_STATE';
export const UPDATE_STICKERS_RUNNING_STATE = 'UPDATE_STICKERS_RUNNING_STATE';
export const SET_CURRENT_EDITING_WEET = 'SET_CURRENT_EDITING_WEET';
export const SET_SCREEN_SURFACE = 'SET_SCREEN_SURFACE';

export const SET_CHANNELS_FOR_EDITING = 'SET_TAGS_FOR_EDITING';


// UTIL
export const RECORD_MAIN_VIDEO_TYPE = 'RECORD_MAIN_VIDEO_TYPE';
export const RECORD_STICKER_VIDEO_TYPE = 'RECORD_STICKER_VIDEO_TYPE';
export const CREATE_KEY_FRAME = 'CREATE_KEY_FRAME';
export const NEW_SOUND_DETECT = 'NEW_SOUND_DETECT';
export const OPEN_EXTENTION = 'OPEN_EXTENTION';
export const IS_INSTALLED = 'IS_INSTALLED';

// External tools integration
export const PREPOPULATE_WEET_TITLE = 'PREPOPULATE_WEET_TITLE';
export const PREPOPULATE_SHARED_EMAILS = 'PREPOPULATE_SHARED_EMAILS';

// reply to the extention
export const PING_RECORD = 'PING_RECORD';

// ANNOTATION / INTERACTION
export const ANNOTATION_INTERACTION_SAVE_IN_PROGRESS='ANNOTATION_INTERACTION_SAVE_IN_PROGRESS';

export const CHANGE_STEP_AI_GENERATION='CHANGE_STEP_AI_GENERATION'

export const AVATAR_AI_TEXT="AVATAR_AI_TEXT";
